import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'antd';
import styled from 'styled-components/macro';
import { Paragraph } from 'components/Common/Text';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { appName } from 'hoc/Layout';
import i18n from 'i18n';
import appStoreEN from 'assets/images/store/app-store-en.svg';
import appStoreNL from 'assets/images/store/app-store-nl.svg';
import appStoreES from 'assets/images/store/app-store-es.svg';
import googlePlayEN from 'assets/images/store/google-play-en.svg';
import googlePlayNL from 'assets/images/store/google-play-nl.svg';
import googlePlayES from 'assets/images/store/google-play-es.svg';
import branchLinks from 'constants/branchLinks';

const env = process.env.NODE_ENV;

const appImage = {
  en: {
    android: googlePlayEN,
    ios: appStoreEN,
  },
  nl: {
    android: googlePlayNL,
    ios: appStoreNL,
  },
  es: {
    android: googlePlayES,
    ios: appStoreES,
  },
};

const MobileModalHeading = styled.h4`
  display: flex;
  vertical-align: middle;
  justify-content: center;

  [data-icon='exclamation-circle'] {
    margin-right: 16px;
    display: inline-block;
    margin-right: 0.5em;
  }
`;

const TextContent = styled.div`
  p {
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 0;
  }

  p:nth-child(n + 2) {
    margin-top: 1.6rem;
    margin-bottom: 0;
  }

  p:last-child {
    margin: 1.6rem 0 2rem 0;
    font-weight: 700;
  }
`;

const DownloadAppWrapper = styled.div`
  display: flex;
  a {
    flex-basis: 50%;
    flex-grow: 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
`;

const MobileScreenModal: React.FC = (): ReactElement => {
  const { t } = useTranslation();

  return (
    <Modal
      title={
        <MobileModalHeading>
          <ExclamationCircleOutlined /> {t('warning')}
        </MobileModalHeading>
      }
      visible={true}
      footer={<></>}
      closable={false}
    >
      <TextContent>
        <Paragraph>{t('yourScreenTooSmall')}</Paragraph>
        <Paragraph>{t('ourWebEditor')}</Paragraph>
        <Paragraph>{t('downloadOurApp')}</Paragraph>
      </TextContent>
      <DownloadAppWrapper>
        <a
          data-link='ios'
          aria-label='iOS Icon'
          href={branchLinks[appName][`${env}`]}
          rel='noopener noreferrer'
          target='_blank'
        >
          <img src={appImage[i18n.language].ios} alt={`app-store-button`} />
        </a>
        <a
          data-link='android'
          aria-label='Android Icon'
          href={branchLinks[appName][`${env}`]}
          rel='noopener noreferrer'
          target='_blank'
        >
          <img
            src={appImage[i18n.language].android}
            alt={`play-store-button`}
          />
        </a>
      </DownloadAppWrapper>
    </Modal>
  );
};

export default MobileScreenModal;
