import React, { useState, ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { H3, Paragraph } from "components/Common/Text";
import GetIcon from "components/Common/Icon"
import {
  mdiPencilOutline,
  mdiEyeOutline,
  mdiEyeOffOutline,
  mdiCart,
  mdiShareVariant
} from "@mdi/js"
import { Button } from "components/Common/Button";
import { DiaryType } from "lib/types";
import styled from "styled-components/macro";
import moment from "moment";
import OrderModal from "components/Editor/Modals/OrderModal";
import { DateFormatEnum } from 'lib/types';
import { getDateFormat } from "util/chapterUtils";
import { useSelector } from "react-redux";
import { AppState } from "store/reducers/rootReducer";

// Styled Components

const Diary = styled.div`
  width: 50%;
  padding: 1.5em;
`;

const DiaryInfoWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  h3 {
    margin-top: 1.3em;
    position: relative;
    word-wrap: break-word;
    text-align: center;
    margin-bottom: 0.5em;
  }

  p {
    color: ${({ theme }) => theme.greyColor};
    font-size: 1.6em;
    margin-bottom: 2em;
  }

  [data-type="diary"] {
    width: 75%;
  }

  button {
    .anticon {
      font-size: 1.3em !important;
      display: inline-block;
      margin-right: 0.5em;
    }
  }
`;

const DiaryImageWrap = styled.div`
  position: relative;
  width: 75%;
  height: 45em;
  min-width:260px;
  max-width:320px;

  .mdi-icon {
    position: absolute;
    top: -0.75em;
    left: -0.75em;
    color: white;
    background: ${({ theme }) => theme.primaryColor};
    width: 4.5em;
    padding: 0.5em;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    -webkit-transition: 0.15s all ease-in;
    -moz-transition: 0.15s all ease-in;
    transition: 0.15s all ease-in;
    cursor: pointer;

    &:hover {
      background: ${({ theme }) => theme.primaryHover};
    }
  }
`;

const DiaryImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  -webkit-box-shadow: rgb(227, 227, 227) 1em 1em 2em 0px;
  -moz-box-shadow: rgb(227, 227, 227) 1em 1em 2em 0px;
  box-shadow: rgb(227, 227, 227) 1em 1em 2em 0px;
  -webkit-border-radius: 1.5em;
  -moz-border-radius: 1.5em;
  border-radius: 1.5em;
`;

const ButtonsWrap = styled.div`
  width: 75%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 2em;

  button {
    width: 47%;
  }
`;

// Types
type DiaryPreview = {
  cover: string;
  diary: DiaryType;
};

const DiaryPreview: React.FC<DiaryPreview> = ({ cover, diary }): ReactElement => {
  const { t, i18n } = useTranslation();
  const [orderModal, setOrderModal] = useState<boolean>(false);
  const orderUrl = process.env.REACT_APP_ORDER_URL?.replace("{lang}", i18n.language).replace("{diaryId}", diary!.id);
  const { userAccount } = useSelector(({ user }: AppState) => user);

  return (
    <Diary>
      <DiaryInfoWrap>
        <DiaryImageWrap>
          <GetIcon path={mdiPencilOutline} />
          <DiaryImage src={cover} />
        </DiaryImageWrap>
        <H3>{diary?.name}</H3>
        <Paragraph>{moment(diary?.createdOn).format(`${getDateFormat(userAccount.dateFormat as DateFormatEnum)}`)}</Paragraph>
        <Button filled size="medium" data-type="diary">
          {diary?.isPublic ?
            <><GetIcon path={mdiEyeOffOutline} className="extra"/> {t('makePrivateDiary')}</> :
            <><GetIcon path={mdiEyeOutline} className="extra"/> {t('makePublicDiary')}</>}
        </Button>
        <ButtonsWrap>
          <Button filled size="medium">
            <GetIcon path={mdiShareVariant} /> {t('share')}
          </Button>
          <Button filled size="medium" onClick={() => setOrderModal(true)}>
            <GetIcon path={mdiCart} className="extra"/> {t('order')}
          </Button>
        </ButtonsWrap>
      </DiaryInfoWrap>
      { orderModal && <OrderModal setVisible={setOrderModal} orderUrl={orderUrl || '#'} /> }
    </Diary>
  );
};

export default DiaryPreview;
