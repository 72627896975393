import React, { ReactElement } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Layer, Text, Rect, Group } from 'react-konva';
import { PageType, StylesType, ContentBlock, ChapterPageType } from 'lib/types'
import PageHeader from "components/Editor/Chapter/Parts/PageHeader"
import PageImage from "components/Editor/Chapter/Parts/PageImage"
import textLines from "components/Editor/Chapter/Parts/TextLines"
import { KonvaEventObject } from "konva/types/Node";
import { AppState } from "store/reducers/rootReducer";
import Konva from "konva";
import { setModalChapter } from "store/actions/chapter-actions";

type PageProps = {
    isRight: boolean;
    side: ChapterPageType | undefined;
    style: StylesType | undefined;
};
const { REACT_APP_PAGE_WIDTH: pageX, REACT_APP_PAGE_HEIGHT: pageY, REACT_APP_NUMBER_MARGIN: numMargin } = process.env as any
const space = { width: pageX - 2 * numMargin, height: pageY - 2 * numMargin }

const PageContent: React.FC<PageProps> = ({ isRight, side, style }): ReactElement => {
    const dispatch = useDispatch()
    const { previewMode } = useSelector(({ chapters }: AppState) => chapters);
    const { diary } = useSelector(({ diary }: AppState) => diary);
    if (!side || !style) return <></>;
    const { id, page, diaryId, pagesBefore } = side

    const editChapter = () => dispatch(setModalChapter(id))

    const renderText = () => {

        if (page.lines) {
            const { font, fontSize } = style.textStyle
            const lines = page.lines

            return lines.map((t, index) => {
                let tHtml = t.text;
                if(diary?.style.textStyle.font.hasBoldAndItalic){
                    tHtml = tHtml
                    .replace(/\[\[\[\s*\/?(\w+\s*\/?)\]\]\]/g, "<$1>")
                } else {
                    tHtml =  tHtml
                    .replace(/\[\[\[\s*\/?(\w+\s*\/?)\]\]\]/g, "")
                }
                const textPos = textLines(tHtml, t.position, { fontFamily: font.displayName, fontSize: fontSize })
                return textPos.map((l) => <Text key={`${l.name}${index}`} {...l} />)
            }).flat()
        }
        return null;
    }
    const renderImages = (page: PageType | undefined) => {
        if (!page) return null;
        return page.contentBlocks.map((p: ContentBlock) =>
            <PageImage
                key={p.id}
                block={p}
                diaryId={diaryId}
                chapterId={id} />)
    }

    const PageNumber = () => {
        const pageNumberMargin = parseInt(numMargin);
        //plus 4.5 to fit pageNumber into the frame 
        const xPos = isRight ? pageX - (pageNumberMargin + 4.5) : +pageNumberMargin
        //plus 1 for the cover, Text requests a string
        const pgNum = ((pagesBefore || 0) + page.number + 1).toString()
        return (
            <Text x={xPos} y={pageY - numMargin} 
                fontFamily={style.textStyle.font.displayName} 
                fontSize={style.textStyle.fontSize  * 1.1} 
                text={pgNum}
            />
        )
    }
    const cursorChange = (e: KonvaEventObject<MouseEvent>, type: string) => {
        const container = e.target.getStage()?.container();
        if (previewMode) type = "default"

        if (container) {
            container.style.cursor = type;
            let opacity = 1;
            if (type === 'pointer') opacity = 0.6
            e.currentTarget.children.each(child => {
                if (child instanceof Konva.Text) child.opacity(opacity)
            })
            e.target.getLayer().batchDraw()
        }
    }

    const editFunc = (e: any) => {
        if (previewMode || (e.evt.button && e.evt.button !== 0)) return;
        e.cancelBubble = true;
        editChapter()
    }

    return <>
        <Layer x={isRight ? +pageX : 0}>
            <Group onMouseEnter={(e) => cursorChange(e, 'pointer')}
                onMouseLeave={(e) => cursorChange(e, 'default')}
                onClick={editFunc} onTap={editFunc}>
                <Rect x={+numMargin} y={+numMargin - 16} {...space} />
                <PageHeader page={page} style={style} />
                {renderText()}
            </Group>
            {renderImages(page)}
            {style.textStyle.showPageNumbers && <PageNumber />}
        </Layer>
    </>
}

export default PageContent;