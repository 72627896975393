import React, { ReactElement, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import useImage from 'use-image';
import useAuthImage from 'components/Editor/Chapter/Parts/PageImage/useAuthImage';
import { ContentBlock } from 'lib/types';
import { Rect, Image, Group, Text } from 'react-konva';
import { KonvaEventObject } from 'konva/types/Node';
import { setImageChange } from 'store/actions/chapter-actions';
import { AppState } from 'store/reducers/rootReducer';

type ImageProps = {
  block: ContentBlock;
  diaryId: string;
  chapterId: string;
};

const iconSize = 26;
const PageImage: React.FC<ImageProps> = ({
  block,
  diaryId,
  chapterId,
}): ReactElement => {
  const { imageId, size, position, routeMapData, simpleMapData } = block;
  const mapData = routeMapData ? routeMapData : simpleMapData;
  const [image, loading, error] = useAuthImage(
    diaryId,
    mapData ? mapData.imageId : imageId,
    size.width,
    size.height
  );
  const [pencilImg] = useImage(
    `/editor_pencil_${process.env.REACT_APP_FILE_PREFIX}.svg`
  );
  const [loadingImg] = useImage('/cloud.png');
  const dispatch = useDispatch();
  const menuNode = React.useMemo(() => document.getElementById('menu'), []);
  const { previewMode, isChapterUpdating, pageNumber } = useSelector(
    ({ chapters }: AppState) => chapters
  );
  const closeMenuTouch = useCallback(() => {
    if (menuNode) menuNode.style.display = 'none';
  }, [menuNode]);

  useEffect(() => {
    closeMenuTouch();
  }, [closeMenuTouch, isChapterUpdating, pageNumber]);

  const EmptyBox = () => {
    return (
      <Group>
        {!previewMode ? (
          <Rect
            dash={[5, 2]}
            stroke="lightgray"
            {...position}
            {...size}
            onTap={closeMenuTouch}
          />
        ) : null}
        <Text
          width={size.width}
          align="center"
          fontStyle="italic"
          fontFamily="diary-font-oregano"
          fill="gray"
          text={block.emptyBoxDescription}
          x={position.x}
          y={position.y + size.height / 2 + (previewMode ? 0 : 15)}
        />
      </Group>
    );
  };

  const renderImage = () => {
    if (image && !error)
      return (
        <Image {...position} {...size} image={image} onTap={closeMenuTouch} />
      );
    if (block.emptyBoxDescription) return <EmptyBox />;

    if (previewMode && !loading) return;
    return (
      <Rect fill="lightgray" {...position} {...size} onTap={closeMenuTouch} />
    );
  };

  const renderIcon = () => {
    if (previewMode && !loading) return;
    const cursorChange = (e: KonvaEventObject<MouseEvent>, type: string) => {
      const container = e.target.getStage()?.container();
      if (container) container.style.cursor = type;
    };
    const openImageMenu = (e: KonvaEventObject<MouseEvent>) => {
      e.cancelBubble = true;
      dispatch(
        setImageChange({
          diaryId,
          chapterId,
          block: block,
          size,
          type: null,
          image: image?.src,
          mode: 'edit',
        })
      );
      const container = e.target.getStage()?.container();
      if (container && menuNode) {
        let pos = { x: 0, y: 0 };
        if (e.evt.clientX) {
          pos = { x: e.evt.clientX + 5, y: e.evt.clientY + 5 };
        } else {
          var posC = container.getBoundingClientRect();
          pos = { x: posC.left, y: posC.top };
          var touchPos = e.target.getStage()?.getPointerPosition();
          if (touchPos) {
            pos = { x: pos.x + touchPos.x, y: pos.y + touchPos.y };
          }
        }
        menuNode.style.display = 'initial';
        menuNode.style.top = pos.y + 'px';
        menuNode.style.left = pos.x + 'px';
      }
    };
    const iconOptions = {
      x: position.x + size.width / 2 - iconSize / 2,
      y: position.y + size.height / 2 - iconSize / 2,
      width: iconSize,
      height: iconSize,
      opacity: 1,
    };
    if (loading) return <Image {...iconOptions} image={loadingImg} />;
    return (
      <Image
        {...iconOptions}
        image={pencilImg}
        onMouseEnter={(e) => cursorChange(e, 'pointer')}
        onMouseLeave={(e) => cursorChange(e, 'default')}
        onClick={openImageMenu}
        onTouchEnd={openImageMenu as any}
      />
    );
  };

  return (
    <Group>
      {renderImage()}
      {renderIcon()}
    </Group>
  );
};

export default PageImage;
