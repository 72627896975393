import React from 'react';
import { HelpParagraph } from 'components/Common/Text';
import { Select, Checkbox } from 'antd';
import {
  FlexWrapper,
  HalfFlexWrapper,
  SizedFlexWrapper,
  ColorSquare,
  colorName,
} from './reusable';
import fonts from 'lib/fonts.json';
import colors from 'lib/colors.json';
import { StylesType } from 'lib/types';
const { Option } = Select;

type DateLocationProps = {
  t: (term: string) => string;
  dateStyle: StylesType['dateAndLocationStyle'];
  disable: boolean;
  changeDateLoc: (style: any) => void;
  handleFont: (val: number, type: string) => void;
  handleColor: (val: number, type: string) => void;
};

const sizes = [7, 9, 11];

const DateLocationStyle: React.FC<DateLocationProps> = ({
  t,
  dateStyle,
  changeDateLoc,
  disable,
  handleFont,
  handleColor,
}) => {
  return (
    <>
      <HelpParagraph>{t('dateLocHelpText')}</HelpParagraph>
      <FlexWrapper>
        <Checkbox
          checked={dateStyle?.showLocation}
          disabled={disable}
          onChange={({ target }) =>
            changeDateLoc({ showLocation: target.checked })
          }
        >
          {t('showLocation')}
        </Checkbox>
        <Checkbox
          checked={dateStyle?.showDate}
          disabled={disable}
          onChange={({ target }) => changeDateLoc({ showDate: target.checked })}
        >
          {t('showDate')}
        </Checkbox>

        <SizedFlexWrapper>
          <Select
            defaultValue={0}
            value={dateStyle?.font.value}
            onChange={(val) => handleFont(val, 'dateLoc')}
            disabled={disable}
          >
            {fonts &&
              fonts.map((f) => (
                <Option key={f.displayName + 2} value={f.value}>
                  {f.name}
                </Option>
              ))}
          </Select>
          <Select
            defaultValue={9}
            value={dateStyle?.fontSize}
            onChange={(val) => changeDateLoc({ fontSize: val })}
            disabled={disable}
          >
            {sizes.map((i) => (
              <Option key={i + 'fontSize2'} value={i}>{`${i}pt`}</Option>
            ))}
          </Select>
        </SizedFlexWrapper>
        <HalfFlexWrapper>
          <Select
            defaultValue='Right'
            value={dateStyle?.align}
            disabled={disable}
            onChange={(val) => changeDateLoc({ align: val })}
          >
            <Option key='Right' value='Right'>
              {t('rightAlign')}
            </Option>
            <Option key='Left' value='Left'>
              {t('leftAlign')}
            </Option>
          </Select>
          <Select
            defaultValue={0}
            value={dateStyle?.textColor.value}
            onChange={(val) => handleColor(val, 'dateLoc')}
            disabled={disable}
          >
            {colors &&
              colors
                .filter((c) => c.canBeDateAndLocationColor)
                .map((c) => (
                  <Option value={c.value} key={c.displayName + 2}>
                    <ColorSquare color={c.screenColor.htmlHex} />
                    {colorName(c.displayName, t)}
                  </Option>
                ))}
          </Select>
        </HalfFlexWrapper>
        <Checkbox
          checked={dateStyle?.isItalic}
          disabled={disable}
          onChange={({ target }) => changeDateLoc({ isItalic: target.checked })}
        >
          {t('makeTextItalic')}
        </Checkbox>
      </FlexWrapper>
    </>
  );
};

export default DateLocationStyle;
