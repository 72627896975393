import React, {
  ReactElement,
  useState,
  useEffect,
  useCallback,
  createRef,
  CSSProperties,
  useMemo,
  useRef,
  forwardRef,
} from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import { Select, Tooltip, Upload } from 'antd';
import { HelpParagraph, Paragraph } from 'components/Common/Text';
import {
  mdiImage,
  mdiInboxArrowDown,
  mdiMagnifyMinus,
  mdiMagnifyPlus,
  mdiReflectHorizontal,
  mdiReflectVertical,
  mdiRotateLeft,
  mdiRotateRight,
  mdiShape,
  mdiShapePlus,
} from '@mdi/js';
import GetIcon from 'components/Common/Icon';
import {
  convertBlobToBase64,
  dataURLtoFile,
  isHeicMimeType,
  isImageLargeEnough,
} from 'util/imageUtils';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'store/reducers/rootReducer';
import ConfirmModal from 'components/Editor/Modals/ConfirmModal';
import 'cropperjs/dist/cropper.css';
import { Cropper, ReactCropperElement } from 'react-cropper';
import { Button } from 'components/Common/Button';
import heic2any from 'heic2any';
import { setImageChange } from 'store/actions/chapter-actions';
import { ImageBoxType } from 'lib/types';

type FilterFunction = (intensity: number) => Record<string, CSSProperties>;

const createInstagramFilter: FilterFunction = (intensity: number) => {
  const intensityFactor = intensity / 100;

  const getAdjustedValue = (
    base: number,
    factor: number,
    isIncreasing = true
  ) => {
    return isIncreasing
      ? base + factor * intensityFactor
      : base - factor * intensityFactor;
  };

  const getBrightness = (base: number) => getAdjustedValue(base, 20);
  const getContrast = (base: number) => getAdjustedValue(base, 40);
  const getSaturate = (base: number) => getAdjustedValue(base, 50);
  const getSepia = (base: number) => getAdjustedValue(base, 40, false);
  const getHueRotate = (base: number) => `${getAdjustedValue(base, 20)}deg`;
  const getGrayscale = (base: number) => getAdjustedValue(base, 100, false);

  return {
    temple: {
      filter: `contrast(${getContrast(110)}%) brightness(${getBrightness(
        110
      )}%) sepia(${getSepia(30)}%)`,
    },
    amaro: {
      filter: `hue-rotate(${getHueRotate(-10)}) contrast(${getContrast(
        90
      )}%) brightness(${getBrightness(150)}%) saturate(${getSaturate(150)}%)`,
    },
    brannan: {
      filter: `sepia(${getSepia(50)}%) contrast(${getContrast(140)}%)`,
    },
    // ... other existing filters ...

    // New filters
    ludwig: {
      filter: `contrast(${getContrast(100)}%) brightness(${getBrightness(
        102
      )}%) saturate(${getSaturate(110)}%)`,
    },
    mayfair: {
      filter: `contrast(${getContrast(110)}%) brightness(${getBrightness(
        105
      )}%) saturate(${getSaturate(125)}%) sepia(${getSepia(10)}%)`,
    },
    willow: {
      filter: `grayscale(${getGrayscale(100)}%) contrast(${getContrast(
        95
      )}%) brightness(${getBrightness(110)}%)`,
    },
    loom: {
      filter: `hue-rotate(${getHueRotate(15)}) saturate(${getSaturate(
        120
      )}%) contrast(${getContrast(105)}%)`,
    },
    // Feel free to add more filters or customize the parameters further
  };
};

const selectOptions = Object.keys(createInstagramFilter(1)).map((filter) => ({
  value: filter,
  label: filter.split('')[0].toUpperCase() + filter.slice(1),
}));

const Uploader = styled(Upload)`
  .ant-upload.ant-upload-select {
    display: block;
  }
  ${({ isHidden }) => isHidden && 'display: hidden;'}
`;

const CropperFooterWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const DropperWrap = styled.div`
  display: flex;
  ${({ isHidden }) => isHidden && 'display: none;'}
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 2px dashed ${({ theme }) => theme.primaryColor};
  cursor: pointer;
  padding: 4em 1em;

  [data-icon='dropperIcon'] {
    width: 5.8em;
    margin-bottom: 0.5em;
    color: ${({ theme }) => theme.primaryColor};
  }

  p {
    margin-top: 2em;
  }
`;

const CropperActions = styled.div`
  display: flex;
  margin-top: 1rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  button:not(:last-child) {
    margin-right: 1rem;
  }
`;

const ImageActions = styled.div`
  display: flex;
  position: relative;
  margin-top: 1rem;
  align-items: center;
  justify-content: center;
  width: 100%;

  button:not(:last-child) {
    margin-right: 1rem;
  }

  button:last-child {
    align-self: flex-end;
  }
`;

const Filters = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FilterSelect = styled.div`
  display: flex;
  justify-content: center;

  p {
    margin-right: 1rem;
    align-self: center;
    display: flex;
    height: 100%;
    margin-bottom: 0;
  }
`;

const FilterActions = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 1rem;
  align-items: center;
  justify-content: center;

  ${({ disabled }) => disabled && 'opacity: 0.7;'}

  input {
    color: ${({ theme }) => theme.primaryColor};
    margin-left: 2rem;
  }

  input[type='range'] {
    -webkit-appearance: none; /* Override default CSS styles */
    appearance: none;
    border-radius: 12px;
    width: 20%;
    min-width: 150px;
    height: 10px;
    background: #ddd;
    outline: none;
    opacity: 0.7;
    -webkit-transition: 0.2s;
    transition: opacity 0.2s;
  }

  input[type='range']:disabled {
    opacity: 0.3;
    background: ${({ theme }) => theme.secondaryColor};
    cursor: not-allowed;
  }

  input[type='range']:not(:disabled):hover {
    opacity: 1;
  }

  /* Style the thumb */
  input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none; /* Override default look */
    appearance: none;
    border-radius: 50%;
    width: 25px; /* Set a specific slider handle width */
    height: 25px; /* Slider handle height */
    background: ${({ theme }) => theme.primaryColor};
    cursor: pointer;
  }

  input[type='range']::-moz-range-thumb {
    background: ${({ theme }) => theme.primaryColor};
    cursor: pointer; /* Cursor on hover */
  }

  input[type='range']:focus {
    outline: none; /* Removes the blue border */
  }
`;

type Props = {
  onImageSend: (file: any) => void;
};

function isSafari() {
  var userAgent = navigator.userAgent.toLowerCase();

  // Check if the browser is Safari and not Chrome or another browser
  if (
    userAgent.indexOf('safari') !== -1 &&
    userAgent.indexOf('chrome') === -1
  ) {
    return true;
  }

  return false;
}

const safari = isSafari();
const ImageCropUpload: React.FC<Props> = ({ onImageSend }): ReactElement => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const uploaderRef = useRef<any>();
  const [isCropping, setIsCropping] = useState(false);
  const [isWarningVisible, setIsWarningVisible] = useState(false);
  const [isValidImage, setIsValidImage] = useState(false);
  const [croppedFile, setCroppedFile] = useState<File>();
  const { imageChange } = useSelector((state: AppState) => state.chapters);
  const [loading, setLoading] = useState(false);
  const [cropper, setCropper] = useState<Cropper>();
  const cropperRef = createRef<ReactCropperElement>();
  const [selectedFilter, setSelectedFilter] = useState('');
  const [showFilters, setShowFilters] = useState(true);
  const [filterIntensity, setFilterIntensity] = useState(50);
  const [imageStyles, setImageStyles] = useState<CSSProperties>({});
  const width = imageChange?.size.width || 1;
  const height = imageChange?.size.height || 1;
  const filters = useMemo(
    () => createInstagramFilter(filterIntensity),
    [filterIntensity]
  );

  const getCropData = async () => {
    if (typeof cropper !== 'undefined') {
      setLoading(true);

      const canvas = cropper.getCroppedCanvas();
      if (selectedFilter) {
        const ctx = canvas.getContext('2d');
        if (ctx) {
          const newStyles = filters[selectedFilter];
          ctx.filter = newStyles.filter ?? '';
          ctx.drawImage(canvas, 0, 0);
        }
      }
      const imageAsDataUrl = canvas.toDataURL('image/jpeg');
      const file = dataURLtoFile(imageAsDataUrl, 'cropped-image.jpeg');
      const img = document.createElement('img');
      img.src = imageAsDataUrl;
      img.addEventListener('load', () => {
        const { width, height } = img;
        if (imageChange)
          setIsValidImage(
            isImageLargeEnough(imageChange.size, { width, height })
          );
        setIsCropping(true);
        setCroppedFile(file);
        setLoading(false);
      });
    }
  };

  useEffect(() => {
    if (!imageChange?.image) return;
    if (imageChange.mode === 'edit') return setIsValidImage(true);
    const img = document.createElement('img');
    img.src = imageChange?.image?.toString() || '';
    img.addEventListener('load', () => {
      const { width, height } = img;

      if (imageChange)
        setIsValidImage(
          isImageLargeEnough(imageChange.size, { width, height })
        );
    });
  }, [imageChange]);

  useEffect(() => {
    applyFilter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFilter, filterIntensity]);

  const sendValidImage = useCallback(() => {
    if (croppedFile) {
      onImageSend(croppedFile);
      setIsCropping(false);
      setCroppedFile(undefined);
      setIsValidImage(false);
      dispatch(setImageChange(null));
    }
  }, [croppedFile, onImageSend, dispatch]);

  useEffect(() => {
    if (!isValidImage && croppedFile) {
      setIsWarningVisible(true);
    } else if (isValidImage && croppedFile) {
      sendValidImage();
    }
  }, [croppedFile, isCropping, isValidImage, sendValidImage]);

  const zoomIn = () => {
    if (cropper) {
      cropper.zoom(0.1);
    }
  };

  const toggleFilters = () => {
    setShowFilters(!showFilters);
  };

  const zoomOut = () => {
    if (cropper) {
      cropper.zoom(-0.1);
    }
  };

  const reflectHorizontal = () => {
    if (cropper) {
      const currentScaleX = cropper.getData().scaleX || 1;
      cropper.scaleX(-currentScaleX);
    }
  };

  const reflectVertical = () => {
    if (cropper) {
      const currentScaleY = cropper.getData().scaleY || 1;
      cropper.scaleY(-currentScaleY);
    }
  };

  const rotateRight = () => {
    if (cropper) {
      cropper.rotate(90);
    }
  };

  const rotateLeft = () => {
    if (cropper) {
      cropper.rotate(-90);
    }
  };

  const applyFilter = () => {
    let newImageStyles: CSSProperties = { filter: '' };

    if (selectedFilter) {
      newImageStyles.filter = filters[selectedFilter].filter;
    } else {
      newImageStyles.filter = '';
    }
    setImageStyles(newImageStyles);
  };

  const uploadNewImage = () => {
    if (uploaderRef?.current) {
      uploaderRef.current.click();
    }
  };

  return (
    <>
      <ImageUploader hidden={true} ref={uploaderRef} />
      {imageChange?.image && (
        <>
          <Cropper
            scalable={true}
            viewMode={1}
            toggleDragModeOnDblclick={false}
            minCropBoxHeight={height}
            cropBoxResizable={false}
            minCropBoxWidth={width}
            background={false}
            onBeforeInput={() => console.log('onBeforeInput')}
            responsive={true}
            highlight={false}
            restore={false}
            center={false}
            autoCropArea={1}
            dragMode="move"
            checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
            guides={true}
            zoomable={true}
            height={'auto'}
            disabled={loading || isCropping}
            ref={cropperRef}
            style={{ ...imageStyles, maxHeight: '480px' }}
            initialAspectRatio={width / height}
            src={imageChange ? imageChange.image?.toString() : ''}
            onInitialized={(instance) => {
              setCropper(instance);
            }}
          />
          <>
            <CropperActions>
              <ImageActions>
                <Tooltip title={t('zoomOut')}>
                  <Button filled size="xsmall" onClick={zoomOut}>
                    <GetIcon path={mdiMagnifyMinus} size="20" />
                  </Button>
                </Tooltip>
                <Tooltip title={t('zoomIn')}>
                  <Button filled size="xsmall" onClick={zoomIn}>
                    <GetIcon path={mdiMagnifyPlus} size="20" />
                  </Button>
                </Tooltip>

                <Tooltip title={t('rotateLeft')}>
                  <Button filled size="xsmall" onClick={rotateLeft}>
                    <GetIcon path={mdiRotateLeft} size="20" />
                  </Button>
                </Tooltip>

                <Tooltip title={t('rotateRight')}>
                  <Button filled size="xsmall" onClick={rotateRight}>
                    <GetIcon path={mdiRotateRight} size="20" />
                  </Button>
                </Tooltip>

                <Tooltip title={t('flipHorizontal')}>
                  <Button filled size="xsmall" onClick={reflectHorizontal}>
                    <GetIcon path={mdiReflectHorizontal} size="20" />
                  </Button>
                </Tooltip>

                <Tooltip title={t('flipVertical')}>
                  <Button filled size="xsmall" onClick={reflectVertical}>
                    <GetIcon path={mdiReflectVertical} size="20" />
                  </Button>
                </Tooltip>

                <Tooltip
                  title={showFilters ? t('hideFilters') : t('showFilters')}
                >
                  <Button filled size="xsmall" onClick={toggleFilters}>
                    <GetIcon
                      path={showFilters ? mdiShape : mdiShapePlus}
                      size="20"
                    />
                  </Button>
                </Tooltip>
                <Tooltip title={t('uploadNewImage')}>
                  <Button
                    outlined
                    size="small"
                    onClick={uploadNewImage}
                    disabled={loading}
                  >
                    <GetIcon path={mdiImage} size="20" />
                  </Button>
                </Tooltip>
              </ImageActions>
              {showFilters && (
                <FilterActions disabled>
                  <Filters>
                    <FilterSelect>
                      <Paragraph size={1}>Filters</Paragraph>
                      <Select
                        style={{ minWidth: '125px' }}
                        disabled={safari}
                        value={selectedFilter}
                        onChange={setSelectedFilter}
                        defaultValue=""
                        defaultActiveFirstOption
                      >
                        <Select.Option value="">{t('none')}</Select.Option>
                        {selectOptions.map((filter) => (
                          <Select.Option value={filter.value}>
                            {filter.label}
                          </Select.Option>
                        ))}
                      </Select>
                    </FilterSelect>
                    <input
                      disabled={!selectedFilter || safari}
                      type="range"
                      min="0"
                      max="100"
                      defaultValue={50}
                      value={filterIntensity}
                      onChange={(e: any) => setFilterIntensity(e.target.value)}
                    />
                  </Filters>
                  {safari && (
                    <div style={{ marginTop: '1.25rem' }}>
                      <Paragraph size={1}>{t('safariFilterWarning')}</Paragraph>
                    </div>
                  )}
                </FilterActions>
              )}
            </CropperActions>
            <CropperFooterWrap key="1">
              <Button
                outlined
                size="small"
                disabled={loading}
                onClick={() => {
                  if (imageChange) {
                    dispatch(setImageChange(null));
                  }
                }}
              >
                {t('cancel')}
              </Button>

              <Button
                filled
                size="small"
                onClick={getCropData}
                disabled={loading}
              >
                {t('save')}
              </Button>
            </CropperFooterWrap>
            <ConfirmModal
              yesText="btnYes"
              noText="btnNo"
              onConfirm={sendValidImage}
              onCancel={() => setIsWarningVisible(false)}
              visible={isWarningVisible}
              headerText="imageTooSmall"
              content="smallImageWarning"
            />
          </>
        </>
      )}
    </>
  );
};

const UploadComponent = styled(Upload)`
  .ant-upload.ant-upload-select {
    display: block;
  }
  ${({ isHidden }) => isHidden && 'display: none;'}
`;

interface ImageUploaderProps {
  hidden?: boolean;
  ref?: React.RefObject<any>;
  onImageUploaded?: (file: string) => void;
}

export const ImageUploader = forwardRef<any, ImageUploaderProps>(
  ({ hidden, onImageUploaded }, ref) => {
    const { imageChange } = useSelector((state: AppState) => state.chapters);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const handleFileSelection = async (file: File) => {
      let image: any = file;
      if (isHeicMimeType(file.type)) {
        image = await heic2any({
          blob: file,
          toType: 'image/jpeg',
        });
      }
      const src = await convertBlobToBase64(
        Array.isArray(image) ? image[0] : image
      );

      if (imageChange) {
        dispatch(
          setImageChange({
            ...imageChange,
            image: src ? src.toString() : '',
            type: ImageBoxType.EDIT_IMAGE,
            mode: 'create',
          })
        );
        if (onImageUploaded) {
          onImageUploaded(src ? src.toString() : '');
        }
      }
      return false; // Prevents the default upload behavior
    };

    const openFileSelector = () => {
      //@ts-ignore
      if (ref?.current) {
        //@ts-ignore
        ref.current.click();
      }
    };

    if (hidden) {
      return (
        <UploadComponent
          name="file"
          multiple={false}
          showUploadList={false}
          accept="image/*, .heic, .heif"
          beforeUpload={handleFileSelection}
          customRequest={() => {}} // Suppresses the default upload behavior
        >
          <DropperWrap isHidden onClick={openFileSelector} ref={ref} />
        </UploadComponent>
      );
    }

    return (
      <Uploader
        name="file"
        multiple={false}
        showUploadList={false}
        accept="image/*, .heic, .heif"
        beforeUpload={handleFileSelection}
        customRequest={() => {}} // Suppresses the default upload behavior
        ref={ref}
        isHidden={false}
      >
        <DropperWrap onClick={openFileSelector}>
          <GetIcon path={mdiInboxArrowDown} data-icon="dropperIcon" />
          <Paragraph>{t('dragToUpload')}</Paragraph>
          <HelpParagraph>{t('singleUploadOnly')}</HelpParagraph>
        </DropperWrap>
      </Uploader>
    );
  }
);

export default ImageCropUpload;
