/* eslint-disable import/no-webpack-loader-syntax */
import React, { ReactElement, useEffect, useState, useCallback } from 'react';
import ReactMapGL, { Layer, Source, NavigationControl } from 'react-map-gl';
import {
  pinLayer,
  lineLayer,
  normalLayer,
  firstAndLastLayer,
  initialViewPort,
} from './MapComponents';
import { PointsDataType, FeatureType } from 'lib/types';
import { AppState } from 'store/reducers/rootReducer';
import { useSelector } from 'react-redux';
import { getMapboxClickData, getMapSizeInPixels, mapAdapter } from './utils';
import * as S from './styles';
import i18n from 'i18n';
import 'mapbox-gl/dist/mapbox-gl.css';
import 'react-map-gl-geocoder/dist/mapbox-gl-geocoder.css';
import Geocoder from 'react-map-gl-geocoder';
import pin from './pin_icon.png';
import mapboxgl from 'mapbox-gl';
import { languageRegions } from 'util/languages';
// @ts-ignore
mapboxgl.workerClass =
  require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

const mbxClient = require('@mapbox/mapbox-sdk');
const geocodingClient = require('@mapbox/mapbox-sdk/services/geocoding');
const MAPBOX_ACCESS_TOKEN =
  process.env.REACT_APP_MAPBOX_ACCESS_TOKEN || 'noToken';
const baseClient = mbxClient({ accessToken: MAPBOX_ACCESS_TOKEN });
export const geocoding = geocodingClient(baseClient);

type MapProps = {
  isRoute: boolean;
  dataSource: PointsDataType;
  updateData: (data: PointsDataType) => void;
  geoCoderRef: any;
  mapRef: any;
};

const Map: React.FC<MapProps> = ({
  isRoute,
  dataSource,
  updateData,
  geoCoderRef,
  mapRef,
}): ReactElement => {
  const { imageChange } = useSelector((state: AppState) => state.chapters);
  const [viewPort, setViewPort] = useState(initialViewPort);
  const lang = i18n.language;

  useEffect(() => {
    if (imageChange?.block.routeMapData) {
      setViewPort({
        ...initialViewPort,
        latitude: imageChange?.block.routeMapData.position.latitude,
        longitude: imageChange?.block.routeMapData.position.longitude,
        zoom: imageChange?.block.routeMapData.zoomLevel,
      });
    }

    if (imageChange?.block.simpleMapData) {
      setViewPort({
        ...initialViewPort,
        latitude: imageChange?.block.simpleMapData.position.latitude,
        longitude: imageChange?.block.simpleMapData.position.longitude,
        zoom: imageChange?.block.simpleMapData.zoomLevel,
      });
    }

    return () => {
      const map = mapRef?.current?.getMap();

      if (map && typeof map.off === 'function') {
        map.off();
      }
    };
  }, [imageChange, mapRef]);

  const handleViewportChange = useCallback(
    (newViewport) => {
      const { zoom, longitude, latitude } = newViewport;
      setViewPort({
        ...newViewport,
        width: '100%',
        height: '100%',
      });
      updateData({
        ...dataSource,
        zoomLevel: Math.ceil(zoom),
        position: { longitude, latitude },
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [updateData]
  );

  const handleClickMap = async (e: any) => {
    let data = { ...dataSource };
    const map = mapRef.current;
    let locations: FeatureType[] = [];
    if (!e.srcEvent && !e.srcEvent.isTrusted) {
      locations = [...data.locations];
    } else if (isRoute) {
      locations = map.queryRenderedFeatures(e.point, {
        layers: ['data-points'],
      });
    } else {
      locations = map.queryRenderedFeatures(e.point, {
        layers: ['data-pins'],
      });
    }

    //if click event is a remove action
    if (locations.length) {
      //if clicked item is a remove icon for route map
      if (!e.srcEvent && !e.srcEvent.isTrusted && isRoute) {
        data.locations = data.locations.filter(
          (point) => point.properties.id !== e.properties.id
        );
      } else {
        const id = locations[0].properties.id;
        data.locations = data.locations.filter(
          (point) => point.properties.id !== id
        );
      }
    } else {
      //if clicked point is a new point
      const clickedPoint = await geocoding
        .reverseGeocode({
          query: [e.lngLat[0], e.lngLat[1] as number],
        })
        .send()
        .then((response) => {
          // GeoJSON document with geocoding matches
          const match = response.body;
          return getMapboxClickData(match.features);
        });

      // create new point and push it to pointsData
      const point = {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [e.lngLat[0].toFixed(3), e.lngLat[1].toFixed(3)],
        },
        properties: {
          id: String(new Date().getTime()),
          isFirstOrLast: false,
          city: clickedPoint.city,
          country: clickedPoint.country,
        },
        pixelPosition: { x: parseInt(e.point[0]), y: parseInt(e.point[1]) },
      };
      data.locations.push(point);
    }
    updateData({ ...data });
  };

  const onMapLoad = () => {
    const map = mapRef?.current?.getMap();
    if (map) {
      map.loadImage(pin, (error, image) => {
        if (error) return;
        map.addImage('pin', image);
      });
      const mapLayers = map.getStyle().layers;

      const roadLayers = mapLayers.filter(function (layer) {
        // filter out the road label layer
        return (
          layer.id.indexOf('road-') > -1 &&
          layer.id.indexOf('-street') < 0 &&
          layer.id !== 'road-label'
        );
      });

      const tunnelLayers = mapLayers.filter(function (layer) {
        // filter out the road label layer
        return layer.id.indexOf('tunnel-') > -1;
      });

      [...roadLayers, ...tunnelLayers].forEach(function (layer) {
        map.setLayoutProperty(layer.id, 'visibility', 'none');
      });
    }
  };

  const size = getMapSizeInPixels(imageChange?.block.size);

  return (
    <S.MapContainer size={size}>
      <S.MapOuterWrap size={size}>
        <S.MapInnerWrap>
          <ReactMapGL
            {...viewPort}
            ref={mapRef}
            className='map-container'
            mapboxApiAccessToken={MAPBOX_ACCESS_TOKEN}
            mapStyle={'mapbox://styles/mapbox/outdoors-v11'}
            onClick={handleClickMap}
            maxZoom={20}
            preserveDrawingBuffer={true}
            onViewportChange={handleViewportChange}
            getCursor={({ isDragging, isHovering }) =>
              isDragging ? 'grabbing' : isHovering ? 'pointer' : 'crosshair'
            }
            onLoad={onMapLoad}
          >
            <Source
              data={mapAdapter({ ...dataSource }, isRoute)}
              type='geojson'
              id='my-data'
            >
              {isRoute && <Layer {...(lineLayer as any)} type={'line'} />}
              {isRoute && <Layer {...normalLayer} type={'circle'} />}
              {isRoute && <Layer {...firstAndLastLayer} type={'circle'} />}
              {!isRoute && <Layer {...pinLayer} type={'symbol'} />}
            </Source>
            <NavigationControl style={{ left: '10px', top: '10px' }} />
            <Geocoder
              mapRef={mapRef}
              containerRef={geoCoderRef}
              onViewportChange={handleViewportChange}
              mapboxApiAccessToken={MAPBOX_ACCESS_TOKEN}
              language={languageRegions[lang]}
              marker={false}
            />
          </ReactMapGL>
        </S.MapInnerWrap>
      </S.MapOuterWrap>
    </S.MapContainer>
  );
};

export default Map;
