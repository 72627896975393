interface Environment {
  development: string;
  test: string;
  production: string;
}

const branchLinks: Record<string, Environment> = {
  'Travel Diaries': {
    development: 'https://traveldiaries.test-app.link',
    test: 'https://traveldiaries.test-app.link',
    production: 'https://app-link.traveldiariesapp.com',
  },
  'Baby Diaries': {
    development: 'https://babydiariesapp.test-app.link',
    test: 'https://babydiariesapp.test-app.link',
    production: 'https://app-link.babydiariesapp.com/',
  },
};

export default branchLinks;
