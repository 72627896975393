import { PointsDataType } from "lib/types";

const normalLayer= {
    id: "data-points",
    source: "geojson",
    paint: {
      "circle-radius": 5,
      "circle-color": "#000",
      "circle-stroke-width": 2,
      "circle-stroke-color": "#fff",
    },
    filter: ["in", "$type", "Point"],
}

const firstAndLastLayer = {
    id: "data-points-first-last",
    source: "geojson",
    paint: {
      "circle-radius": 7,
      "circle-color": "#FAA41F",
      "circle-stroke-width": 3,
      "circle-stroke-color": "#fff",
    },
    filter: ["==", "isFirstOrLast", true],
}

const pinLayer = {
    id: "data-pins",
    source: "geojson",
    layout: {
      "icon-image": "pin", // reference the image
      "icon-size": 0.1,
    },
    filter: ["in", "$type", "Point"],
    paint: {
      
    },
}

const lineLayer = {
    id: "data-lines",
    source: "geojson",
    layout: {
      "line-cap": "round",
      "line-join": "round",
    },
    paint: {
      "line-width": 2,
      "line-color": "#000",
    },
    filter: ["in", "$type", "LineString"],
}

const initialViewPort = {
    latitude : 0,
    longitude: 0,
    zoom: 1,
    width: "100%",
    height: "100%"
}

const getInitialPointsData = (): PointsDataType => {
  return {
    type: "FeatureCollection",
    locations: [],
    zoomLevel: 5,
    position: { longitude: 50, latitude :60 }
  }
}

export {
    lineLayer,
    pinLayer,
    firstAndLastLayer,
    normalLayer,
    initialViewPort,
    getInitialPointsData
}
