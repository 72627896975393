import TagManager from 'react-gtm-module';
export const GTMPageView = (project, user, url) => {
  if (process.env.NODE_ENV !== 'development') {
    if (!user || !user.profile) return;
    TagManager.initialize({
      gtmId: process.env.REACT_APP_GOOGLE_TAG_ID,
      dataLayer: {
        project: project,
        userID: user?.profile.sub,
        event: 'pageview',
        page: url,
        title: `${project} | ${process.env.REACT_APP_NAME}`,
      },
    });
    console.log('GA tracking userID:', user?.profile.sub);
  }
};

export const GTMFirstDiary = (user) => {
  if (process.env.NODE_ENV !== 'development') {
    TagManager.initialize({
      gtmId: process.env.REACT_APP_GOOGLE_TAG_ID,
      dataLayer: {
        userID: user?.profile.sub,
        event: 'create_first_diary',
      },
    });
  }
};
