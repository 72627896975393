import React, { useState, useEffect } from 'react';
import {
  shareByEmail,
  getSharableEmails,
  deleteSharableEmail,
  closeEmailSection,
} from 'store/actions/share-actions';
import { useDispatch, useSelector } from 'react-redux';
import { H4, H5 } from 'components/Common/Text';
import { useTranslation } from 'react-i18next';
import { Input } from 'antd';
import { Button } from 'components/Common/Button';
import { AppState } from 'store/reducers/rootReducer';
import styled from 'styled-components/macro';
import CoverLoader from 'components/Common/CoverLoader';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import RemovableCheckbox from 'components/Common/RemovableCheckbox';
import {
  CheckboxGroup,
  AllCheckerCheckbox,
} from '@createnl/grouped-checkboxes';

const { TextArea } = Input;
const ButtonsWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 1em;

  @media (max-width: 500px) {
    flex-direction: column;
    flex-wrap: wrap;
  }

  button {
    font-size: 14px;
    margin-right: 1.2em;
    &:last-of-type {
      margin-right: 0;
    }

    @media (max-width: 768px) {
      font-size: 13px;
    }

    @media (max-width: 550px) {
      font-size: 12px;
      margin-right: 0.7em;
    }

    @media (max-width: 500px) {
      width: 100%;
      margin-right: 0;
      margin-bottom: 1em;
      font-size: 15px;
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
`;

const EmailsContanier = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
`;

const CheckboxContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-height: 6.5rem;
  overflow-y: auto;
  box-shadow: rgba(33, 33, 33, 0.1) 0px 0px 11px;
  padding: 1.25em 1em;
  border-radius: 20px;
`;

const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

interface Props {
  selectedChapter: string;
}

const ShareEmail: React.FC<Props> = ({ selectedChapter }: Props) => {
  const [newEmails, setNewEmails] = useState<string>('');
  const [newEmailsValid, setNewEmailsValid] = useState<boolean>(false);
  const [emailMessage, setEmailMessage] = useState<string>('');
  const [selectedEmails, setSelectedEmails] = useState<CheckboxValueType[]>([]);
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const [title, setTitle] = useState<string>('');
  const { t, i18n } = useTranslation();

  const dispatch = useDispatch();
  const {
    isEmailSending,
    availableEmailsToShare,
    isSharableEmailsLoading,
    sharedId,
  } = useSelector(({ shareDiary }: AppState) => shareDiary);
  const { diary } = useSelector(({ diary }: AppState) => diary);
  const { chapters } = useSelector(({ chapters }: AppState) => chapters);

  useEffect(() => {
    dispatch(getSharableEmails(diary!.id));
  }, [dispatch, diary]);

  useEffect(() => {
    if (sharedId && sharedId !== '') {
      const sharedChapter = chapters.find((chapter) => chapter.id === sharedId);
      setTitle(sharedChapter ? sharedChapter.title : '');
    } else setTitle(diary ? diary.name : '');
  }, [diary, sharedId, chapters]);

  //if an email is selected, and then user deletes the mail => remove it from selected list
  useEffect(() => {
    setSelectedEmails(
      selectedEmails.filter((mail) =>
        availableEmailsToShare.includes(mail.toString())
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableEmailsToShare]);

  useEffect(() => {
    if (availableEmailsToShare.length === selectedEmails.length)
      setSelectAll(true);
    else setSelectAll(false);
  }, [selectedEmails, availableEmailsToShare]);

  const sendEmails = async () => {
    const getInputMailsAsArray = (mailAddresses: string): string[] => {
      const mailArray = mailAddresses
        .split(',')
        .map((address) => address.replace(/\s+/g, ''));
      if (mailArray[0] === '' && mailArray.length === 1) return [];
      return mailArray;
    };

    const request = {
      language: i18n.language.toUpperCase(),
      message: emailMessage,
      emailAddresses: [
        ...selectedEmails.map((mail) => mail.toString()),
        ...getInputMailsAsArray(newEmails),
      ],
    };
    dispatch(shareByEmail(diary!.id, request, selectedChapter));
  };

  const updateInputEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newEmails = e.target.value;
    let valid = true;
    if (newEmails)
      for (let e of newEmails
        .split(',')
        .map((mail) => mail.trimEnd().trimStart())) {
        if (!emailRegex.test(e)) {
          valid = false;
          break;
        }
      }
    setNewEmails(newEmails);
    setNewEmailsValid(valid);
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = e.target;
    if (checked)
      setSelectedEmails((selectedEmails) => [...selectedEmails, value]);
    else
      setSelectedEmails((selectedEmails) => [
        ...selectedEmails.filter((e) => e !== value),
      ]);
  };

  const onDeleteEmail = (index: number) => {
    dispatch(
      deleteSharableEmail(availableEmailsToShare[index].toString(), diary!.id)
    );
  };

  const onSelectAllChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;
    setSelectedEmails(e.target.checked ? availableEmailsToShare : []);
    setSelectAll(checked);
  };

  return (
    <>
      {isEmailSending ? <CoverLoader /> : null}
      <H4>{t('shareByEmail', { diaryName: title })}</H4>
      <TextArea
        style={{ marginBottom: '15px' }}
        rows={8}
        placeholder={t('emailMessage')}
        onChange={(e) => setEmailMessage(e.target.value)}
      >
        {emailMessage}
      </TextArea>

      <Input
        style={{ marginBottom: '15px' }}
        placeholder={t('emailReceiversCommaSeparated')}
        type="text"
        value={newEmails}
        onChange={updateInputEmail}
      />
      {isSharableEmailsLoading && <CoverLoader />}
      {!!availableEmailsToShare.length && (
        <EmailsContanier>
          <H5 style={{ margin: ' 1em 0', fontWeight: '700' }}>
            {t('previouslySharedEmailAddresses')}
          </H5>
          <CheckboxGroup>
            <div className={'ant-checkbox-wrapper ant-checkbox-wrapper-custom'}>
              <span
                className={`ant-checkbox ant-checkbox-custom ${
                  selectAll && 'ant-checkbox-checked'
                }`}
              >
                <AllCheckerCheckbox
                  id={`email-all-checker`}
                  onChange={onSelectAllChange}
                  className={'ant-checkbox-input'}
                />
                <span className="ant-checkbox-inner"></span>
              </span>
              <label htmlFor={`email-all-checker`}>{t('selectAll')}</label>
            </div>
            <CheckboxContainer style={{ margin: '16px 0' }}>
              {availableEmailsToShare.map((email, index) => {
                return (
                  <RemovableCheckbox
                    value={email}
                    key={index}
                    index={index}
                    onChange={onChange}
                    onDelete={onDeleteEmail}
                    isSelectAllChecked={selectAll}
                    selectedEmailsCount={selectedEmails.length}
                  />
                );
              })}
            </CheckboxContainer>
          </CheckboxGroup>
        </EmailsContanier>
      )}

      <ButtonsWrap>
        <Button
          outlined
          key="close"
          onClick={() => dispatch(closeEmailSection())}
        >
          {t('cancel')}
        </Button>
        <Button
          filled
          disabled={
            isEmailSending ||
            (newEmails && !newEmailsValid) ||
            (!selectedEmails.length && !newEmails)
          }
          key="send"
          onClick={sendEmails}
        >
          {t('sendBtnTitle')}
        </Button>
      </ButtonsWrap>
    </>
  );
};

export default ShareEmail;
