import styled from 'styled-components/macro';

export const ModalHeading = styled.div`
  display: inline-block;
  margin: 0;
`;

export const Label = styled.label`
  border: none;
  text-decoration: underline;
  color: ${({ theme }) => theme.danger};
  font-weight: 700;
  margin: 0.5rem 0;
  padding: 0.5rem 0.75rem;
  border-radius: 30px;
  font-size: 0.9rem;
  &:hover {
    color: ${({ theme }) => theme.white};
    background-color: ${({ theme }) => theme.danger};
    cursor: pointer;
  }

  .anticon {
    display: inline-block;
    margin-right: 0.5em;
    font-size: 1.2em;
    position: relative;
    top: 1px;
  }
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .mdi-icon {
    display: block;
    height: 3em;
    width: 3em;
    color: ${({ theme }) => theme.primaryColor};
  }
`;

export const ButtonGroupWrap = styled.div`
  display: flex;
  margin-top: 1rem;
  flex-direction: column;
  align-items: center;

  button:nth-of-type(n + 1) {
    border: none;
    text-decoration: underline;
    font-size: 0.9rem;
    color: ${({ theme }) => theme.greyColor};
  }
`;

export const DescriptionText = styled.p`
  font-size: 0.95rem;
  font-weight: 600;
`;

export const WarningText = styled.p`
  font-size: 0.8rem;
  font-weight: 600;
  font-style: italic;
`;
