import {
  GET_DIARY_START,
  GET_DIARY_SUCCESS,
  GET_DIARY_ERROR,
  GET_DIARY_STYLES_START,
  GET_DIARY_STYLES_SUCCESS,
  GET_DIARY_STYLES_ERROR,
  UPDATE_DIARY_START,
  UPDATE_DIARY_SUCCESS,
  DIARY_RESET,
  DiaryActionTypes,
  CREATE_DIARY_START,
  CREATE_DIARY_SUCCESS,
  CREATE_DIARY_ERROR,
  PUBLISH_GLOBAL_DIARY,
  UNPUBLISH_GLOBAL_DIARY,
  UPDATE_DIARY_COMPLETE,
} from 'store/actions/diary-actions/diary-action-types';
import {
  GET_COVER_START,
  GET_COVER_SUCCESS,
  COVER_RESET,
  CoverActionTypes,
} from 'store/actions/diary-actions/cover-action-types';
import { DiaryType, StylesType } from 'lib/types';
import i18n from 'i18n';

interface IInitialState {
  diary: DiaryType;
  cover: string;
  diaryStyles: StylesType[];
  isDiaryLoading: boolean;
  isDiaryStylesLoading: boolean;
  isCoverLoading: boolean;
  isCreatingDiary: boolean;
  updateDiaryLoading: boolean;
  successMessage: string;
  createdDiaryId: string;
  errorMessage: string;
  createErrorMessage: string;
  errorStylesMessage: string;
  createdWithVoucher: boolean;
}

const initialState: IInitialState = {
  diary: undefined,
  cover: '',
  diaryStyles: [],
  createdDiaryId: '',
  isDiaryLoading: false,
  isDiaryStylesLoading: false,
  isCoverLoading: false,
  isCreatingDiary: false,
  updateDiaryLoading: false,
  successMessage: '',
  errorMessage: '',
  createErrorMessage: '',
  errorStylesMessage: '',
  createdWithVoucher: false,
};

type actionTypes = DiaryActionTypes | CoverActionTypes;

const DiaryReducer = (
  state: IInitialState = initialState,
  action: actionTypes
): IInitialState => {
  switch (action.type) {
    case GET_DIARY_START:
      return {
        ...state,
        isDiaryLoading: true,
        errorMessage: '',
      };
    case GET_DIARY_SUCCESS:
      return {
        ...state,
        isDiaryLoading: false,
        diary: action.payload.diary,
        cover: action.payload.cover,
      };
    case GET_DIARY_ERROR:
      return {
        ...state,
        isDiaryLoading: false,
        diary: undefined,
        errorMessage: action.payload,
      };
    case UPDATE_DIARY_START:
      return {
        ...state,
        updateDiaryLoading: true,
      };
    case UPDATE_DIARY_SUCCESS:
      return {
        ...state,
        diary: action.payload,
        successMessage: i18n.t('yourChangesHaveBeenSaved'),
      };
    case UPDATE_DIARY_COMPLETE:
      return {
        ...state,
        updateDiaryLoading: false,
        successMessage: '',
        errorMessage: '',
      };
    case GET_COVER_START:
      return {
        ...state,
        isCoverLoading: true,
      };
    case GET_COVER_SUCCESS:
      return {
        ...state,
        isCoverLoading: false,
        cover: action.payload,
        successMessage: i18n.t('yourChangesHaveBeenSaved'),
      };
    case DIARY_RESET:
      return {
        ...state,
        isDiaryLoading: false,
        successMessage: '',
        errorMessage: '',
      };
    case COVER_RESET:
      return {
        ...state,
        isCoverLoading: false,
        successMessage: '',
        errorMessage: '',
        updateDiaryLoading: false,
      };
    case GET_DIARY_STYLES_START:
      return {
        ...state,
        isDiaryStylesLoading: true,
        errorStylesMessage: '',
      };
    case GET_DIARY_STYLES_SUCCESS:
      return {
        ...state,
        isDiaryStylesLoading: false,
        diaryStyles: action.payload,
      };
    case GET_DIARY_STYLES_ERROR:
      return {
        ...state,
        isDiaryStylesLoading: false,
        errorStylesMessage: action.payload,
      };
    case CREATE_DIARY_START:
      return {
        ...state,
        isCreatingDiary: true,
        createErrorMessage: '',
      };
    case CREATE_DIARY_SUCCESS:
      return {
        ...state,
        createdDiaryId: action.payload?.diary!.id,
        isCreatingDiary: false,
        createErrorMessage: i18n.t('diaryWasCreatedSuccessfully'),
        createdWithVoucher: action.payload.createdWithVoucher,
      };
    case CREATE_DIARY_ERROR:
      return {
        ...state,
        isCreatingDiary: false,
        createErrorMessage: i18n.t('diaryCreationFailed'),
      };
    case PUBLISH_GLOBAL_DIARY:
      return {
        ...state,
        diary: { ...state.diary!, isPublic: true },
      };
    case UNPUBLISH_GLOBAL_DIARY:
      return {
        ...state,
        diary: { ...state.diary!, isPublic: false },
      };
    default:
      return state;
  }
};

export default DiaryReducer;
