import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'antd';
import styled from 'styled-components/macro';
import { mdiAlertCircleOutline } from '@mdi/js';
import { Button, Anchor } from 'components/Common/Button';
import { Paragraph } from 'components/Common/Text';
import GetIcon from 'components/Common/Icon';
import { getDiaryOrderMetaData } from 'util/diaryUtils';
import { useSelector } from 'react-redux';
import { AppState } from 'store/reducers/rootReducer';
import { OrderModalStatus, DiaryOrderMetaDataType } from 'lib/types';
import { track } from 'hoc/CustomerIO';

const OrderModalHeading = styled.h4`
  display: inline-block;
  vertical-align: middle;
  .mdi-icon {
    display: inline-block;
    margin-right: 0.5em;
  }
`;

const OrderModalFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  a {
    margin-left: 1em;
    display: inline-block;
  }
`;

const OrderModalContent = styled.div`
  p {
    font-size: 16px;
    font-weight: 300;
    margin-bottom: 0;
  }
`;

type OrderProps = { orderUrl: string; setVisible: (newState: boolean) => void };

const OrderModal: React.FC<OrderProps> = ({
  orderUrl,
  setVisible,
}): ReactElement => {
  const { diary } = useSelector(({ diary }: AppState) => diary);
  const [metaData, setMetaData] = useState<DiaryOrderMetaDataType | undefined>(
    undefined
  );
  const { t } = useTranslation();
  const [modalStatus, setModalStatus] = useState<OrderModalStatus>(
    OrderModalStatus.Closed
  );

  const orderDiary = useCallback(() => {
    track('Order Started', null);
    window.location.href = orderUrl ?? '/';
  }, [orderUrl]);

  interface DiaryOrderMetadata {
    diaryPageCount: number;
    isOrderable: boolean;
    maximumOrderPageCount: number;
    minimumOrderPageCount: number;
    printPageCount: number;
  }

  const getOrderMetadata = useCallback(async () => {
    const diaryOrderMetaData = await getDiaryOrderMetaData(diary?.id!);

    if (diaryOrderMetaData) {
      setMetaData(diaryOrderMetaData);
      if (
        diaryOrderMetaData.diaryPageCount <
        diaryOrderMetaData.minimumOrderPageCount
      ) {
        setModalStatus(OrderModalStatus.LessThanLimit);
      } else if (!diaryOrderMetaData.isOrderable) {
        setModalStatus(OrderModalStatus.MoreThanLimit);
      } else {
        orderDiary();
      }
    }
  }, [diary, orderDiary]);

  useEffect(() => {
    getOrderMetadata();
  }, [getOrderMetadata]);

  return (
    <>
      {modalStatus === OrderModalStatus.Closed ? null : (
        <Modal
          title={
            <OrderModalHeading>
              <GetIcon path={mdiAlertCircleOutline} className='large' />{' '}
              {t('orderDiary')}
            </OrderModalHeading>
          }
          onCancel={() => setVisible(false)}
          visible={true}
          footer={
            <OrderModalFooter key='1'>
              {modalStatus === OrderModalStatus.LessThanLimit ? (
                <Button
                  outlined
                  size='medium'
                  onClick={() => setVisible(false)}
                >
                  {t('btnNo')}
                </Button>
              ) : (
                <Button filled size='medium' onClick={() => setVisible(false)}>
                  {t('closeBtnTitle')}
                </Button>
              )}

              {modalStatus !== OrderModalStatus.MoreThanLimit && (
                <Button
                  as={Anchor}
                  filled
                  size='medium'
                  onClick={orderDiary}
                  href={orderUrl}
                >
                  {t('proceedBtnTxt')}
                </Button>
              )}
            </OrderModalFooter>
          }
        >
          <OrderModalContent>
            <Paragraph>
              {modalStatus === OrderModalStatus.LessThanLimit &&
                t('lessThanMin', {
                  minPages: metaData?.minimumOrderPageCount || '',
                })}
              {modalStatus === OrderModalStatus.MoreThanLimit &&
                t('moreThanMax', {
                  maxPages: metaData?.maximumOrderPageCount || '',
                })}
            </Paragraph>
          </OrderModalContent>
        </Modal>
      )}
    </>
  );
};

export default OrderModal;
