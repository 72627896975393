import { useEffect } from 'react';
import Oidc from 'oidc-client';
import axios from 'axios';
import { requestInterceptor } from 'util/AxiosHeaders';
import { History } from 'history';
import { useTranslation } from 'react-i18next';
import { AccountProps } from 'lib/types';
import { initialState } from '../../store/reducers/user-reducer';

export const requestAccountData = async (): Promise<AccountProps> => {
  try {
    return await axios
      .get(`${process.env.REACT_APP_API_URL}v1/me`)
      .then((res) => res.data && res.data.data);
  } catch (e) {
    console.error('<< ACCOUNT DATA REQUEST FAILED >>');
    return initialState.userAccount;
  }
};

const getLang = (account: AccountProps, history: History): string => {
  if (account) {
    let redirect = localStorage.getItem('oidc_redirect') || '/en/diary';
    let url = redirect.split('/');
    if (account.language && url[1] !== account.language.toLowerCase()) {
      url[1] = account.language.toLowerCase();
      redirect = url.join('/');
    }
    history.replace(redirect);
    localStorage.removeItem('oidc_redirect');
    return url[1];
  } else return localStorage.getItem('i18nextLng') || 'en';
};

const SignInOidc = (props: { history: History }) => {
  const { i18n } = useTranslation();

  useEffect(() => {
    new Oidc.UserManager({ response_mode: 'query' })
      .signinRedirectCallback()
      .then(async (user) => {
        requestInterceptor(user);
        const account = await requestAccountData();
        const lang = getLang(account, props.history);
        i18n.changeLanguage(lang);
      })
      .catch((e) => {
        console.error(e);
        props.history.replace(
          localStorage.getItem('oidc_redirect') || 'en/diary'
        );
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export default SignInOidc;
