import React, { useEffect, useMemo, useState } from 'react';
import { Modal, Select, Switch, Tooltip, message } from 'antd';
import { Button } from 'components/Common/Button';
import { H4, Span } from 'components/Common/Text';
import { useTranslation } from 'react-i18next';
import {
  publishDiary,
  unpublishDiary,
  closeShareModal,
  openEmailSection,
} from 'store/actions/share-actions';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'store/reducers/rootReducer';
import {
  mdiFacebook,
  mdiTwitter,
  mdiEmailSendOutline,
  mdiInformation,
} from '@mdi/js';
import GetIcon from 'components/Common/Icon';
import ShareEmail from 'components/Editor/Modals/ShareModal/shareEmail';
import { resetShareMessage } from 'store/actions/share-actions';
import styled from 'styled-components/macro';
import CoverLoader from 'components/Common/CoverLoader';

const { REACT_APP_WEBSITE_URL, REACT_APP_NAME } = process.env;
const shareText = `Check out my new story on ${REACT_APP_NAME}`;

// Styled Components
const DiaryTitle = styled.h2`
  font-size: 16px;
  margin-bottom: 0.7em;
  position: relative;
  left: 0.9em;
  font-weight: 400;

  .mdi-icon {
    color: ${({ theme }) => theme.primaryColor};
    position: relative;
    left: 0.1em;
    top: -0.35em;
  }
`;

const SwitchWrap = styled.div`
  display: flex;
  justify-content: flex-start;
  padding: 1em;

  span {
    font-size: 1em;
  }

  .ant-switch {
    margin-left: 0.8em;
    position: relative;
    top: -1px;
  }
`;

const ShareButtonsWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 1.5em;

  @media (max-width: 500px) {
    flex-direction: column;
    flex-wrap: wrap;
  }

  button {
    font-size: 14px;
    margin-right: 1.2em;
    &:last-of-type {
      margin-right: 0;
    }

    @media (max-width: 768px) {
      font-size: 13px;
    }

    @media (max-width: 550px) {
      font-size: 12px;
      margin-right: 0.7em;
    }

    @media (max-width: 500px) {
      width: 100%;
      margin-right: 0;
      margin-bottom: 1em;
      font-size: 15px;
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
`;

// Types
type SelectProps = { id: string; title: string };

const ShareModal: React.FC = () => {
  const { t, i18n } = useTranslation();
  const defaultSelect = useMemo(
    (): SelectProps[] => [{ id: '', title: t('entireDiary') }],
    [t]
  );
  const lang = i18n.language;
  const dispatch = useDispatch();
  const { diary: data } = useSelector(({ diary }: AppState) => diary);
  const { chapters } = useSelector(({ chapters }: AppState) => chapters);
  const { showModal, showEmailSection, shareMessage, isShareing } = useSelector(
    ({ shareDiary }: AppState) => shareDiary
  );
  const [selectData, setSelectData] = useState<SelectProps[]>([]);
  const [chapter, setChapter] = useState<string>('');
  const [shareUrl, setShareUrl] = useState<string>('');

  useEffect(() => {
    if (shareMessage.content) {
      message[shareMessage.type]({
        content: shareMessage.content,
        style: {
          marginTop: '60px',
        },
      });
      dispatch(resetShareMessage());
    }
  }, [dispatch, shareMessage]);

  const shareDiary = (type) => {
    switch (type) {
      case 'fb':
        const fb = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
          shareUrl
        )}`;
        window.open(fb, '_blank');
        dispatch(closeShareModal());
        break;
      case 'tw':
        const tw = `https://twitter.com/intent/tweet?text=${shareText}:&url=${encodeURIComponent(
          shareUrl
        )}&hashtags=traveldiariesapp`;
        window.open(tw, '_blank');
        dispatch(closeShareModal());
        break;
    }
  };

  useEffect(() => {
    const prepareOptions = async () => {
      let available: SelectProps[] = chapters
        .filter((c) => c.isPublic)
        .map((c) => ({ id: c.id, title: c.title }));
      //pipe => the first chapter when 'Entire diary' is selected
      if (data?.isPublic) available = [...defaultSelect, ...available];
      setSelectData(available);
    };
    if (chapters.length) {
      prepareOptions();
      return () => {
        setSelectData(defaultSelect);
      };
    }
  }, [chapters, data, defaultSelect, t]);

  useEffect(() => {
    if (!selectData.length || !showModal) setChapter('');
    else if (typeof showModal === 'string') {
      if (selectData.findIndex((c) => c.id === showModal) !== -1)
        setChapter(showModal);
    } else setChapter(selectData[0].id);
  }, [selectData, showModal]);

  useEffect(() => {
    let url = `${REACT_APP_WEBSITE_URL}/${lang}/diary/${data?.id}/chapter/`;
    //pipe is here because we want to send
    //the first chapter when 'Entire diary' is selected
    url += chapter ? chapter.split('|')[0] : chapters[0] ? chapters[0].id : '';
    setShareUrl(url);
  }, [chapter, lang, data, chapters]);

  return (
    <Modal
      title={<H4>{data?.name}</H4>}
      visible={!!data && !!showModal}
      style={{ top: '3%' }}
      width='600px'
      closable={true}
      onCancel={() => dispatch(closeShareModal())}
      footer={null}
    >
      {!showEmailSection ? (
        <>
          <DiaryTitle>
            {t('shareYourDiary')}{' '}
            {!data?.isPublic && (
              <Tooltip title={t('shareDiaryPublic')} placement='top'>
                <GetIcon path={mdiInformation} className='large' />
              </Tooltip>
            )}
          </DiaryTitle>
          <Select
            value={chapter}
            disabled={isShareing}
            onChange={(val) => setChapter(val as string)}
            style={{ width: '100%' }}
          >
            {selectData &&
              selectData.map((s) => (
                <Select.Option key={s.id} value={s.id}>
                  {s.title}
                </Select.Option>
              ))}
          </Select>
          <SwitchWrap>
            <Span>{t('diarySetTo', { diaryName: data?.name || '' })}</Span>
            <Tooltip title={t('shareDiaryPublic')} placement='top'>
              <Switch
                key='switch'
                checked={data?.isPublic}
                checkedChildren={<span>{t('public')}</span>}
                unCheckedChildren={<span>{t('private')}</span>}
                onClick={(checked) =>
                  dispatch(
                    checked ? publishDiary(data!) : unpublishDiary(data!)
                  )
                }
              />
            </Tooltip>
          </SwitchWrap>
          <ShareButtonsWrap>
            <Button
              onClick={() => shareDiary('fb')}
              facebook
              size='small'
              disabled={!selectData.length}
            >
              <GetIcon path={mdiFacebook} className='large' /> Facebook
            </Button>
            <Button
              onClick={() => shareDiary('tw')}
              twitter
              size='small'
              disabled={!selectData.length}
            >
              <GetIcon path={mdiTwitter} className='large' /> Twitter
            </Button>
            <Button
              onClick={() => dispatch(openEmailSection(chapter))}
              filled
              size='small'
              disabled={!selectData.length}
            >
              <GetIcon path={mdiEmailSendOutline} className='large' />
              {t('shareViaEmail')}
            </Button>
          </ShareButtonsWrap>
          {isShareing && <CoverLoader />}
        </>
      ) : (
        <ShareEmail selectedChapter={chapter} />
      )}
    </Modal>
  );
};

export default ShareModal;
