import React from 'react'
import Icon from "@mdi/react";
import { IconProps } from '@mdi/react/dist/IconProps'

type IconPropsEx = IconProps & { onClick?: any }

const GetIcon = ({ className = "", ...props }: IconPropsEx): JSX.Element => {
    const icon = <Icon className={`mdi-icon ${className}`} {...props} />
    if (props.onClick) return <span onClick={props.onClick}>{icon}</span>
    return icon
};

export default GetIcon;