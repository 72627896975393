import React from "react";
import { Button } from "components/Common/Button";
import caretRight from "assets/images/icons/caret_right_white.svg";
import caretLeft from "assets/images/icons/caret_left_white.svg";
import styled from "styled-components/macro";
import { useTranslation } from 'react-i18next';

const { REACT_APP_NAME } = process.env;

// Styled Components
const PaginationWrap = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: ${({ step }) => (step > 1 ? "space-between" : "flex-end")};

	@media (max-width: 768px) {
		flex-direction: column;
		button {
			&:first-of-type {
				margin-bottom: 1.5em;
			}
		}
	}
`;

const Image = styled.img`
	width: 1em;
	height: 1em;
	display: inline-block;

	${({ left }) =>
		left && {
			marginRight: "1em",
		}}
	${({ right }) =>
		right && {
			marginLeft: "1em",
		}}
`;

// Types
type PaginationType = {
	currentView: number;
	clicked: (id) => void;
	saveDiary: () => void;
	nextDisabled: boolean;
};

const Pagination: React.FC<PaginationType> = ({ currentView, clicked, saveDiary, nextDisabled }) => {
	const { t } = useTranslation()

	const PaginationName = {
		1: t("addTitle"),
		2: t("addCover"),
		3: t("chooseStyle")
	};
	const renderClicked = () =>
		currentView < 3 ? clicked(currentView + 1) : saveDiary();

	const renderNextIcon = () => <Image right src={caretRight} alt={`${REACT_APP_NAME} - Caret Right`} />

	return (
		<PaginationWrap step={currentView}>
			{currentView > 1 && (
				<Button
					size="medium"
					filled
					disabled={currentView === 1}
					onClick={() => clicked(currentView - 1)}>
					<Image left src={caretLeft} alt={`${REACT_APP_NAME} - Caret Left`} />
					{PaginationName[currentView - 1]}
				</Button>
			)}

			<Button size="medium" filled onClick={() => renderClicked()} disabled={nextDisabled}>
				{currentView === 3 ? t("save") : PaginationName[currentView + 1]}
				{renderNextIcon()}
			</Button>
		</PaginationWrap>
	);
};

export default Pagination;
