import React, { ReactElement, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Modal } from 'antd';
import { H4 } from 'components/Common/Text';
import { ImageBoxType, PointsDataType } from 'lib/types';
import ImageCropUpload, { ImageUploader } from './ImageCropUpload';
import EmptyBoxForm from './EmptyBoxForm';
import {
  saveEmptyBox,
  saveImage,
  saveMap,
  setImageChange,
} from 'store/actions/chapter-actions';
import { AppState } from 'store/reducers/rootReducer';
import SimpleMap from './Map/SimpleMap';
import RouteMap from './Map/RouteMap';
import SelectMapType from './Map/SelectMapType';

const ImageModal: React.FC = ({ ...props }): ReactElement => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { imageChange } = useSelector(({ chapters }: AppState) => chapters);
  const modalSize =
    imageChange?.type === ImageBoxType.SIMPLE_MAP ||
    imageChange?.type === ImageBoxType.ROUTE_MAP
      ? 1000
      : 750;

  const onClose = useCallback(() => {
    dispatch(setImageChange(null));
  }, [dispatch]);

  const modalTitle = useCallback(() => {
    switch (imageChange?.type) {
      case ImageBoxType.IMAGE:
        return t('uploadImage');
      case ImageBoxType.EMPTYBOX:
        return t('emptyBox');
      case ImageBoxType.SIMPLE_MAP:
        return t('simpleMap');
      case ImageBoxType.ROUTE_MAP:
        return t('routeMap');
      case ImageBoxType.MAP_TYPE:
        return t('chooseMapType');
    }
    return 'Modal';
  }, [imageChange, t]);

  const onImageSend = useCallback(
    (file: any) => {
      if (!imageChange) return;
      dispatch(saveImage(imageChange, file));
    },
    [dispatch, imageChange]
  );

  const FormBox = () => {
    if (!imageChange) return null;
    const onBoxSend = (desc: string) =>
      dispatch(saveEmptyBox(imageChange, desc));
    const onSimpleMapSend = (data: PointsDataType, image: string) =>
      dispatch(saveMap(imageChange, data, image, false));
    const onRouteMapSend = (data: PointsDataType, image: string) =>
      dispatch(saveMap(imageChange, data, image, true));

    switch (imageChange?.type) {
      case ImageBoxType.IMAGE:
        return (
          <Modal
            title={<H4>{modalTitle()}</H4>}
            maskClosable={false}
            visible={imageChange?.type === ImageBoxType.IMAGE}
            onCancel={onClose}
            footer={false}
            width={modalSize}
            destroyOnClose={true}
            forceRender={true}
          >
            <ImageUploader hidden={false} />
          </Modal>
        );
      case ImageBoxType.EDIT_IMAGE:
        return (
          <Modal
            visible={imageChange?.type === ImageBoxType.EDIT_IMAGE}
            width={'60%'}
            title={t('cropImage')}
            onCancel={() => {
              dispatch(setImageChange(null));
            }}
            destroyOnClose={true}
            forceRender={true}
            footer={false}
          >
            <ImageCropUpload onImageSend={onImageSend} />
          </Modal>
        );

      case ImageBoxType.EMPTYBOX:
        return (
          <Modal
            title={<H4>{modalTitle()}</H4>}
            maskClosable={false}
            visible={imageChange?.type === ImageBoxType.EMPTYBOX}
            onCancel={onClose}
            footer={false}
            width={modalSize}
            destroyOnClose={true}
            forceRender={true}
          >
            <EmptyBoxForm imageChange={imageChange} onBoxSend={onBoxSend} />
          </Modal>
        );
      case ImageBoxType.ROUTE_MAP:
        return (
          <Modal
            title={<H4>{modalTitle()}</H4>}
            maskClosable={false}
            visible={imageChange?.type === ImageBoxType.ROUTE_MAP}
            onCancel={onClose}
            footer={false}
            width={modalSize}
            destroyOnClose={true}
            forceRender={true}
          >
            <RouteMap onRouteMapSend={onRouteMapSend} />
          </Modal>
        );
      case ImageBoxType.SIMPLE_MAP:
        return (
          <Modal
            title={<H4>{modalTitle()}</H4>}
            maskClosable={false}
            visible={imageChange?.type === ImageBoxType.SIMPLE_MAP}
            onCancel={onClose}
            footer={false}
            width={modalSize}
            destroyOnClose={true}
            forceRender={true}
          >
            <SimpleMap onSimpleMapSend={onSimpleMapSend} />;
          </Modal>
        );
      case ImageBoxType.MAP_TYPE:
        return (
          <Modal
            title={<H4>{modalTitle()}</H4>}
            maskClosable={false}
            visible={imageChange?.type === ImageBoxType.MAP_TYPE}
            onCancel={onClose}
            footer={false}
            width={modalSize}
            destroyOnClose={true}
            forceRender={true}
          >
            <SelectMapType />
          </Modal>
        );
      default:
        return null;
    }
  };

  return <FormBox />;
};

export default ImageModal;
