import React, { useState, useEffect } from 'react';
import styled from "styled-components/macro";
import GetIcon from "components/Common/Icon"
import { mdiClose } from "@mdi/js"
import { Checkbox } from "@createnl/grouped-checkboxes";
import usePrevious from 'util/usePrevious';

interface Props {
	onDelete: (index: number) => void;
	onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
	value: string;
	index: number;
	isSelectAllChecked: boolean;
	selectedEmailsCount: number;
}

const RemovableChecboxWrapper = styled.div`
	width: 48%;
	display : flex;
	align-items: center;
	flex-direction: row;
	font-weight: 600;
	flex-wrap: no-wrap;
	justify-content: flex-start;
	margin: 4px 0;

	@media (max-width: 478px) {
		width: 100%;
	}

	svg {
		cursor:pointer;
		color: ${({ theme }) => theme.primaryColor};
	}
`;

const CheckboxLabel = styled.label`
	display: block;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 80%;
	margin-right: 4px;
	font-weight: 700;
	@media (max-width: 768px) {
		width: 100%;
		max-width: unset;
	}
	svg {
		cursor:pointer;
		color: ${({ theme }) => theme.primaryColor};
	}
`;

const RemovableCheckbox: React.FC<Props> = ({ onChange, onDelete, index, value, isSelectAllChecked, selectedEmailsCount }) => {
	const [checked, setChecked] = useState(false);
	const selectAll = usePrevious(isSelectAllChecked)
	const deleteCheckbox = (e: React.MouseEvent<HTMLInputElement>) => {
		e.stopPropagation();
		onDelete(index);
	}

	const onCheckedChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		e.stopPropagation()
		setChecked(e.target.checked);
		onChange(e);
	}

	useEffect(() => {
		if (selectAll !== isSelectAllChecked)
			if (!selectedEmailsCount && !isSelectAllChecked) {
				setChecked(false);
			} else if (isSelectAllChecked) {
				setChecked(true);
			}
	}, [isSelectAllChecked, selectedEmailsCount, selectAll])

	return (
		<RemovableChecboxWrapper>
			<div className={'ant-checkbox-wrapper ant-checkbox-wrapper-custom'}>
				<span className={`ant-checkbox ant-checkbox-custom ${(checked) && 'ant-checkbox-checked'}`}>
					<Checkbox
						checked={checked}
						key={value}
						value={value}
						onChange={onCheckedChange}
						id={`${value}-${index}`}
						className={'ant-checkbox-input'}
					/>
					<span className="ant-checkbox-inner"></span>
				</span>
				<CheckboxLabel htmlFor={`${value}-${index}`}> {value} </CheckboxLabel>
				<GetIcon path={mdiClose} onClick={deleteCheckbox} />
			</div>
		</RemovableChecboxWrapper>
	);
};

export default RemovableCheckbox;
