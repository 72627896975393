import React from "react";
import styled from "styled-components";

const LoaderOverlay = styled.div`
	position: absolute;
	left: 0;
	top: 0;
	bottom: 0;
	right: 0;
	background: #ffffff;
	opacity: 0.4;
	z-index: 2;
	display: flex;
	align-items: center;
	justify-content: center;
`;

const LoaderWrap = styled.div`
	color: ${({ theme }) => theme.primaryColor};
	font-size: 7px;
	position: relative;
	margin: -4em auto 0;
	text-indent: -9999em;
	-webkit-transform: translateZ(0);
	-ms-transform: translateZ(0);
	transform: translateZ(0);
	-moz-animation-delay: -0.16s;
	-webkit-animation-delay: -0.16s;
	animation-delay: -0.16s;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	border-radius: 50%;
	width: 2em;
	height: 2em;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
	-webkit-animation: load7 1.8s infinite ease-in-out;
	animation: loadKeyframe 1.8s infinite ease-in-out;

	&:before,
	&:after {
		content: "";
		position: absolute;
		top: 0;
		-webkit-border-radius: 50%;
		-moz-border-radius: 50%;
		border-radius: 50%;
		width: 2em;
		height: 2em;
		-webkit-animation-fill-mode: both;
		animation-fill-mode: both;
		-webkit-animation: loadKeyframe 1.8s infinite ease-in-out;
		animation: loadKeyframe 1.8s infinite ease-in-out;
	}

	&:before {
		left: -3.5em;
		-webkit-animation-delay: -0.32s;
		animation-delay: -0.32s;
	}

	&:after {
		left: 3.5em;
	}

	@-webkit-keyframes loadKeyframe {
		0%,
		80%,
		100% {
			-webkit-box-shadow: 0 2.5em 0 -1.3em;
			-moz-box-shadow: 0 2.5em 0 -1.3em;
			box-shadow: 0 2.5em 0 -1.3em;
		}
		40% {
			-webkit-box-shadow: 0 2.5em 0 0;
			-moz-box-shadow: 0 2.5em 0 0;
			box-shadow: 0 2.5em 0 0;
		}
	}
	@keyframes loadKeyframe {
		0%,
		80%,
		100% {
			-webkit-box-shadow: 0 2.5em 0 -1.3em;
			-moz-box-shadow: 0 2.5em 0 -1.3em;
			box-shadow: 0 2.5em 0 -1.3em;
		}
		40% {
			-webkit-box-shadow: 0 2.5em 0 0;
			-moz-box-shadow: 0 2.5em 0 0;
			box-shadow: 0 2.5em 0 0;
		}
	}
`;

const CoverLoader = () => {
	return (
		<LoaderOverlay>
			<LoaderWrap>Loading...</LoaderWrap>;
		</LoaderOverlay>
	);
};

export default CoverLoader;
