import React, { ReactElement } from "react";
import styled from "styled-components";

const ErrorWrap = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100vw;
	height: 100vh;
	margin-top: -15em;
`;

const ErrorMessage = ({ children }): ReactElement => {
	return <ErrorWrap>{children}</ErrorWrap>;
};

export default ErrorMessage;
