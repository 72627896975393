import React, { ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { H4, Paragraph } from "components/Common/Text";
import LoadingScreen from "components/Common/CoverLoader";
import styled from "styled-components/macro";
import { AppState } from "store/reducers/rootReducer";
import { getLayouts, updateLayout } from "store/actions/chapter-actions";
import { LayoutChangeEnum, LayoutType } from "lib/types";

// Styled Components

const LayoutsWrap = styled.div`
	height: 100%;
	padding-bottom: 1em;

	${({ isLoaded }) => (isLoaded ? { animation: "fadeIn 0.5s linear" } : { opacity: 0 })}
`;

const Header = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding: 1.75em 2.5em;
	background: ${({ theme }) => theme.primaryColor};
	overflow: hidden;
	-webkit-transition: 0.15s all ease-in-out;
	-moz-transition: 0.15s all ease-in-out;
	transition: 0.15s all ease-in-out;

	h4 {
		margin-bottom: 0;
		color: ${({ theme }) => theme.whiteColor};
		white-space: nowrap;
	}

	&:hover {
		background: ${({ theme }) => theme.primaryHover};
	}
`;

const Content = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
`;

const ContentHeader = styled.div`
	text-align: center;
	padding: 2.5em;

	h4 {
		text-transform: uppercase;
		margin-top: 0.5em;
	}
`;

const ContentLayoutItems = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	height: 100%;
	overflow: auto;
	margin-top: 4em;
	margin-bottom: 6em;
`;

const ContentLayoutItemWrap = styled.div`
	flex: 0 1 33%;
	padding: 6px;

	@media (max-width: 992px) {
		flex: 0 1 20%;
	}
`

const ContentLayoutItem = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	-webkit-box-shadow: rgba(33, 33, 33, 0.3) 1px 1px 3px 2px;
	-moz-box-shadow: rgba(33, 33, 33, 0.3) 1px 1px 3px 2px;
	box-shadow: rgba(33, 33, 33, 0.3) 1px 1px 3px 2px;
`;

const Image = styled.img`
	-webkit-transition: 0.2s filter ease-in-out;
	-moz-transition: 0.2s filter ease-in-out;
	transition: 0.2s filter ease-in-out;
	&:hover {
		-webkit-filter: brightness(50%);
		-moz-filter: brightness(50%);
		filter: brightness(50%);
	}
`;

// Types
type LayoutsTypes = {
};

const Layouts: React.FC<LayoutsTypes> = (): ReactElement => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const { layouts, isLayoutsLoading, layoutChange, isChapterUpdating }
		= useSelector(({ chapters }: AppState) => chapters)
	const [loaded, setLoaded] = useState(false);

	useEffect(() => {
		if (!layouts.length) dispatch(getLayouts());
		else setLoaded(true);
	}, [layouts, dispatch]);

	const changeLayout = (layout: any) => {
		const data = {
			chapterId: layoutChange!.sheet.id,
			pageId: layoutChange!.sheet.layoutPage.pageId,
			type: layoutChange!.type,
			layout
		}
		dispatch(updateLayout(data))
	}

	const layoutItem = (layout: LayoutType) => {
		return (
			<ContentLayoutItemWrap key={layout.sortOrder} draggable={false}>
				<ContentLayoutItem onClick={() => changeLayout(layout.layout)}>
					<Image src={layout.icon} alt={layout.name} />
				</ContentLayoutItem>
			</ContentLayoutItemWrap >
		);
	}

	const renderLayoutItems = () => {
		let availableLayouts: LayoutType[] = []
		if (layouts.length) {
			availableLayouts = layouts;
			if (layoutChange) {
				// If page is first page and contains header
				if (layoutChange.sheet.page.header) {
					availableLayouts = layouts.filter((layout) =>
						layout.layout.canBeUsedOnFirstPage ||
						layoutChange.type === LayoutChangeEnum.RIGHT);
				}
				// Extract text layout 
				if (layoutChange?.type !== LayoutChangeEnum.CHANGE)
					availableLayouts = availableLayouts.filter(layout => layout.sortOrder !== 0);
			}
		}
		return availableLayouts.map(layout => layoutItem(layout));
	}

	return (
		<LayoutsWrap isLoaded={loaded}>
			<Header>
				<H4>{t('layouts')}</H4>
			</Header>
			<Content>
				<ContentHeader>
					<H4>{t('addLayout')}</H4>
					<Paragraph size={1.4}>{t('layoutsWarning')}</Paragraph>
				</ContentHeader>
				{isLayoutsLoading || isChapterUpdating
					? <LoadingScreen />
					: <ContentLayoutItems>{renderLayoutItems()}</ContentLayoutItems>}
			</Content>
		</LayoutsWrap>
	);
};

export default Layouts;
