import axios from 'axios';
import i18n from 'i18n';
import { languageRegions } from './languages';

const requestInterceptor = (user: any) => {
  return axios.interceptors.request.use(
    (config) => {
      const token = user.access_token;

      if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
      }

      config.headers['Accept-Language'] =
        languageRegions[i18n.language] || languageRegions.en;
      config.headers['Content-Type'] = 'application/json';
      config.headers['x-api-key'] = process.env.REACT_APP_PRODUCT_API_KEY;
      config.baseURL = process.env.REACT_APP_API_URL;

      return config;
    },
    (error) => {
      Promise.reject(error);
    }
  );
};

const responseInterceptor = () => {
  return axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (401 === error.response.status) {
        sessionStorage.clear(); //oidc has session storage
        window.location.reload();
      }
      return Promise.reject(error);
    }
  );
};
export { responseInterceptor, requestInterceptor };
