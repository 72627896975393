import {
  ChapterContentType,
  LayoutType,
  ImageChangeType,
  LayoutChangeType,
  LayoutChangeEnum,
  ImageBoxType,
  PointsDataType,
} from 'lib/types';

export const SET_PAGE_NUMBER = 'SET_PAGE_NUMBER';
export const SET_MODAL_CHAPTER = 'SET_MODAL_CHAPTER';
export const CHANGE_LAYOUT = 'CHANGE_LAYOUT';
export const SET_IMAGE_CHANGE = 'SET_IMAGE_CHANGE';
export const SET_IMAGE_CHANGE_TYPE = 'SET_IMAGE_CHANGE_TYPE';
export const SET_PREVIEW = 'SET_PREVIEW';
export const PUBLISH_ALL_CHAPTERS = 'PUBLISH_ALL_CHAPTERS';
export const UNPUBLISH_ALL_CHAPTERS = 'UNPUBLISH_ALL_CHAPTERS';

export const GET_LAYOUTS_START_SAGA = 'GET_LAYOUTS_START_SAGA';
export const GET_LAYOUTS_START = 'GET_LAYOUTS_START';
export const GET_LAYOUTS_SUCCESS = 'GET_LAYOUTS_SUCCESS';
export const GET_LAYOUTS_ERROR = 'GET_LAYOUTS_ERROR';

export const GET_CHAPTERS_START_SAGA = 'GET_CHAPTERS_START_SAGA';
export const GET_CHAPTERS_START = 'GET_CHAPTERS_START';
export const GET_CHAPTERS_SUCCESS = 'GET_CHAPTERS_SUCCESS';
export const GET_CHAPTERS_COMPLETE = 'GET_CHAPTERS_COMPLETE';
export const GET_CHAPTERS_ERROR = 'GET_CHAPTERS_ERROR';

export const GET_MAP_START_SAGA = 'GET_MAP_START_SAGA';
export const GET_MAP_START = 'GET_MAP_START';
export const GET_MAP_SUCCESS = 'GET_MAP_SUCCESS';
export const GET_MAP_INSERT = 'GET_MAP_INSERT';
export const GET_MAP_UPDATE = 'GET_MAP_UPDATE';
export const GET_MAP_LOADED = 'GET_MAP_LOADED';
export const GET_MAP_ERROR = 'GET_MAP_ERROR';
export const UPDATE_MAP_SUCCESS = 'UPDATE_MAP_SUCCESS';

export const GET_SHEETS_START_SAGA = 'GET_SHEETS_START_SAGA';
export const GET_SHEETS_START = 'GET_SHEETS_START';
export const GET_SHEETS_SUCCESS = 'GET_SHEETS_SUCCESS';
export const GET_SHEETS_INSERT = 'GET_SHEETS_INSERT';
export const GET_SHEETS_UPDATE = 'GET_SHEETS_UPDATE';
export const GET_SHEETS_LOADED = 'GET_SHEETS_LOADED';
export const GET_SHEETS_ERROR = 'GET_SHEETS_ERROR';
export const UPDATE_SHEETS_SUCCESS = 'UPDATE_SHEETS_SUCCESS';

export const POST_CHAPTER_START_SAGA = 'POST_CHAPTER_START_SAGA';
export const POST_CHAPTER_START = 'POST_CHAPTER_START';
export const POST_CHAPTER_SUCCESS = 'POST_CHAPTER_SUCCESS';
export const POST_CHAPTER_ERROR = 'POST_CHAPTER_ERROR';

export const SORT_CHAPTER_START_SAGA = 'SORT_CHAPTER_START_SAGA';
export const SORT_CHAPTER_START = 'SORT_CHAPTER_START';
export const SORT_CHAPTER_SUCCESS = 'SORT_CHAPTER_SUCCESS';
export const SORT_CHAPTER_ERROR = 'SORT_CHAPTER_ERROR';

export const PUT_CHAPTER_START_SAGA = 'PUT_CHAPTER_CHANGES_START_SAGA';
export const PUT_CHAPTER_START = 'PUT_CHAPTER_CHANGES_START';
export const PUT_CHAPTER_SUCCESS = 'PUT_CHAPTER_CHANGES_SUCCESS';
export const PUT_CHAPTER_ERROR = 'PUT_CHAPTER_CHANGES_ERROR';

export const DELETE_CHAPTER_START_SAGA = 'DELETE_CHAPTER_START_SAGA';
export const DELETE_CHAPTER_START = 'DELETE_CHAPTER_START';
export const DELETE_CHAPTER_SUCCESS = 'DELETE_CHAPTER_SUCCESS';
export const DELETE_CHAPTER_ERROR = 'DELETE_CHAPTER_ERROR';

export const PUT_LAYOUT_START_SAGA = 'PUT_LAYOUT_START_SAGA';
export const PUT_LAYOUT_START = 'PUT_LAYOUT_START';
export const PUT_LAYOUT_SUCCESS = 'PUT_LAYOUT_SUCCESS';
export const PUT_LAYOUT_ERROR = 'PUT_LAYOUT_ERROR';

export const POST_IMAGE_START_SAGA = 'POST_IMAGE_START_SAGA';
export const POST_IMAGE_START = 'POST_IMAGE_START';
export const POST_IMAGE_SUCCESS = 'POST_IMAGE_SUCCESS';
export const POST_IMAGE_ERROR = 'POST_IMAGE_ERROR';

export const POST_MAP_START_SAGA = 'POST_MAP_START_SAGA';
export const POST_MAP_START = 'POST_MAP_START';
export const POST_MAP_SUCCESS = 'POST_MAP_SUCCESS';
export const POST_MAP_ERROR = 'POST_MAP_ERROR';

export const PUT_EMPTY_BOX_START_SAGA = 'PUT_EMPTY_BOX_START_SAGA';
export const PUT_EMPTY_BOX_START = 'PUT_EMPTY_BOX_START';
export const PUT_EMPTY_BOX_SUCCESS = 'PUT_EMPTY_BOX_SUCCESS';
export const PUT_EMPTY_BOX_ERROR = 'PUT_EMPTY_BOX_ERROR';

export interface ISetPageNumber {
  type: typeof SET_PAGE_NUMBER;
  payload: number;
}
export interface ISetModalChapter {
  type: typeof SET_MODAL_CHAPTER;
  payload: string | null;
}
export interface IChangeLayout {
  type: typeof CHANGE_LAYOUT;
  payload: LayoutChangeType | undefined;
}
export interface ISetEnoughSheetsLoaded {
  type: typeof GET_SHEETS_LOADED;
}
export interface ISetImageChange {
  type: typeof SET_IMAGE_CHANGE;
  payload: ImageChangeType | null;
}
export interface ISetImageChangeType {
  type: typeof SET_IMAGE_CHANGE_TYPE;
  payload: ImageBoxType;
}
export interface ISetPreview {
  type: typeof SET_PREVIEW;
}
export interface IPublishAllChapters {
  type: typeof PUBLISH_ALL_CHAPTERS;
}
export interface IUnpublishAllChapters {
  type: typeof UNPUBLISH_ALL_CHAPTERS;
}

export interface IGetChaptersStartSaga {
  type: typeof GET_CHAPTERS_START_SAGA;
  payload: string;
}

export interface IGetChaptersStartAction {
  type: typeof GET_CHAPTERS_START;
}

export interface IGetChaptersSuccessAction {
  type: typeof GET_CHAPTERS_SUCCESS;
  payload: ChapterContentType[];
}

export interface IGetChaptersCompleteAction {
  type: typeof GET_CHAPTERS_COMPLETE;
}

export interface IGetChaptersErrorAction {
  type: typeof GET_CHAPTERS_ERROR;
  payload: string;
}

export interface IGetMapStartSaga {
  type: typeof GET_MAP_START_SAGA;
  payload: { diaryId: string; chapterIds: string[]; isUpdate: boolean };
}

export interface IGetChapterStartSaga {
  type: typeof GET_SHEETS_START_SAGA;
  payload: { diaryId: string; chapterIds: string[]; isUpdate: boolean };
}

export interface IInsertChapterSheets {
  type: typeof GET_SHEETS_INSERT;
  payload: ChapterContentType;
}

export interface IUpdateChapterSheets {
  type: typeof GET_SHEETS_UPDATE;
  payload: ChapterContentType;
}

export interface IGetChapterStartAction {
  type: typeof GET_SHEETS_START;
  payload: boolean;
}

export interface IGetChapterSuccessAction {
  type: typeof GET_SHEETS_SUCCESS;
}

export interface IUpdateChapterSuccessAction {
  type: typeof UPDATE_SHEETS_SUCCESS;
}

export interface IGetChapterErrorAction {
  type: typeof GET_SHEETS_ERROR;
  payload: string;
}

export interface IPostChapterStartSaga {
  type: typeof POST_CHAPTER_START_SAGA;
  payload: { content: ChapterContentType; withClose: boolean };
}

export interface IPostChapterStartAction {
  type: typeof POST_CHAPTER_START;
}

export interface IPostChapterSuccessAction {
  type: typeof POST_CHAPTER_SUCCESS;
  payload: ChapterContentType;
}

export interface IPostChapterErrorAction {
  type: typeof POST_CHAPTER_ERROR;
  payload: string;
}

export interface IPutChapterStartSaga {
  type: typeof PUT_CHAPTER_START_SAGA;
  payload: { content: ChapterContentType; withClose?: boolean };
}

export interface IPutChapterStartAction {
  type: typeof PUT_CHAPTER_START;
}

export interface IPutChapterSuccessAction {
  type: typeof PUT_CHAPTER_SUCCESS;
  payload: ChapterContentType;
}

export interface IPutChapterErrorAction {
  type: typeof PUT_CHAPTER_ERROR;
  payload: string;
}

export interface IDeleteChapterStartSaga {
  type: typeof DELETE_CHAPTER_START_SAGA;
  payload: { diaryId: string; chapterId: string };
}

export interface IDeleteChapterStartAction {
  type: typeof DELETE_CHAPTER_START;
}

export interface IDeleteChapterSuccessAction {
  type: typeof DELETE_CHAPTER_SUCCESS;
  payload: string;
}

export interface IDeleteChapterErrorAction {
  type: typeof DELETE_CHAPTER_ERROR;
  payload: string;
}

export interface ISortChapterStartSaga {
  type: typeof SORT_CHAPTER_START_SAGA;
  payload: { start: number; end: number };
}

export interface ISortChapterStartAction {
  type: typeof SORT_CHAPTER_START;
}

export interface ISortChapterSuccessAction {
  type: typeof SORT_CHAPTER_SUCCESS;
  payload: { start: number; end: number };
}

export interface ISortChapterErrorAction {
  type: typeof SORT_CHAPTER_ERROR;
  payload: string;
}

export interface IPutLayoutStartSaga {
  type: typeof PUT_LAYOUT_START_SAGA;
  payload: {
    pageId: string;
    chapterId: string;
    layout: LayoutType['layout'];
    type: LayoutChangeEnum;
  };
}

export interface IPutLayoutStartAction {
  type: typeof PUT_LAYOUT_START;
}

export interface IPutLayoutSuccessAction {
  type: typeof PUT_LAYOUT_SUCCESS;
  payload: ChapterContentType;
}

export interface IPutLayoutErrorAction {
  type: typeof PUT_LAYOUT_ERROR;
  payload: string;
}

export interface IPostImageStartSaga {
  type: typeof POST_IMAGE_START_SAGA;
  payload: { data: ImageChangeType; image: FormData };
}

export interface IPostImageStartAction {
  type: typeof POST_IMAGE_START;
}

export interface IPostImageSuccessAction {
  type: typeof POST_IMAGE_SUCCESS;
  payload: ChapterContentType;
}

export interface IPostImageErrorAction {
  type: typeof POST_IMAGE_ERROR;
  payload: string;
}

export interface IPutEmptyBoxStartSaga {
  type: typeof PUT_EMPTY_BOX_START_SAGA;
  payload: { data: ImageChangeType; description: string | null };
}

export interface IPutEmptyBoxStartAction {
  type: typeof PUT_EMPTY_BOX_START;
}

export interface IPutEmptyBoxSuccessAction {
  type: typeof PUT_EMPTY_BOX_SUCCESS;
  payload: ChapterContentType;
}

export interface IPutEmptyBoxErrorAction {
  type: typeof PUT_EMPTY_BOX_ERROR;
  payload: string;
}

export interface IGetLayoutsStartSaga {
  type: typeof GET_LAYOUTS_START_SAGA;
}

export interface IGetLayoutsStartAction {
  type: typeof GET_LAYOUTS_START;
}

export interface IGetLayoutsSuccessAction {
  type: typeof GET_LAYOUTS_SUCCESS;
  payload: LayoutType[];
}

export interface IGetLayoutsErrorAction {
  type: typeof GET_LAYOUTS_ERROR;
  payload: string;
}

export interface IPostMapStartSaga {
  type: typeof POST_MAP_START_SAGA;
  payload: {
    data: ImageChangeType;
    map: PointsDataType;
    imageData: string;
    isRoute: boolean;
  };
}

export interface IPostMapStartAction {
  type: typeof POST_MAP_START;
}

export interface IPostMapSuccessAction {
  type: typeof POST_MAP_SUCCESS;
  payload: ChapterContentType;
}

export interface IPostMapErrorAction {
  type: typeof POST_MAP_ERROR;
  payload: string;
}

export type ChapterActionTypes =
  | ISetPageNumber
  | ISetModalChapter
  | IChangeLayout
  | ISetEnoughSheetsLoaded
  | ISetImageChange
  | ISetImageChangeType
  | ISetPreview
  | IInsertChapterSheets
  | IGetChaptersStartSaga
  | IGetChaptersStartAction
  | IGetChaptersSuccessAction
  | IGetChaptersErrorAction
  | IGetMapStartSaga
  | IGetChapterStartSaga
  | IGetChapterStartAction
  | IGetChapterSuccessAction
  | IGetChapterErrorAction
  | IPostChapterStartSaga
  | IPostChapterStartAction
  | IPostChapterSuccessAction
  | IPostChapterErrorAction
  | IPutChapterStartSaga
  | IPutChapterStartAction
  | IPutChapterSuccessAction
  | IPutChapterErrorAction
  | IDeleteChapterStartSaga
  | IDeleteChapterStartAction
  | IDeleteChapterSuccessAction
  | IDeleteChapterErrorAction
  | ISortChapterStartSaga
  | ISortChapterStartAction
  | ISortChapterSuccessAction
  | ISortChapterErrorAction
  | IPutLayoutStartSaga
  | IPutLayoutStartAction
  | IPutLayoutSuccessAction
  | IPutLayoutErrorAction
  | IPostImageStartSaga
  | IPostImageStartAction
  | IPostImageSuccessAction
  | IPostImageErrorAction
  | IPutEmptyBoxStartSaga
  | IPutEmptyBoxStartAction
  | IPutEmptyBoxSuccessAction
  | IPutEmptyBoxErrorAction
  | IUnpublishAllChapters
  | IPublishAllChapters
  | IGetLayoutsStartSaga
  | IGetLayoutsStartAction
  | IGetLayoutsSuccessAction
  | IUpdateChapterSheets
  | IUpdateChapterSuccessAction
  | IGetLayoutsErrorAction
  | IGetChaptersCompleteAction
  | IPostMapStartSaga
  | IPostMapStartAction
  | IPostMapSuccessAction
  | IPostMapErrorAction;
