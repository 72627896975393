import React, { ReactElement, useEffect, useState } from "react";
import styled from "styled-components/macro";
import { Modal } from "antd";
import { Button } from "components/Common/Button";
import { useTranslation } from "react-i18next";

const FooterButtonsWrap = styled.div`
    display: flex;
    flex-direction: row;
    justify-content:flex-end;
    align-items:center;

    div{
        display: flex;
    }

    @media (max-width: 768px) {
        justify-content:center;
    }
`;

export const ConfirmContent = styled.p`
	font-size: 1.4em;
	line-height: 1.4em;
	font-weight: 300;
    margin-bottom: 0;
	color: ${({ theme }) => theme.textColor};
	${props =>
        props.noMargin && {
            marginBottom: "0",
        }}
`;

type Props = {
    content: string,
    headerText: string,
    yesText: string,
    noText: string,
    onConfirm: () => void,
    onCancel: () => void,
    visible: boolean
}

const ConfirmModal: React.FC<Props> = ({ content, yesText, noText, onConfirm, onCancel, headerText, visible }): ReactElement => {
    const [showModal, setShowModal] = useState(false);
    const { t } = useTranslation();
    const onCancelClick = () => {
        onCancel();
        setShowModal(false);
    }

    useEffect(() => {
        setShowModal(visible)
    }, [visible])

    const onConfirmClick = () => {
        onConfirm();
        setShowModal(false);
    }

    return <Modal
        title={t(headerText)}
        visible={showModal}
        style={{ marginTop: '5%' }}
        footer={[
            <FooterButtonsWrap >
                <Button
                    filled
                    size="small"
                    style={{ marginRight: '10px' }}
                    onClick={onConfirmClick}>
                    {t(yesText)}
                </Button>
                <Button
                    outlined
                    size="small"
                    onClick={onCancelClick}
                >
                    {t(noText)}
                </Button>
            </FooterButtonsWrap>
        ]}
    >
        <ConfirmContent>{t(content)}</ConfirmContent>
    </Modal>
}

export default ConfirmModal;