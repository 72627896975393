const theme = {
  'Travel Diaries': {
    primaryColor: '#FAA41F',
    primaryHover: '#f5970a',
    greyColor: '#a8a8a8',
    textColor: '#333333',
    whiteColor: '#ffffff',
    redColor: '#c6353f',
    disabledColor: '#d3d3d3',
    facebook: '#3b5998',
    twitter: '#00acee',
    danger: '#F17372',
    white: '#ffffff',
  },
  'Baby Diaries': {
    primaryColor: '#D5BBA6',
    primaryHover: '#D0B49E',
    greyColor: '#a8a8a8',
    textColor: '#333333',
    whiteColor: '#ffffff',
    redColor: '#c6353f',
    disabledColor: '#d3d3d3',
    facebook: '#3b5998',
    twitter: '#00acee',
    danger: '#F17372',
    white: '#ffffff',
  },
};

export default theme;
