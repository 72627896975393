import React, { useState, useEffect } from 'react';
import { StylesType } from 'lib/types';
import styled from 'styled-components/macro';

// Styled Components

const StyleContainer = styled.div`
  margin: 0 auto;
  width: 440px;
  height: 624px; // 420 / 411 * 583
  padding: 3em;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  -webkit-box-shadow: rgb(227, 227, 227) 0em 0em 1em 10px;
  -moz-box-shadow: rgb(227, 227, 227) 0em 0em 1em 10px;
  box-shadow: rgb(227, 227, 227) 0em 0em 1em 10px;
  -webkit-border-radius: 1.5em;
  -moz-border-radius: 1.5em;
  border-radius: 0.6em;
  overflow: hidden;

  @media (max-width: 992px) and (min-width: 768px) {
    width: 350px;
    height: 496px; // 350 / 411 * 583
  }
`;

const StyleText = styled.p`
  font: ${({ font }) => font};
  ${({ isBold }) =>
    isBold && {
      fontWeight: 'bold',
    }}
  ${({ isItalic }) =>
    isItalic && {
      fontStyle: 'italic',
    }}
    ${({ textAlign }) => textAlign && { textAlign }}
    color: ${({ textColor, theme }) => textColor || theme.textColor};
  width: 100%;
  ${({ isContent }) =>
    isContent ? { margin: '2em 0' } : { marginBottom: '0.5em' }}
`;

const StyleTitleLine = styled.hr`
  height: ${({ height }) => height + 'px'};
  color: ${({ color }) => color};
  margin-bottom: 1em;
  width: 100%;
  border-width: 0;
  background-color: ${({ color }) => color};
`;

const PageNumber = styled.p`
  font: ${({ font }) => font};
  position: absolute;
  bottom: 5px;
`;

type StylePreviewType = {
  style: StylesType;
};

const StylePreview: React.FC<StylePreviewType> = ({ style }) => {
  const tistyle = style!.titleStyle;
  const dstyle = style!.dateAndLocationStyle;
  const txstyle = style!.textStyle;
  const [scale, setScale] = useState(1.2);

  const calcScale = () => {
    setScale(window.innerWidth < 992 && window.innerWidth > 768 ? 1.1 : 1.3);
  };

  useEffect(() => {
    calcScale();
  }, []);

  useEffect(() => {
    window.addEventListener('resize', calcScale);
    return () => {
      window.removeEventListener('resize', calcScale);
    };
  });

  const renderDateLocation = () => {
    if (dstyle.showDate || dstyle.showLocation) {
      const comma = dstyle.showDate && dstyle.showLocation ? ', ' : '';
      return (
        <StyleText
          font={`${dstyle.fontSize * scale}px ${dstyle.font.displayName}`}
          isItalic={dstyle.isItalic}
          textAlign={dstyle.align.toLowerCase()}
          textColor={dstyle.textColor.screenColor.htmlHex}
        >
          {dstyle.showLocation && 'Amsterdam'}
          {comma}
          {dstyle.showDate && '25-12-2020'}
        </StyleText>
      );
    }
    return null;
  };

  const renderPageNumber = () => {
    if (txstyle.showPageNumbers) {
      return (
        <PageNumber
          font={`${txstyle.fontSize * 1.2}px ${txstyle.font.displayName}`}
        >
          2
        </PageNumber>
      );
    }
    return null;
  };

  const renderParagraph = (text: string) => {
    return (
      <StyleText
        font={`${txstyle.fontSize * scale}px ${txstyle.font.displayName}`}
        isContent={true}
        textAlign={txstyle.align.toLowerCase()}
      >
        {text}
      </StyleText>
    );
  };

  return (
    <StyleContainer>
      {tistyle.lineAlign === 'Both' && (
        <StyleTitleLine
          height={tistyle.lineWidth}
          color={tistyle.lineColor.screenColor.htmlHex}
        />
      )}
      <StyleText
        font={`${tistyle.fontSize * scale}px ${tistyle.font.displayName}`}
        isBold={tistyle.isBold}
        textColor={tistyle.textColor.screenColor.htmlHex}
      >
        Chapter 1
      </StyleText>
      {tistyle.lineAlign !== 'Hide' && (
        <StyleTitleLine
          height={tistyle.lineWidth}
          align={tistyle.lineAlign}
          color={tistyle.lineColor.screenColor.htmlHex}
        />
      )}
      {renderDateLocation()}
      {renderParagraph(`Lorem Ipsum is simply dummy text of the printing and typesetting industry.
            Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown
            printer took a galley of type and scrambled it to make a type specimen book.
            It has survived not only five centuries, but also the leap into electronic typesetting,
            remaining essentially unchanged.`)}
      {renderParagraph(`It was popularised in the 1960s with the release of Letraset sheets containing
            Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker
            including versions of Lorem Ipsum.`)}
      {renderParagraph(`Contrary to popular belief, Lorem Ipsum is not simply random text.
            It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old.
            Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia.`)}
      {renderPageNumber()}
    </StyleContainer>
  );
};

export default StylePreview;
