import React, {
  useState,
  useEffect,
  Dispatch,
  SetStateAction,
  ChangeEvent,
} from 'react';
import { H2, HelpParagraph } from 'components/Common/Text';
import { Input } from 'antd';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';

// Styled Components

const StepOneWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0 -2em;

  @media (max-width: 768px) {
    flex-direction: column;
    flex-wrap: wrap;
    margin: 0;
  }

  ${({ isLoaded }) =>
    isLoaded ? { animation: 'fadeIn 0.5s linear' } : { opacity: 0 }}

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
`;

const StepOneWrapItem = styled.div`
  width: 50%;
  padding: 2em;
  text-align: center;

  @media (max-width: 768px) {
    padding: 0;
    width: 100%;
  }

  &:last-of-type {
    padding-top: 3.5em;

    img {
      width: 1.3em;
      height: 1.3em;
      position: relative;
      margin-right: 0.8em;
      top: -2px;
    }

    p {
      margin-bottom: 4.5em;
      font-weight: 400;
    }

    h4 {
      cursor: pointer;
      font-weight: 700;

      &:hover {
        color: ${({ theme }) => theme.primaryColor};
        text-decoration: underline;
      }
    }
  }
`;

const Label = styled.label`
  position: relative;
  left: 1em;
  margin: 1em 0 1em;
  display: block;
  font-size: 1.45em;
  font-weight: 400;

  @media (max-width: 768px) {
    font-size: 1.5em;
    padding-left: 5px;
  }
`;

const FormWrap = styled.div`
  width: 100%;
  margin: 5em 0;
  padding: 0 1em;
  text-align: left;

  @media (max-width: 768px) {
    padding: 0;
  }
`;

const FormWrapControl = styled.div`
  margin-bottom: 2em;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

// Types

type StepOneType = {
  newDiary: { name: string; period: string };
  setNewDiary: Dispatch<SetStateAction<{ name: string; period: string }>>;
};

const StepOne: React.FC<StepOneType> = ({ newDiary, setNewDiary }) => {
  const { t } = useTranslation();
  const [loaded, setLoaded] = useState<boolean>(false);

  useEffect(() => {
    setLoaded(true);
  }, []);

  const inputChangeHandler = (event: ChangeEvent<HTMLInputElement>): void => {
    setNewDiary({
      ...newDiary,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <StepOneWrap isLoaded={loaded}>
      <StepOneWrapItem>
        <H2>{t('addTitleToDiary')}</H2>
        <HelpParagraph>{t('canChangeIt')}</HelpParagraph>
        <FormWrap>
          <FormWrapControl>
            <Label htmlFor='name'>{t('titleMandatory')}</Label>
            <Input
              type='text'
              id='name'
              name='name'
              value={newDiary.name}
              onChange={inputChangeHandler}
            />
          </FormWrapControl>
          <FormWrapControl>
            <Label htmlFor='period'>{t('period')}</Label>
            <Input
              type='text'
              id='period'
              name='period'
              value={newDiary.period}
              onChange={inputChangeHandler}
            />
          </FormWrapControl>
        </FormWrap>
      </StepOneWrapItem>
    </StepOneWrap>
  );
};

export default StepOne;
