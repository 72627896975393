import React, { ReactElement } from "react";
import { PageType, StylesType } from 'lib/types'
import { Text, Line } from 'react-konva';

type HeaderProps = {
    style: StylesType;
    page: PageType;
};

const { REACT_APP_NUMBER_MARGIN: numMargin } = process.env as any

const PageHeader: React.FC<HeaderProps> = ({ style, page }): ReactElement => {

    if (!style.titleStyle || !page.header) return <></>;

    const renderLines = (style: StylesType['titleStyle']) => {
        const createLine = (x: number, y: number, width: number) => {
            return <Line
                strokeWidth={style.lineWidth}
                points={[x, y, width, y]}
                stroke={style.lineColor.screenColor.htmlHex} />
        }
        let L1: any, L2: any;
        const { topLine, bottomLine } = page.header

        switch (style.lineAlign) {
            case 'Both':
                if (topLine) {
                    const { position: p2, size: s2 } = topLine
                    L2 = createLine(p2.x, p2.y, s2.width + p2.x)
                }
            // eslint-disable-next-line no-fallthrough
            case 'Below':
                if (bottomLine) {
                    const { position: p1, size: s1 } = bottomLine
                    L1 = createLine(p1.x, p1.y, s1.width + p1.x)
                }
                break;
        }
        return <>{L1}{L2}</>
    }

    const renderHeader = () => {
        if (page.header.title) {
            const titleStyle = style.titleStyle
            const titleObjs = page.header.title
            const title = titleObjs.map((t, index) => <Text key={index} x={t.position.x} y={t.position.y - titleStyle.fontSize}
                text={t.text}
                fontFamily={titleStyle.font.displayName}
                fontSize={titleStyle.fontSize}
                fontStyle={titleStyle.isBold ? 'bold' : 'normal'}
                fill={titleStyle.textColor.screenColor.htmlHex} />
            )
            return <>{renderLines(titleStyle)}{title}</>
        }
    }

    const renderDateLocation = () => {
        if (page.header.dateAndLocation) {
            const dlStyle = style.dateAndLocationStyle
            const dateAndLocation = page.header.dateAndLocation

            if (!(dlStyle.showDate || dlStyle.showLocation)) return;

            return <Text x={+numMargin} y={dateAndLocation.position.y - dlStyle.fontSize} text={dateAndLocation.text}
                fontFamily={dlStyle.font.displayName}
                width={dateAndLocation.width - 2 * numMargin}
                align={dlStyle.align.toLowerCase()}
                fontSize={dlStyle.fontSize}
                fontStyle={dlStyle.isItalic ? 'italic' : 'normal'}
                fill={dlStyle.textColor.screenColor.htmlHex} />
        }
    }

    return <>
        {renderHeader()}
        {renderDateLocation()}
    </>

}

export default PageHeader;