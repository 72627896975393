import { StylesType } from 'lib/types';
import styled from 'styled-components/macro';

export const FlexWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  & > div {
    margin-bottom: 1em;
  }

  label {
    margin: 0 0.3em 0.6em !important;
  }
`;

export const SizedFlexWrapper = styled.div`
  width: 100%;
  display: flex;
  & > *:first-child {
    flex: 2;
    margin-right: 0.5em;
  }
  & > *:last-child {
    flex: 1;
  }
`;

export const HalfFlexWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  & > * {
    flex: 1;
  }
  & > *:first-child {
    margin-right: 1em;
  }
`;

export const ColorSquare = styled.div`
  display: inline-block;
  margin-right: 0.5em;
  width: 1em;
  height: 1em;
  background-color: white;
  border: none;

  ${({ color }) =>
    color && {
      backgroundColor: color,
    }}
`;

export const colorName = (displayName: string, t: (term: string) => string) => {
  const colorName = displayName.replace('style-color-', '').replace('-', ' ');
  return t(colorName);
};

export const compareObjs = (style1: StylesType, style2: StylesType) => {
  const { titleStyle: t1, dateAndLocationStyle: d1, textStyle: x1 } = style1;
  const { titleStyle: t2, dateAndLocationStyle: d2, textStyle: x2 } = style2;
  for (let i of [
    [t1, t2],
    [d1, d2],
    [x1, x2],
  ]) {
    if (i[0].font.value !== i[1].font.value) return false;
    if (i[0].fontSize !== i[1].fontSize) return false;
  }
  //text specific
  if (x1.showPageNumbers !== x2.showPageNumbers) return false;
  //title specific
  for (let el of ['isBold', 'lineAlign', 'lineWidth'])
    if (t1[el] !== t2[el]) return false;
  for (let el1 of ['textColor', 'lineColor'])
    if (t1[el1].value !== t2[el1].value) return false;
  //dateLocation specific
  for (let field of ['showDate', 'showLocation', 'isItalic', 'align'])
    if (d1[field] !== d2[field]) return false;
  if (d1.textColor.value !== d2.textColor.value) return false;
  return true;
};
