import React from 'react';
import { HelpParagraph } from 'components/Common/Text';
import { Select, Checkbox } from 'antd';
import {
  FlexWrapper,
  HalfFlexWrapper,
  SizedFlexWrapper,
  ColorSquare,
  colorName,
} from './reusable';
import fonts from 'lib/fonts.json';
import colors from 'lib/colors.json';
import { StylesType } from 'lib/types';
const { Option } = Select;

type TextStyleTypes = {
  t: (term: string) => string;
  titleStyle: StylesType['titleStyle'];
  disable: boolean;
  lineColorSame: boolean;
  changeTitle: (style: any) => void;
  handleFont: (val: number, from: string) => void;
  handleColor: (val: number, from: string) => void;
  handleLinesColorChange: (e: any) => void;
};

const sizes = {
  title: [14, 16, 18, 20, 22, 24, 26, 28, 30, 32, 34, 36],
  line: [1, 2, 3],
};

const TitleStyle: React.FC<TextStyleTypes> = ({
  t,
  titleStyle,
  changeTitle,
  handleFont,
  handleColor,
  disable,
  lineColorSame,
  handleLinesColorChange,
}) => {
  const disableLines = disable || titleStyle?.lineAlign === 'Hide';

  return (
    <>
      <HelpParagraph>{t('titleHelpText')}</HelpParagraph>
      <FlexWrapper>
        <SizedFlexWrapper>
          <Select
            defaultValue={0}
            value={titleStyle?.font.value}
            onChange={(val) => handleFont(val, 'title')}
            disabled={disable}
          >
            {fonts &&
              fonts.map((f) => (
                <Option key={f.displayName + 1} value={f.value}>
                  {f.name}
                </Option>
              ))}
          </Select>
          <Select
            defaultValue={30}
            value={titleStyle?.fontSize}
            onChange={(val) => changeTitle({ fontSize: val })}
            disabled={disable}
          >
            {sizes.title.map((i) => (
              <Option key={i + 'fontSize1'} value={i}>{`${i}pt`}</Option>
            ))}
          </Select>
        </SizedFlexWrapper>
        <HalfFlexWrapper>
          <Select
            defaultValue={0}
            value={titleStyle?.textColor.value}
            onChange={(val) => handleColor(val, 'title')}
            disabled={disable}
          >
            {colors &&
              colors.map((c) => (
                <Option value={c.value} key={c.displayName + 1}>
                  <ColorSquare color={c.screenColor.htmlHex} />
                  {colorName(c.displayName, t)}
                </Option>
              ))}
          </Select>
          <Checkbox
            checked={titleStyle?.isBold && titleStyle?.font.hasBoldAndItalic}
            disabled={disable || !titleStyle?.font.hasBoldAndItalic}
            onChange={(e) => changeTitle({ isBold: e.target.checked })}
          >
            {t('makeTitleBold')}
          </Checkbox>
        </HalfFlexWrapper>
        <SizedFlexWrapper>
          <Select
            defaultValue='None'
            value={titleStyle?.lineAlign}
            onChange={(val) => changeTitle({ lineAlign: val })}
            disabled={disable}
          >
            <Option key='Hide' value='Hide'>
              {t('noLines')}
            </Option>
            <Option key='Below' value='Below'>
              {t('lineBelow')}
            </Option>
            <Option key='Both' value='Both'>
              {t('doubleLines')}
            </Option>
          </Select>
          <Select
            defaultValue={1}
            value={titleStyle?.lineWidth}
            onChange={(val) => changeTitle({ lineWidth: val })}
            disabled={disableLines}
          >
            {sizes.line.map((i) => (
              <Option key={i + 'line'} value={i}>{`${i}pt`}</Option>
            ))}
          </Select>
        </SizedFlexWrapper>
        <Checkbox
          checked={lineColorSame}
          disabled={disableLines}
          onChange={(e) => handleLinesColorChange(e)}
        >
          {t('linesSameColor')}
        </Checkbox>
      </FlexWrapper>
    </>
  );
};

export default TitleStyle;
