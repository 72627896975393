import {
  GET_DIARY_START_SAGA,
  UPDATE_DIARY_START_SAGA,
  GET_DIARY_STYLES_START_SAGA,
  CREATE_DIARY_START_SAGA,
  ICreateDiaryStartSaga,
  IUpdateDiaryStartSaga,
} from 'store/actions/diary-actions/diary-action-types';
import { UPDATE_COVER_START_SAGA } from 'store/actions/diary-actions/cover-action-types';

import { DiaryType, CreateDiaryType, UserType } from 'lib/types';

export const getDiary = (diaryId: string) => {
  return { type: GET_DIARY_START_SAGA, payload: diaryId };
};

export const getDiaryStyles = () => {
  return { type: GET_DIARY_STYLES_START_SAGA };
};

export const updateCover = (diaryId: string, image: FormData) => {
  return { type: UPDATE_COVER_START_SAGA, payload: { diaryId, image } };
};

export const createDiary = (
  diaryData: CreateDiaryType,
  imageFile: File,
  user: UserType | null,
  voucherId?: string
): ICreateDiaryStartSaga => {
  const image = new FormData();
  image.set('image', imageFile);
  return {
    type: CREATE_DIARY_START_SAGA,
    payload: { diaryData, image, user, voucherId },
  };
};

export const updateDiary = (
  diary: DiaryType,
  notCover: boolean = false,
  chapterIds: string[] = []
): IUpdateDiaryStartSaga => {
  const { name, isPublic, period, style, id } = diary!;
  return {
    type: UPDATE_DIARY_START_SAGA,
    payload: {
      diary: { name, isPublic, period, style, id } as any,
      notCover,
      chapterIds,
    },
  };
};
