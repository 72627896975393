import React from "react";
import { HelpParagraph, WarningParagraph } from "components/Common/Text";
import { Select, Checkbox } from "antd";
import { FlexWrapper, SizedFlexWrapper } from './reusable'
import fonts from "lib/fonts.json"
import { StylesType } from "lib/types";

const { Option } = Select

type TextStyleTypes = {
    t: (term:string)=>string
    textStyle: StylesType['textStyle'];
    disable: boolean;
    changeText: (style: any) => void;
    handleFont: (val: number, type: string) => void;
};

const sizes = [9, 10, 11]

const TextStyle: React.FC<TextStyleTypes> = ({t, textStyle, changeText, disable, handleFont }) => {
    
    return <>
        <HelpParagraph>{t('textHelpText')}</HelpParagraph>
        <FlexWrapper>
            <SizedFlexWrapper>
                <Select defaultValue={0} value={textStyle?.font.value} onChange={(val) => handleFont(val, 'text')} disabled={disable}>
                    {fonts && fonts.map((f) =>
                        <Option key={f.displayName + 3} value={f.value}>{f.name}</Option>)}
                </Select>
                <Select defaultValue={10} value={textStyle?.fontSize} disabled={disable}
                    onChange={(val) => changeText({ fontSize: val })}>
                    {sizes.map((i) => <Option key={i + 'fontSize3'} value={i}>{`${i}pt`}</Option>)}
                </Select>
            </SizedFlexWrapper>
            {!textStyle?.font.hasBoldAndItalic && <WarningParagraph>{t('noBoldAndItalic')}</WarningParagraph>}
            <Checkbox checked={textStyle?.showPageNumbers} disabled={disable}
                onChange={({ target }) => changeText({ showPageNumbers: target.checked })}>
                {t('showPageNumbers')}
        </Checkbox>
        </FlexWrapper>
    </>
}

export default TextStyle;