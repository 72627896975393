import React, { useEffect, useState } from 'react';
import { message, Modal } from 'antd';
import { Button } from 'components/Common/Button';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import styled from 'styled-components/macro';
import CoverLoader from 'components/Common/CoverLoader';

const FooterButtonsWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  div {
    display: flex;
  }

  @media (max-width: 768px) {
    justify-content: center;
  }
`;
export const ConfirmContent = styled.p`
  font-size: 1.4em;
  line-height: 1.4em;
  font-weight: 300;
  margin-bottom: 0;
  color: ${({ theme }) => theme.textColor};
  ${(props) =>
    props.noMargin && {
      marginBottom: '0',
    }}
`;

// Types
interface DownloadPDFModalProps {
  diaryId: string;
  visible: boolean;
  setVisible: (value: boolean) => void;
}

const DownloadPDFModal: React.FC<DownloadPDFModalProps> = ({
  diaryId,
  visible,
  setVisible,
}): React.ReactElement => {
  const { t } = useTranslation();
  const [confirmed, setConfirmed] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const onCancelClick = () => {
    setVisible(false);
    setConfirmed(false);
  };

  useEffect(() => {
    setVisible(visible);
  }, [setVisible, visible]);

  const onConfirmClick = async () => {
    setIsLoading(true);
    axios
      .get(`v1/me/content/${diaryId}/pdf`)
      .then((_) => {
        setConfirmed(true);
        setIsLoading(false);
      })
      .catch((err) => {
        setVisible(false);
        message.error({
          content: t('pdfDownloadError'),
          duration: 2.5,
          style: {
            marginTop: '60px',
          },
        });
        setIsLoading(false);
      });
  };

  return (
    <Modal
      title={t('downloadPDF')}
      visible={visible}
      style={{ marginTop: '5%' }}
      onCancel={onCancelClick}
      footer={[
        <FooterButtonsWrap>
          {confirmed ? (
            <Button
              filled
              size="small"
              style={{ marginRight: '10px' }}
              onClick={onCancelClick}
            >
              {t('closeBtnTitle')}
            </Button>
          ) : (
            <>
              <Button
                filled
                size="small"
                style={{ marginRight: '10px' }}
                onClick={onConfirmClick}
              >
                {t('btnYes')}
              </Button>
              <Button outlined size="small" onClick={onCancelClick}>
                {t('btnNo')}
              </Button>
            </>
          )}
        </FooterButtonsWrap>,
      ]}
    >
      <ConfirmContent>
        {confirmed ? t('pdfSentText') : t('downloadPDFConfirmText')}
      </ConfirmContent>
      {isLoading && <CoverLoader />}
    </Modal>
  );
};

export default DownloadPDFModal;
