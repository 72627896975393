import React, { useState, useEffect, ReactElement, ChangeEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Modal, Input, Upload, message } from 'antd';
import { mdiLoading } from '@mdi/js';
import CoverLoader from 'components/Common/CoverLoader';
import styled from 'styled-components/macro';
import { Button } from 'components/Common/Button';
import { H4, Paragraph } from 'components/Common/Text';
import { AppState } from 'store/reducers/rootReducer';
import { updateCover, updateDiary } from 'store/actions/diary-actions';
import GetIcon from 'components/Common/Icon';
import UploadIcon from 'components/Icons/UploadIcon';
import { appTheme } from 'hoc/Layout';

const { Dragger } = Upload;

const EditHeading = styled.h4`
  .anticon {
    display: inline-block;
    margin-right: 0.5em;
  }
`;

const Image = styled.img`
  width: 100%;
  height: auto;
  -webkit-box-shadow: rgb(227, 227, 227) 1em 1em 2em 0px;
  -moz-box-shadow: rgb(227, 227, 227) 1em 1em 2em 0px;
  box-shadow: rgb(227, 227, 227) 1em 1em 2em 0px;
  -webkit-border-radius: 1.5em;
  -moz-border-radius: 1.5em;
  border-radius: 1.5em;
`;

const ImageWrap = styled.div`
  position: relative;
`;

const ModalContent = styled.div`
  display: flex;
  flex-flow: row wrap;

  @media (max-width: 768px) {
    flex-flow: column wrap;
  }
`;

const ModalContentInputsWrap = styled.div`
  width: 100%;
  padding: 0 1em;

  @media (max-width: 768px) {
    padding: 0;
  }
`;

const ModalInputGroup = styled.div`
  margin-bottom: 2em;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

const Label = styled.label`
  position: relative;
  left: 1em;
  margin-top: 8px;
  display: block;
  font-size: 16px;
  font-weight: 300;

  @media (max-width: 768px) {
    font-size: 14px;
    position: static;
    padding-left: 5px;
  }
`;

const CoverWrap = styled.div`
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  padding-top: 2em;
`;

const CoverWrapItem = styled.div`
  padding: 1.5em;
  padding-bottom: 0;

  h4 {
    font-size: 1.3em;
    font-weight: 700;
  }

  p {
    font-size: 16px;
  }

  @media (max-width: 768px) {
    padding: 0;
  }

  &:first-of-type {
    width: 35%;

    @media (max-width: 768px) {
      width: 100%;
      margin-bottom: 2em;
    }
  }

  &:last-of-type {
    width: 65%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    @media (max-width: 768px) {
      width: 100%;
    }
  }
`;

const UploadWrap = styled.div`
  width: 100%;
  margin: 1em 0 auto;
  margin-bottom: auto;
`;

const UploadInnerWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    margin: 0 12px 0 0;
  }

  svg {
    margin: 0 12px;
  }

  @media (max-width: 768px) {
    flex-flow: column wrap;
    padding: 1.5em;

    img {
      margin-bottom: 1em;
      width: 1.5em;
      height: 1.5em;
    }
  }
`;

const CtaWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 5em;

  button {
    font-size: 15px;
    margin-right: 10px;
    &:last-of-type {
      margin-right: 0;
    }
  }
`;

type CoverProps = { visible: boolean; setVisible: (newState: boolean) => void };

const msgStyles = { fontSize: '16px' };

const EditCoverModal: React.FC<CoverProps> = ({
  visible,
  setVisible,
}): ReactElement => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { diary, cover, isCoverLoading, updateDiaryLoading, successMessage } =
    useSelector((state: AppState) => state.diary);
  const [inputs, setInputs] = React.useState({ name: '', period: '' });
  const [fileList, setFileList] = useState<any>([]);
  const [imageCover, setImageCover] = useState<any>(null);
  const [closeable, setCloseable] = useState(false);

  useEffect(() => {
    //period can be empty
    if (diary?.name || diary?.period)
      setInputs({ name: diary.name, period: diary.period });
  }, [diary]);

  useEffect(() => {
    if (successMessage) {
      message.success({ content: successMessage, style: msgStyles });
      if (closeable) setVisible(false);
    }
  }, [closeable, setVisible, successMessage]);

  const inputChangeHandler = (event: ChangeEvent<HTMLInputElement>): void => {
    setInputs({
      ...inputs,
      [event.target.name]: event.target.value,
    });
  };

  const submitHandler = (withClose = false) => {
    if (!diary) return;
    const { name, period } = inputs;

    if (name === diary.name && period === diary.period && !imageCover) {
      if (withClose) return setVisible(false);
      return;
    }
    if (name !== diary.name || period !== diary.period) {
      setCloseable(withClose);
      const diaryToSend = { ...diary };
      name.length && name !== diary?.name
        ? (diaryToSend.name = name)
        : (diaryToSend.name = diary.name);
      //here we allow the period to be empty
      period !== diary?.period
        ? (diaryToSend.period = period)
        : (diaryToSend.period = diary.period);

      dispatch(updateDiary(diaryToSend));
      setFileList([]);
    }

    if (!imageCover) return;
    else {
      setCloseable(withClose);
      dispatch(updateCover(diary.id, imageCover));
      setImageCover(null);
      setFileList([]);
    }
  };

  const draggerProps = {
    name: 'file',
    multiple: false,
    accept: 'image/*',
    showUploadList: true,
    beforeUpload() {
      return false;
    },
    onChange(info) {
      setImageCover(null);

      if (!info.fileList.length) {
        setFileList([]);
        return;
      }

      let fileList = [...info.fileList];
      fileList = fileList.slice(-1);

      fileList = fileList.map((file) => {
        if (file.response) file.url = file.response.url;
        return file;
      });

      const formData = new FormData();
      formData.append('image', info.file);

      setImageCover(formData);
      setFileList(fileList);
    },
    onRemove() {
      setImageCover(null);
    },
  };

  const disableBtns = isCoverLoading || updateDiaryLoading;

  return (
    <Modal
      style={{ top: '3%' }}
      title={<EditHeading>{t('editCover')}</EditHeading>}
      visible={visible}
      onCancel={() => setVisible(false)}
      footer={null}
      width={750}
    >
      <ModalContent>
        <ModalContentInputsWrap>
          <ModalInputGroup>
            <Input
              type="text"
              name="name"
              value={inputs.name}
              onChange={inputChangeHandler}
              maxLength={60}
            />
            <Label>{t('showInYourPublicDiary')}</Label>
          </ModalInputGroup>
          <ModalInputGroup>
            <Input
              type="text"
              name="period"
              value={inputs.period}
              onChange={inputChangeHandler}
              maxLength={100}
            />
            <Label>{t('periodInYourPublicDiary')}</Label>
          </ModalInputGroup>
        </ModalContentInputsWrap>
        <CoverWrap>
          <CoverWrapItem>
            <H4>{t('cover')}</H4>
            <ImageWrap>
              <Image src={cover} alt={cover} />
              {isCoverLoading && <CoverLoader />}
            </ImageWrap>
          </CoverWrapItem>
          <CoverWrapItem>
            <H4>{t('changeCover')}</H4>
            <Paragraph>{t('saveToUpload')}</Paragraph>
            <UploadWrap>
              <Dragger {...draggerProps} fileList={fileList}>
                <UploadInnerWrap>
                  <UploadIcon
                    color={appTheme.primaryColor}
                    height={20}
                    width={20}
                  />
                  <Upload />
                  <Paragraph>{t('dragToUpload')}</Paragraph>
                </UploadInnerWrap>
              </Dragger>
            </UploadWrap>
            <CtaWrap>
              <Button size="medium" outlined onClick={() => setVisible(false)}>
                {t('btnNo')}
              </Button>
              <Button
                size="medium"
                filled
                onClick={() => submitHandler(false)}
                disabled={isCoverLoading || updateDiaryLoading}
              >
                {disableBtns && !closeable ? (
                  <GetIcon path={mdiLoading} spin={true} className="extra" />
                ) : (
                  t('save')
                )}
              </Button>
              <Button
                size="medium"
                filled
                onClick={() => submitHandler(true)}
                disabled={isCoverLoading || updateDiaryLoading}
              >
                {disableBtns && closeable ? (
                  <GetIcon path={mdiLoading} spin={true} className="extra" />
                ) : (
                  t('saveAndClose')
                )}
              </Button>
            </CtaWrap>
          </CoverWrapItem>
        </CoverWrap>
      </ModalContent>
    </Modal>
  );
};

export default EditCoverModal;
