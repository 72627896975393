import { Size } from 'lib/types';

export function dataURLtoFile(dataurl: string, filename: string): File {
  const arr: string[] = dataurl.split(',');
  const mime: string | undefined = arr[0].match(/:(.*?);/)?.[1];
  const bstr: string = atob(arr[1]);
  let n: number = bstr.length;
  const u8arr: Uint8Array = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime ?? '' });
}

export const getNumberOfPixelsPerPointPrint = (): number => {
  const DOTS_PER_INCH = 200;
  const POINTS_PER_INCH = 67.54142;
  return Math.ceil(DOTS_PER_INCH / POINTS_PER_INCH);
};

export const isImageLargeEnough = (
  blockDimensions: Size,
  imageDimensions: Size
): boolean => {
  var ppp = getNumberOfPixelsPerPointPrint();
  const mistakeRatio = ppp * 3;
  var minWidth = ppp * blockDimensions.width;
  var minHeight = ppp * blockDimensions.height;
  if (imageDimensions.width + mistakeRatio < minWidth) {
    return false;
  }
  if (imageDimensions.height + mistakeRatio < minHeight) {
    return false;
  }
  return true;
};
export const convertBlobToBase64 = (blob: Blob) => {
  return new Promise<string | ArrayBuffer | null>((resolve) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      resolve(event.target?.result || null);
    };
    reader.readAsDataURL(blob);
  });
};

export const isHeicMimeType = (fileType: string): boolean => {
  return fileType === 'image/heic' || fileType === 'image/heif';
};
