import styled, { CSSObject } from 'styled-components/macro';

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-transition: 0.2s all ease-in-out;
  -moz-transition: 0.2s all ease-in-out;
  transition: 0.2s all ease-in-out;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
  cursor: pointer;
  height: 2.7em;

  .mdi-icon {
    display: inline-block;
    margin-right: 5px;
  }

  ${({ size }): CSSObject => {
    switch (size) {
      case 'xsmall':
        return {
          fontSize: '14px',
          padding: '0.4em 1.2em',
          fontWeight: 700,
          lineHeight: '1.2em',
          borderRadius: '20px',
        };
      case 'small':
        return {
          fontSize: '14px',
          padding: '0.6em 2em',
          fontWeight: 700,
          lineHeight: '1.2em',
        };
      case 'medium':
        return {
          fontSize: '15px',
          padding: '0.6em 2em',
          fontWeight: 700,
          lineHeight: '1.2em',
        };
      case 'large':
        return {
          fontSize: '18px',
          padding: '0.6em 2em',
          fontWeight: 700,
          lineHeight: '1.2em',
        };
      default:
        return {
          fontSize: '15px',
          padding: '0.6em 2em',
          fontWeight: 700,
          lineHeight: '1.2em',
        };
    }
  }};

  ${({ filled, theme }) =>
    filled && {
      background: theme.primaryColor,
      color: theme.whiteColor,
      border: `2px solid ${theme.primaryColor}`,
    }}

  ${({ outlined, theme }) =>
    outlined && {
      background: theme.whiteColor,
      color: theme.primaryColor,
      border: `2px solid ${theme.primaryColor}`,
    }}

	${({ facebook, theme }) =>
    facebook && {
      background: '#3b5998',
      border: `2px solid ${theme.facebook}`,
      color: theme.whiteColor,
    }}

	${({ twitter, theme }) =>
    twitter && {
      background: '#00acee',
      border: `2px solid ${theme.twitter}`,
      color: theme.whiteColor,
    }}	

	&:hover {
    ${({ filled, theme }) =>
      filled && {
        background: theme.primaryHover,
        boxShadow: 'rgba(33, 33, 33, 0.1) 0px 0px 11px',
        border: `2px solid ${theme.primaryHover}`,
      }}

    ${({ outlined, theme }) =>
      outlined && {
        background: theme.primaryWhite,
        boxShadow: 'rgba(33, 33, 33, 0.1) 0px 0px 11px',
        color: theme.primaryHover,
        border: `2px solid ${theme.primaryHover}`,
      }}

		${({ facebook, theme }) =>
      facebook && {
        background: 'rgba(59, 89, 152, 0.9)',
        boxShadow: 'rgba(33, 33, 33, 0.1) 0px 0px 11px',
        border: `2px solid ${theme.facebook}`,
      }}

		${({ twitter, theme }) =>
      twitter && {
        background: 'rgba(0, 172, 238, 0.9)',
        boxShadow: 'rgba(33, 33, 33, 0.1) 0px 0px 11px',
        border: `2px solid ${theme.twitter}`,
      }}	
			
		${({ disabled, theme }) =>
      disabled && {
        background: theme.disabledColor,
        border: `2px solid ${theme.disabledColor}`,
      }}
  }

  ${({ disabled, theme }) =>
    disabled && {
      background: theme.disabledColor,
      border: `2px solid ${theme.disabledColor}`,
      boxShadow: 'rgba(33, 33, 33, 0.1) 0px 0px 11px',
      pointerEvents: 'none',
    }}

  &:focus {
    outline: none;
  }

  @media (max-width: 768px) {
    font-size: 1.4em;
  }

  &:active {
    
    transform: scale(0.9);
  
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    ${({ theme }) => ({
      background: theme.primaryHover,
    })}}
     background: darken(theme.primaryColor, 10%); */ /* Adjust color as needed */
  }

 

  &:focus {
    outline: none;
  }

  @media (max-width: 768px) {
    font-size: 1.4em;
  }
`;

export const Anchor = styled.a`
  display: inline-block;
  height: auto;

  &:hover {
    color: #ffffff;
  }
`;

export const BtnWhite = styled.button`
  display: inline-block;
  height: auto;
  color: ${({ theme }) => theme.textColor};
  font-weight: bolder;
  background-color: transparent;
  border: 1px solid ${({ theme }) => theme.disabledColor};
  padding: 0.3em 0.8em;
  border-radius: 10px;
  margin-right: 0.5em !important;

  @media (max-width: 992px) {
    font-size: 1.2em;
    min-height: 30px;
  }

  ${({ filled, theme }) =>
    filled && {
      background: theme.primaryColor,
      color: theme.whiteColor,
      borderColor: theme.primaryColor,
    }}

  .mdi-icon {
    color: ${({ filled, theme }) =>
      filled ? theme.whiteColor : theme.primaryColor};
    margin-right: 5px;
  }

  &:hover {
    border-width: 1px;
    color: ${({ filled, theme }) =>
      filled ? theme.whiteColor : theme.primaryHover};
  }
  &:disabled {
    color: ${({ theme }) => theme.disabledColor};
  }
`;
