import React, { ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import { Input } from "antd";
import { Button } from "components/Common/Button";
import { ImageChangeType } from "lib/types";

const BoxWrapper = styled.div`
    display:flex;
    flex-direction:column;
    align-items:center;

    input{
        width: 80%;
    }
    button{
        width: 150px;
        margin-top:2em;
    }
`

type Props = {
    imageChange: ImageChangeType;
    onBoxSend: (desc: string) => void
}

const EmptyBoxForm: React.FC<Props> = ({ imageChange, onBoxSend }): ReactElement => {
    const { t } = useTranslation()
    const [description, setDescription] = useState("")
    const [clicked, setClicked] = useState(false)

    useEffect(() => {
        if (imageChange?.block.emptyBoxDescription)
            setDescription(imageChange?.block.emptyBoxDescription)
    }, [imageChange])

    const handleClick = () => {
        setClicked(true)
        onBoxSend(description)
    }

    return <BoxWrapper>
        <Input
            autoFocus={true}
            id="description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            placeholder={t("boxDescription")}
            maxLength={50} />
        <Button style={{ paddingTop: "10px" }} filled onClick={handleClick} disabled={clicked}>
            {t("save")}
        </Button>
    </BoxWrapper>
}

export default EmptyBoxForm