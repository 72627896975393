import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Popconfirm } from 'antd';
import { useSelector } from 'react-redux';
import { AppState } from 'store/reducers/rootReducer';
import { Anchor, Button } from 'components/Common/Button';
import { QuestionCircleOutlined } from '@ant-design/icons';
import userManager from 'util/userManager';
import * as S from './styles';
import { UserDeactivationReason } from 'lib/types';

const premiumPlansPath = {
  en: 'premium-plans',
  nl: 'premium-abonnement',
  es: 'premium-plans',
};

export type PrivacyFormData = {
  firstName: string;
  lastName: string;
  country: string | undefined;
  version: number;
};

const DeactivatedUserModal = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const {
    userAccount: { isUserDeactivated, deactivationReason },
  } = useSelector((state: AppState) => state.user);

  const [showModal] = useState(isUserDeactivated ?? false);

  if (!isUserDeactivated) return null;

  const deleteMyAccount = () => {
    window.location.href = `${process.env.REACT_APP_DASHBOARD_URL}/${language}/account?deleteMe=true`;
  };

  const logout = () => {
    userManager.signoutRedirect({ extraQueryParams: { lang: language } });
  };

  return (
    <>
      {showModal && (
        <div>
          <Modal
            style={{ top: '50%', transform: 'translateY(-50%)' }}
            title={
              <S.ModalHeading style={{ padding: '10px 0 !important' }}>
                {t('becomeMember')}
              </S.ModalHeading>
            }
            closable={false}
            visible={true}
            width={'420px'}
            bodyStyle={{ height: 'auto', width: '100%' }}
            footer={''}
            maskClosable={false}
          >
            <S.ModalContent>
              <S.DescriptionText>
                {t(getDeactivatedMessageKey(deactivationReason))}
              </S.DescriptionText>
              <S.WarningText>{t('deactivatedWarning')}</S.WarningText>
              <S.ButtonGroupWrap>
                <Button
                  filled
                  as={Anchor}
                  size='small'
                  href={`${process.env.REACT_APP_WEBSITE_URL}/${language}/${premiumPlansPath[language]}`}
                >
                  {t('viewMemberships')} {'>'}
                </Button>
                <Popconfirm
                  overlayStyle={{ width: '500px' }}
                  title={t('confirmDeleteAccount')}
                  okText={t('btnYes')}
                  cancelText={t('btnNo')}
                  onConfirm={deleteMyAccount}
                  icon={
                    <QuestionCircleOutlined style={{ color: 'indianred' }} />
                  }
                >
                  <S.Label>{t('deleteAccount')}</S.Label>
                </Popconfirm>
                <Button size='medium' outlined onClick={logout}>
                  {t('logout')}
                </Button>
              </S.ButtonGroupWrap>
            </S.ModalContent>
          </Modal>
        </div>
      )}
    </>
  );
};

const getDeactivatedMessageKey = (
  deactivationReason: UserDeactivationReason
): string => {
  switch (deactivationReason) {
    case UserDeactivationReason.PremiumExpiration:
      return 'premiumExpirationDeactivatedMessage';

    case UserDeactivationReason.None:
    case UserDeactivationReason.FreeUsageLimit:
    default:
      return 'freeUsageDeactivatedMessage';
  }
};

export default DeactivatedUserModal;
