import React, { ReactElement, useState, useRef } from "react";
import * as S from "./styles";
import { Row, Col } from "antd";
import { useTranslation } from "react-i18next";
import { H5 } from "components/Common/Text";
import { Button } from "components/Common/Button";
import RouteMapList from "./RouteMapList";
import { ImageBoxType, PointsDataType } from "lib/types";
import { useSelector, useDispatch } from "react-redux";
import { AppState } from "store/reducers/rootReducer";
import Map from "./Map";
import {
  convertToRouteMap,
  getMapBase64,
  updateFirstAndLastPoint,
} from "./utils";
import { getInitialPointsData } from "../Map/MapComponents";
import { setImageChangeType } from "store/actions/chapter-actions";

type RouteMapProps = {
  onRouteMapSend: (data: PointsDataType, image: string) => void;
};

const RouteMap: React.FC<RouteMapProps> = ({
  onRouteMapSend,
}): ReactElement => {
  const { t } = useTranslation();
  const geocoderRef = useRef<any>(null);
  const _mapRef = useRef<any>(null);
  const { imageChange } = useSelector((state: AppState) => state.chapters);
  const dispatch = useDispatch();

  const [routeMapData, setRouteMapData] = useState<PointsDataType>(
    imageChange?.block.routeMapData
      ? convertToRouteMap(imageChange?.block.routeMapData)
      : getInitialPointsData()
  );

  const handleBackClick = () => {
    dispatch(setImageChangeType(ImageBoxType.MAP_TYPE));
  };

  const updateRouteMapData = (data: PointsDataType) => {
    const newData = updateFirstAndLastPoint(data);
    setRouteMapData({ ...newData });
  };

  const saveRouteMap = () => {
    const image = getMapBase64(_mapRef.current.getMap());
    onRouteMapSend(routeMapData, image);
  };

  return (
    <Row gutter={[24, 24]}>
      <Col span={16} md={15} sm={24}>
        <Map
          isRoute={true}
          dataSource={routeMapData}
          updateData={updateRouteMapData}
          geoCoderRef={geocoderRef}
          mapRef={_mapRef}
        />
      </Col>
      <Col span={16} lg={8} md={9} sm={24}>
        <S.MapSidebar>
          <H5>{t("addNewLocation")}</H5>
          <S.LocationSearch ref={geocoderRef} />
          <S.Description>{t("routeMapUsage")}</S.Description>
          <S.Title>{t("route")}</S.Title>
          <RouteMapList
            dataSource={routeMapData}
            updateData={updateRouteMapData}
          />
          <S.ButtonsWrap>
            <Button
              outlined
              size="medium"
              style={{ margin: "10px", width: "150px" }}
              onClick={handleBackClick}
            >
              {t("back")}
            </Button>
            <Button
              filled
              size="medium"
              style={{ margin: "10px", width: "150px" }}
              onClick={saveRouteMap}
            >
              {t("save")}
            </Button>
          </S.ButtonsWrap>
        </S.MapSidebar>
      </Col>
    </Row>
  );
};

export default RouteMap;
