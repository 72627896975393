import React, { ReactElement, useRef, useState } from "react";
import Map from "./Map";
import * as S from "./styles";
import { Row, Col } from "antd";
import { useTranslation } from "react-i18next";
import { H5 } from "components/Common/Text";
import { Button } from "components/Common/Button";
import { PointsDataType, ImageBoxType } from "lib/types";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "store/reducers/rootReducer";
import { getInitialPointsData } from "../Map/MapComponents";
import { setImageChangeType } from "store/actions/chapter-actions";
import { convertToSimpleMap, getMapBase64 } from "./utils";

type SimpleMapProps = {
  onSimpleMapSend: (data: PointsDataType, image: string) => void;
};

const SimpleMap: React.FC<SimpleMapProps> = ({
  onSimpleMapSend,
}): ReactElement => {
  const { t } = useTranslation();
  const geocoderRef = useRef<any>(null);
  const _mapRef = useRef<any>(null);
  const { imageChange } = useSelector((state: AppState) => state.chapters);
  const dispatch = useDispatch();

  const [simpleMapData, setSimpleMapData] = useState<PointsDataType>(
    imageChange?.block.simpleMapData
      ? convertToSimpleMap(imageChange?.block.simpleMapData)
      : getInitialPointsData()
  );

  const handleBackClick = () =>
    dispatch(setImageChangeType(ImageBoxType.MAP_TYPE));

  const saveSimpleMap = () => {
    const image = getMapBase64(_mapRef.current.getMap());
    onSimpleMapSend(simpleMapData, image);
  };

  const updateSimpleMapData = (data: PointsDataType) =>
    setSimpleMapData({ ...data });

  return (
    <Row gutter={[24, 24]}>
      <Col span={16} md={15} sm={24}>
        <Map
          isRoute={false}
          updateData={updateSimpleMapData}
          dataSource={simpleMapData}
          geoCoderRef={geocoderRef}
          mapRef={_mapRef}
        />
      </Col>
      <Col span={16} lg={8} md={9} sm={24}>
        <S.MapSidebar>
          <H5>{t("navigation")}</H5>
          <S.LocationSearch ref={geocoderRef} />
          <S.Description>{t("simpleMapUsage")}</S.Description>
          <S.Title>{t("createSimpleMap")}</S.Title>
          <p>{t("createSimpleMapDescription")}</p>
          <S.ButtonsWrap>
            <Button
              outlined
              size="medium"
              style={{ margin: "10px", width: "150px" }}
              onClick={handleBackClick}
            >
              {t("back")}
            </Button>
            <Button
              filled
              size="medium"
              style={{ margin: "10px", width: "150px" }}
              onClick={saveSimpleMap}
            >
              {t("save")}
            </Button>
          </S.ButtonsWrap>
        </S.MapSidebar>
      </Col>
    </Row>
  );
};

export default SimpleMap;
