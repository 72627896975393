import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { requestInterceptor, responseInterceptor } from 'util/AxiosHeaders';
import { User } from 'oidc-client';
import { AppState } from 'store/reducers/rootReducer';
import { getDiary, getDiaryStyles } from 'store/actions/diary-actions';
import { H2 } from 'components/Common/Text';
import { isEmpty as _isEmpty } from 'lodash';
import Layout from 'hoc/Layout';
import ChapterEditorView from 'components/Editor/ChapterEditorView';
import EditorPreview from 'components/Editor/EditorPreview';
import ErrorMessage from 'components/Common/ErrorMessage';
import LoadingScreen from 'components/Common/LoadingScreen';
import styled from 'styled-components/macro';
import CoverLoader from 'components/Common/CoverLoader';
import MobileScreenModal from 'components/MobileScreenModal';
import useCheckMobileScreen from 'util/useCheckMobileScreen';
import DeactivatedUserModal from 'components/Editor/Modals/DeactivatedUserModal';

const MainViewComponent = styled.div`
  -webkit-animation: 0.45s fadeIn ease-in-out;
  -moz-animation: 0.45s fadeIn ease-in-out;
  animation: 0.45s fadeIn ease-in-out;

  @media (max-width: 768px) {
    flex-direction: column;
    flex-wrap: wrap;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

type EditorViewTypes = { match: any; user: User };

const EditorView: React.FC<EditorViewTypes> = ({ match, user }) => {
  const dispatch = useDispatch();
  const {
    diary,
    cover,
    diaryStyles,
    isDiaryLoading,
    isDiaryStylesLoading,
    errorMessage,
    errorStylesMessage,
  } = useSelector(({ diary }: AppState) => diary);
  const { chapters, isChaptersLoading, isChapterUpdating } = useSelector(
    ({ chapters }: AppState) => chapters
  );
  const isMobile = useCheckMobileScreen();

  useEffect(() => {
    if (user) {
      requestInterceptor(user);
      responseInterceptor();
      dispatch(getDiary(match.params.diaryId));
      dispatch(getDiaryStyles());
    }
  }, [user, dispatch, match]);

  const renderContent = (
    <Layout nofooter>
      {!isMobile ? (
        <>
          <DeactivatedUserModal />
          <MainViewComponent>
            {match.params.chapterId ? (
              <>
                {isChapterUpdating && <CoverLoader />}
                <ChapterEditorView params={match.params} />
              </>
            ) : (
              <EditorPreview diary={diary} cover={cover} chapters={chapters} />
            )}
          </MainViewComponent>
        </>
      ) : (
        <MobileScreenModal />
      )}
    </Layout>
  );

  const loading =
    isDiaryLoading ||
    isDiaryStylesLoading ||
    isChaptersLoading ||
    _isEmpty(user);

  const hasError =
    !_isEmpty(errorMessage) ||
    !_isEmpty(errorStylesMessage) ||
    !match.params.diaryId;

  const isContentReady =
    !_isEmpty(diary) &&
    !_isEmpty(diaryStyles) &&
    !isDiaryLoading &&
    !isDiaryStylesLoading &&
    !_isEmpty(cover);

  if (loading) return <LoadingScreen />;

  if (hasError)
    return (
      <Layout>
        <ErrorMessage>
          <H2>{errorMessage || errorStylesMessage}</H2>
        </ErrorMessage>
      </Layout>
    );

  if (isContentReady) return renderContent;

  return null;
};

export default EditorView;
