import { DiaryType, StylesType, CreateDiaryType, UserType } from 'lib/types';

export const GET_DIARY_START_SAGA = 'GET_DIARY_START_SAGA';
export const GET_DIARY_START = 'GET_DIARY_START';
export const GET_DIARY_SUCCESS_SAGA = 'GET_DIARY_SUCCESS_SAGA';
export const GET_DIARY_SUCCESS = 'GET_DIARY_SUCCESS';
export const GET_DIARY_ERROR_SAGA = 'GET_DIARY_ERROR_SAGA';
export const GET_DIARY_ERROR = 'GET_DIARY_ERROR';
export const GET_DIARY_STYLES_START_SAGA = 'GET_DIARY_STYLES_START_SAGA';
export const GET_DIARY_STYLES_START = 'GET_DIARY_STYLES_START';
export const GET_DIARY_STYLES_SUCCESS = 'GET_DIARY_STYLES_SUCCESS';
export const GET_DIARY_STYLES_ERROR = 'GET_DIARY_STYLES_ERROR';

export const CREATE_DIARY_START_SAGA = 'CREATE_DIARY_START_SAGA';
export const CREATE_DIARY_START = 'CREATE_DIARY_START';
export const CREATE_DIARY_SUCCESS = 'CREATE_DIARY_SUCCESS';
export const CREATE_DIARY_ERROR = 'CREATE_DIARY_ERROR';

export const UPDATE_DIARY_START_SAGA = 'UPDATE_DIARY_START_SAGA';
export const UPDATE_DIARY_START = 'UPDATE_DIARY_START';
export const UPDATE_DIARY_SUCCESS = 'UPDATE_DIARY_SUCCESS';
export const UPDATE_DIARY_COMPLETE = 'UPDATE_DIARY_COMPLETE';

export const PUBLISH_GLOBAL_DIARY = 'PUBLISH_GLOBAL_DIARY';
export const UNPUBLISH_GLOBAL_DIARY = 'UNPUBLISH_GLOBAL_DIARY';

export const DIARY_RESET = 'DIARY_RESET';
export interface IDiaryPublishedAction {
  type: typeof PUBLISH_GLOBAL_DIARY;
}

export interface IDiaryUnpublishedAction {
  type: typeof UNPUBLISH_GLOBAL_DIARY;
}

export interface IGetDiaryStartSaga {
  type: typeof GET_DIARY_START_SAGA;
  payload: string;
}

export interface IGetDiaryStartAction {
  type: typeof GET_DIARY_START;
}

export interface IGetDiarySuccessSaga {
  type: typeof GET_DIARY_SUCCESS_SAGA;
}

export interface IGetDiarySuccessAction {
  type: typeof GET_DIARY_SUCCESS;
  payload: any;
}

export interface IGetDiaryErrorSaga {
  type: typeof GET_DIARY_ERROR_SAGA;
}

export interface IGetDiaryErrorAction {
  type: typeof GET_DIARY_ERROR;
  payload: string;
}

export interface IUpdateDiaryStartSaga {
  type: typeof UPDATE_DIARY_START_SAGA;
  payload: { diary: DiaryType; notCover: boolean; chapterIds: string[] };
}

export interface IUpdateDiaryStartAction {
  type: typeof UPDATE_DIARY_START;
}

export interface IUpdateDiarySuccessAction {
  type: typeof UPDATE_DIARY_SUCCESS;
  payload: DiaryType;
}

export interface IUpdateDiaryCompleteAction {
  type: typeof UPDATE_DIARY_COMPLETE;
}

export interface ICreateDiaryStartSaga {
  type: typeof CREATE_DIARY_START_SAGA;
  payload: {
    diaryData: CreateDiaryType;
    image: FormData;
    user: UserType | null;
    voucherId?: string;
  };
}

export interface ICreateDiaryStartAction {
  type: typeof CREATE_DIARY_START;
}

export interface ICreateDiarySuccessAction {
  type: typeof CREATE_DIARY_SUCCESS;
  payload: { diary: DiaryType; createdWithVoucher: boolean };
}

export interface ICreateDiaryErrorAction {
  type: typeof CREATE_DIARY_ERROR;
  payload: string;
}

export interface IDiaryResetAction {
  type: typeof DIARY_RESET;
}

export interface IGetDiaryStylesStartSaga {
  type: typeof GET_DIARY_STYLES_START_SAGA;
}

export interface IGetDiaryStylesStartAction {
  type: typeof GET_DIARY_STYLES_START;
}

export interface IGetDiaryStylesSuccessAction {
  type: typeof GET_DIARY_STYLES_SUCCESS;
  payload: StylesType[];
}

export interface IGetDiaryStylesErrorAction {
  type: typeof GET_DIARY_STYLES_ERROR;
  payload: string;
}

export type DiaryActionTypes =
  | IGetDiaryStartSaga
  | IGetDiaryStartAction
  | IGetDiarySuccessSaga
  | IGetDiarySuccessAction
  | IGetDiaryErrorSaga
  | IGetDiaryErrorAction
  | IUpdateDiaryStartSaga
  | IUpdateDiaryStartAction
  | IUpdateDiarySuccessAction
  | IUpdateDiaryCompleteAction
  | ICreateDiaryStartSaga
  | ICreateDiaryStartAction
  | ICreateDiarySuccessAction
  | ICreateDiaryErrorAction
  | IDiaryResetAction
  | IGetDiaryStylesStartSaga
  | IGetDiaryStylesStartAction
  | IGetDiaryStylesSuccessAction
  | IGetDiaryStylesErrorAction
  | IDiaryPublishedAction
  | IDiaryUnpublishedAction;
