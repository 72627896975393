import React, { useMemo, ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { AppState } from 'store/reducers/rootReducer';
import { useDispatch, useSelector } from 'react-redux';
import { addChapter } from 'store/actions/chapter-actions';
import {
  mdiChevronRight,
  mdiShareVariantOutline,
  mdiDelete,
  mdiPlusCircle,
  mdiLinkVariant,
  mdiDotsVertical,
} from '@mdi/js';
import GetIcon from 'components/Common/Icon';
import { Dropdown } from 'antd';
import { ChapterContentType } from 'lib/types';
import { H4, Span } from 'components/Common/Text';
import moment from 'moment';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { DateFormatEnum } from 'lib/types';
import { getDateFormat } from 'util/chapterUtils';

// Styled components

const Chapters = styled.div`
  width: 50%;
  padding: 1.5em;
`;

const ChaptersWrap = styled.div`
  width: 100%;
  max-width: 500px;
  border: 1px solid #ededed;
  -webkit-box-shadow: rgb(227, 227, 227) 1em 1em 2em 0px;
  -moz-box-shadow: rgb(227, 227, 227) 1em 1em 2em 0px;
  box-shadow: rgb(227, 227, 227) 1em 1em 2em 0px;
  -webkit-border-radius: 1.5em;
  -moz-border-radius: 1.5em;
  border-radius: 1.5em;
  overflow: hidden;
`;

const ChaptersHeader = styled.div`
  background: ${({ theme }) => theme.primaryColor};
  padding: 2em 2.5em;
  overflow: hidden;

  h4 {
    margin-bottom: 0;
    font-size: 1.5em;
    font-weight: 600;
    color: #ffffff;
  }
`;

const ChaptersInnerWrap = styled.div`
  ${({ chapters }) =>
    chapters.length > 8
      ? {
          height: '66.5em',
          overflow: 'auto',
        }
      : {
          height: 'auto',
        }}
`;

const ChaptersListWrap = styled.ul`
  list-style-type: none;
  margin-bottom: 0;
`;

const ChaptersListItem = styled.li`
  font-size: 1.8em;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  border-left: 2px solid white;
  padding: 1.4em 1.3em 1.4em 1.3em;

  &:hover {
    border-left: 2px solid ${({ theme }) => theme.primaryColor};

    h4 {
      color: ${({ theme }) => theme.primaryColor};
    }
  }
`;

const ChaptersNoItems = styled.li`
  font-size: 1.8em;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  border-left: 2px solid white;
  padding: 1.4em 1.3em 1.4em 1.3em;
  color: ${({ theme }) => theme.textColor};

  .anticon {
    margin-top: 0.3em;
    cursor: pointer;
    color: ${({ theme }) => theme.primaryColor};
    font-size: 3em;

    &:hover {
      -webkit-transform: scale(1.1);
      -moz-transform: scale(1.1);
      transform: scale(1.1);
    }
  }
`;

const ChapterInfo = styled.div`
  h4 {
    font-size: 1em;
    margin-bottom: 0.5em;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    white-space: nowrap;
    display: inline-block;
    width: 300px;
    -webkit-transition: 0.1s color ease-in-out;
    -moz-transition: 0.1s color ease-in-out;
    transition: 0.1s color ease-in-out;
    cursor: pointer;
  }

  span {
    display: block;
    margin-bottom: 0;
    font-size: 0.85em;
    color: ${({ theme }) => theme.greyColor};
  }
`;

const ChapterCta = styled.div`
  .mdi-icon {
    cursor: pointer;
    -webkit-transition: 0.2s all ease-in-out;
    -moz-transition: 0.2s all ease-in-out;
    transition: 0.2s all ease-in-out;
  }

  [data-icon='arrow'] {
    color: ${({ theme }) => theme.primaryColor};
    font-weight: bold;
    font-size: 1.35em;
    margin-right: 0.5em;
  }

  [data-icon='options'] {
    font-size: 1.35em;
  }

  [data-icon='share'] {
    font-size: 1.1em;
    margin-right: 0.5em;
  }

  [data-icon='delete'] {
    font-size: 1.1em;
  }
`;

const DropdownContent = styled.div`
  background: #ffffff;
  border: 1px solid #ededed;
  padding: 1.2em;
  -webkit-border-radius: 0.3em;
  -moz-border-radius: 0.3em;
  border-radius: 0.3em;
`;

const DropdownIconsWrap = styled.ul`
  list-style-type: none;
  margin-bottom: 0;
  padding: 0;
`;

const DropdownIconsList = styled.li`
  font-size: 1.1em;
  font-weight: 300;
  padding-bottom: 1em;
  cursor: pointer;

  .mdi-icon {
    display: inline-block;
    margin-right: 0.1em;
    -webkit-transition: 0.1s color ease-in-out;
    -moz-transition: 0.1s color ease-in-out;
    transition: 0.1s color ease-in-out;
  }

  &:last-of-type {
    padding-bottom: 0;
  }

  &:hover {
    .mdi-icon {
      color: ${({ theme }) => theme.primaryColor};
      ${({ deleteIcon }) =>
        deleteIcon && {
          color: 'indianred',
        }};
    }
  }
`;

type ChaptersPreviewType = {
  diaryId: string | undefined;
  chapters: ChapterContentType[];
};

const ChaptersPreview: React.FC<ChaptersPreviewType> = ({
  diaryId,
  chapters,
}): ReactElement => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const lang = i18n.language;
  const { userAccount } = useSelector(({ user }: AppState) => user);
  const { sheets } = useSelector(({ chapters }: AppState) => sheets);

  const newChapter = useMemo(() => {
    return {
      diaryId: diaryId,
      date: new Date().toISOString(),
      isPublic: false,
      location: '',
      text: '',
      title: t('newChapter'),
      sortOrder: 1,
    } as any;
  }, [diaryId, t]);

  const renderDropdownIcons = (): JSX.Element => (
    <DropdownIconsWrap>
      <DropdownIconsList>
        <GetIcon path={mdiShareVariantOutline} /> {t('share')}
      </DropdownIconsList>
      <DropdownIconsList deleteIcon>
        <GetIcon path={mdiDelete} /> {t('delete')}
      </DropdownIconsList>
      <DropdownIconsList>
        <GetIcon path={mdiLinkVariant} /> {t('public')}
      </DropdownIconsList>
    </DropdownIconsWrap>
  );

  const renderDropdownContent = (): JSX.Element => (
    <DropdownContent>{renderDropdownIcons()}</DropdownContent>
  );

  const renderChapters = () =>
    chapters.length ? (
      chapters.map((chapter) => (
        <ChaptersListItem key={chapter.id}>
          <ChapterInfo>
            <Link to={`/${lang}/diary/${diaryId}/chapter/${chapter.id}`}>
              <H4>{chapter.title}</H4>
              <Span>
                {moment(chapter.date).format(
                  `${getDateFormat(userAccount.dateFormat as DateFormatEnum)}`
                )}
              </Span>
            </Link>
          </ChapterInfo>
          <ChapterCta>
            <Link to={`/${lang}/diary/${diaryId}/chapter/${chapter.id}`}>
              <GetIcon path={mdiChevronRight} data-icon='arrow' />
            </Link>
            <Dropdown
              overlay={renderDropdownContent()}
              trigger={['click']}
              placement='bottomRight'
            >
              <GetIcon path={mdiDotsVertical} data-icon='options' />
            </Dropdown>
          </ChapterCta>
        </ChaptersListItem>
      ))
    ) : (
      <ChaptersNoItems key='noChapters'>
        {t('noChaptersFound')}
        <GetIcon
          path={mdiPlusCircle}
          onClick={() => dispatch(addChapter(newChapter, true))}
        />
      </ChaptersNoItems>
    );

  return (
    <Chapters>
      <ChaptersWrap>
        <ChaptersHeader>
          <H4>{t('chapters')}</H4>
        </ChaptersHeader>
        <ChaptersInnerWrap chapters={chapters || []}>
          <ChaptersListWrap>{renderChapters()}</ChaptersListWrap>
        </ChaptersInnerWrap>
      </ChaptersWrap>
    </Chapters>
  );
};

export default ChaptersPreview;
