import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import Routes from './routes';
import store from 'store';
import { useTranslation } from 'react-i18next';
import { ConfigProvider } from 'antd';
import { getAndtLocale } from 'util/languages';

const App: React.FC = () => {
  const { i18n } = useTranslation();

  return (
    <Provider store={store}>
      <ConfigProvider locale={getAndtLocale(i18n.language)}>
        <BrowserRouter>
          <Routes />
        </BrowserRouter>
      </ConfigProvider>
    </Provider>
  );
};

export default App;
