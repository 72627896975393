import { combineReducers } from "redux";

import UserReducer from "store/reducers/user-reducer";
import DiaryReducer from "store/reducers/diary-reducers";
import ChapterReducer from "store/reducers/chapter-reducer";
import ShareReducer from "store/reducers/share-diary";

const rootReducer = combineReducers({
	diary: DiaryReducer,
	user: UserReducer,
	chapters: ChapterReducer,
	shareDiary: ShareReducer
});

export type AppState = ReturnType<typeof rootReducer>;

export default rootReducer;
