import React, { ReactElement, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { createPortal } from 'react-dom';
import styled from 'styled-components/macro';
import {
  mdiFileImageOutline,
  mdiMapMarkerRadius,
  mdiFormatTextbox,
  mdiCloseBoxOutline,
} from '@mdi/js';
import GetIcon from 'components/Common/Icon';
import {
  saveEmptyBox,
  setImageChangeType,
} from 'store/actions/chapter-actions';
import { ImageBoxType, ImageChangeType } from 'lib/types';
import { AppState } from 'store/reducers/rootReducer';

const isImage = (imageChange: ImageChangeType | null) =>
  imageChange?.block.imageId;
const isMap = (imageChange: ImageChangeType | null) =>
  isRouteMap(imageChange) || isSimpleMap(imageChange);
const isRouteMap = (imageChange: ImageChangeType | null) =>
  imageChange?.block.routeMapData;
const isSimpleMap = (imageChange: ImageChangeType | null) =>
  imageChange?.block.simpleMapData;

const MenuWrapper = styled.div`
  display: none;
  position: absolute;
  width: 180px;
  background-color: white;
  box-shadow: 0 0 5px grey;
  border-radius: 10px;
  overflow: hidden;
  z-index: 9;

  button {
    display: flex;
    align-items: center;
    cursor: pointer;
    text-align: left;
    font-size: 1.3em;
    font-weight: bold;
    width: 100%;
    background-color: white;
    border: none;
    margin: 0;
    padding: 10px;
    border: 1px solid lightgray;
  }

  button:disabled {
    cursor: not-allowed;
    color: rgba(0, 0, 0, 0.25);
  }

  .mdi-icon {
    color: var(--primaryColor);
    margin-right: 5px;
    width: 1.3em;
  }

  button:hover {
    background-color: #f5f5f5;
    transition: 0.2s all ease-in-out;
  }
`;

const ImageDropdownMenu: React.FC = (): ReactElement => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { imageChange } = useSelector(({ chapters }: AppState) => chapters);
  const root = useMemo(() => document.getElementById('root'), []);
  const menuRef = useRef<any>(null);

  const openType = (type: ImageBoxType) => {
    menuRef.current.style.display = 'none';
    dispatch(setImageChangeType(type));
  };

  const EmptyBoxBtn = () => {
    if (!imageChange?.block) return null;
    const { emptyBoxDescription } = imageChange?.block;
    return (
      <button onClick={() => openType(ImageBoxType.EMPTYBOX)}>
        <GetIcon path={mdiFormatTextbox} />
        {emptyBoxDescription ? t('changeDescription') : t('emptyBox')}
      </button>
    );
  };

  const RemoveDesignBtn = () => {
    if (!imageChange?.block) return null;
    const { emptyBoxDescription, imageId, routeMapData } = imageChange?.block;
    return emptyBoxDescription || imageId || routeMapData ? (
      <button onClick={() => dispatch(saveEmptyBox(imageChange, null))}>
        <GetIcon path={mdiCloseBoxOutline} />
        {t('removeDesign')}
      </button>
    ) : null;
  };

  if (!root) return <></>;

  const isImageAsset = isImage(imageChange);
  const isMapAsset = isMap(imageChange);

  return createPortal(
    <MenuWrapper
      id="menu"
      onMouseLeave={(e: any) => (e.currentTarget.style.display = 'none')}
      ref={menuRef}
    >
      <div>
        <button
          onClick={() => {
            if (isImageAsset && !isMapAsset) {
              openType(ImageBoxType.EDIT_IMAGE);
            } else openType(ImageBoxType.IMAGE);
          }}
        >
          <GetIcon path={mdiFileImageOutline} />
          {isImage(imageChange) ? t('changeImage') : t('uploadImage')}
        </button>
        <EmptyBoxBtn />
        <button onClick={() => openType(ImageBoxType.MAP_TYPE)}>
          <GetIcon path={mdiMapMarkerRadius} />
          {isMap(imageChange) ? t('changeMap') : t('createMap')}
        </button>
        <RemoveDesignBtn />
      </div>
    </MenuWrapper>,
    root
  );
};

export default ImageDropdownMenu;
