import React from 'react';
import styled, {
  ThemeProvider,
  createGlobalStyle,
} from 'styled-components/macro';
import Header from 'components/Header';
import Footer from 'components/Footer';
import theme from 'lib/theme';

export const appName = process.env.REACT_APP_NAME || 'Travel Diaries';

export const appTheme = theme[appName.toString()];

const GlobalStyles = createGlobalStyle`
    :root {
        --primaryColor: ${({ theme }) => theme.primaryColor};
        --textColor: ${({ theme }) => theme.textColor};
        --primaryHover: ${({ theme }) => theme.primaryHover};
        --disabledColor: ${({ theme }) => theme.disabledColor};
    }
 * {
        padding: 0;
        margin: 0;
        min-height: 0;
        min-width: 0;
        box-sizing: border-box;
    }

    html {
        scroll-behavior: smooth;
        margin: 0;
        padding: 0;
        scrollbar-color: ${({ theme }) => theme.primaryColor};
    }

    body {
        font-family: "Nunito", sans-serif;
        scroll-behavior: smooth;
        overflow-x: hidden;
        box-sizing: border-box;
        font-size: 10px;
        line-height: 1;
        margin: 0;
        padding: 0;
    }

    ::-webkit-scrollbar {
        width: 10px;
    }

    ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 10px #e0e0e0;
    }

    ::-webkit-scrollbar-thumb {
        background: ${({ theme }) => theme.primaryColor};
    }

    ::-webkit-scrollbar-thumb:hover {
        background: ${({ theme }) => theme.disabledColor};
    }

`;

const LayoutWrapper = styled.div`
  padding: 125px 0 0;
  min-height: 93vh;

  @media (max-width: 992px) {
    padding: 125px 0 8.5em;
  }
`;

const Layout = ({ children, nofooter = false, noheader = false }) => (
  <>
    <ThemeProvider theme={appTheme}>
      <GlobalStyles />
      {!noheader && <Header />}
      <LayoutWrapper>{children}</LayoutWrapper>
      {!nofooter && <Footer />}
    </ThemeProvider>
  </>
);

export default Layout;
