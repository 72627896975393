import React, { useState, useEffect, Dispatch, SetStateAction } from 'react';
import { Select } from 'antd';
import { H2, HelpParagraph } from 'components/Common/Text';
import StylePreview from 'components/StylePreview';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { StylesType } from 'lib/types';

// Styled Components

const StepThreeWrap = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0 -2em 5em;

  @media (max-width: 768px) {
    flex-direction: column;
    flex-wrap: wrap;
    margin: 2em 0 5em;
  }

  ${({ isLoaded }) =>
    isLoaded ? { animation: 'fadeIn 0.5s linear' } : { opacity: 0 }}

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
`;

const StepThreeWrapItem = styled.div`
  width: 50%;
  padding: 2em;

  @media (max-width: 768px) {
    padding: 0;
    width: 100%;
  }

  &:first-of-type {
    padding: 1.5em;
  }

  &:last-of-type {
    padding-top: 3.5em;

    p {
      margin-bottom: 2em;
      font-weight: 300;
    }
  }
`;

const StyleWrap = styled.div`
  position: relative;
`;

// Types

type StepThreeType = {
  newStyle: string | undefined;
  setNewStyle: Dispatch<SetStateAction<string | undefined>>;
  diaryStyles: StylesType[];
};

const StepThree: React.FC<StepThreeType> = ({
  newStyle,
  setNewStyle,
  diaryStyles,
}) => {
  const { t } = useTranslation();

  const [loaded, setLoaded] = useState<boolean>(false);
  const [chosenStyle, setChosenStyle] = useState<StylesType>();

  useEffect(() => {
    setLoaded(true);
  }, []);

  useEffect(() => {
    if (newStyle && diaryStyles)
      setChosenStyle(diaryStyles.find((d) => d?.displayName === newStyle));
  }, [newStyle, diaryStyles]);

  useEffect(() => {
    if (diaryStyles.length && !newStyle)
      setNewStyle(diaryStyles[0]?.displayName);
  }, [diaryStyles, newStyle, setNewStyle]);

  return (
    <>
      <StepThreeWrap isLoaded={loaded}>
        {chosenStyle && (
          <StepThreeWrapItem>
            <StyleWrap>
              <StylePreview style={chosenStyle} />
            </StyleWrap>
          </StepThreeWrapItem>
        )}
        <StepThreeWrapItem>
          <H2>{t('styleOfYourDiary')}</H2>
          <HelpParagraph>{t('styleText')}</HelpParagraph>
          {diaryStyles && (
            <Select
              value={newStyle}
              onChange={(val) => setNewStyle(val)}
              style={{ width: '100%' }}
            >
              {diaryStyles.length &&
                diaryStyles.map((s) => (
                  <Select.Option key={s!.displayName} value={s!.displayName}>
                    {s!.displayName}
                  </Select.Option>
                ))}
            </Select>
          )}
        </StepThreeWrapItem>
      </StepThreeWrap>
    </>
  );
};

export default StepThree;
