import styled from "styled-components";
import { H5 } from "components/Common/Text";
import { List } from "antd";

export const MapContainer = styled.div`
  margin: 0 auto;
  max-width: max(min(${({ size }) => size.width}px, 500px), 550px);
`;

export const MapOuterWrap = styled.div`
  width: 100%;
  height: auto;
  position: relative;
  padding-top: ${({ size }) => (size.height / size.width) * 100}%;
`;

export const MapInnerWrap = styled.div`
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

export const LocationSearch = styled.div`
  z-index: 100;
  .mapboxgl-ctrl-geocoder {
    min-width: 100%;
  }
`;

export const Image = styled.img`
  max-width: 100%;
  margin-bottom: 1rem;
  border: 2px solid white;

  &:hover {
    cursor: pointer;
    border: 2px solid #f5970a;
    box-shadow: #f5970a 0px 0px 5px;
  }
`;

export const Title = styled(H5)`
  margin: 1rem 0;
`;

export const Description = styled.p`
  margin: 1rem 0;
`;

export const Label = styled.label`
  display: table;
  margin: 0 0.5em;
  font-size: 1.45em;
  font-weight: 400;

  small {
    color: indianred;
  }
`;

export const MapSidebar = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const ButtonsWrap = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column-reverse;

    a {
      margin-top: 1em;
    }
  }
`;

export const CustomList = styled(List)`
  box-shadow: 0 0 5px 2px rgb(0 0 0 / 10%);
  overflow-y: auto;
  max-height: 180px;
  .ant-list-item {
    padding: 8px;
  }
`;

export const ListItem = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
  border-left: 2px solid white;
  width: 100%;

  &:hover {
    h4 {
      color: ${({ theme }) => theme.primaryColor};
    }
  }
`;

export const ListItemText = styled.h4`
  font-size: 1em;
  margin-bottom: 0;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: flex;
  transition: 0.1s color ease-in-out;
  cursor: auto;
  min-width: 0;
  flex: 1 1 auto;
  flex-basis: 80%;
`;

export const ListItemCTA = styled.div`
  color: ${({ theme }) => theme.primaryColor};
  display: flex;
  justify-content: center;
  margin-left: auto;
  max-height: 24px;
  .mdi-icon {
    cursor: pointer;
    transition: 0.2s all ease-in-out;
  }
`;

export const ListItemDot = styled.div`
  position: relative;
  border: 2px solid #999;
  border-radius: 100%;
  width: 12px;
  height: 12px;
  line-height: 12px;
  text-align: center;
  background-color: #999;
  z-index: 5;
  margin-right: 8px;
  margin: auto 8px auto 0;

  &:before {
    position: absolute;
    border: 1px solid #999;
    width: 0;
    height: 48px;
    display: block;
    content: "";
    left: 50%;
    z-index: 1;
    top: 0;
    margin-left: -1px;
    display: ${({ isLast }) => (isLast ? "none" : "block")};
  }
`;

export const ActionsWrap = styled.div`
  display: flex;

  .mdi-icon {
    width: 24px;
    height: 24px;
    color: ${({ theme }) => theme.primaryColor};
    cursor: pointer;

    &:hover {
      color: ${({ theme }) => theme.primaryHover};
    }
  }
`;

export const MarkerButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  z-index: 1000;
  position: relative;
`;

export const MarkerImage = styled.img`
  width: 20px;
  height: 30px;
`;
