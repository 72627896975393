import {
  SET_IMAGE_CHANGE,
  SET_MODAL_CHAPTER,
  SET_PAGE_NUMBER,
  SET_PREVIEW,
  GET_CHAPTERS_START,
  GET_CHAPTERS_SUCCESS,
  GET_CHAPTERS_ERROR,
  GET_SHEETS_START,
  GET_SHEETS_SUCCESS,
  GET_SHEETS_INSERT,
  GET_SHEETS_UPDATE,
  GET_SHEETS_LOADED,
  GET_SHEETS_ERROR,
  ChapterActionTypes,
  PUT_CHAPTER_START,
  PUT_CHAPTER_SUCCESS,
  PUT_CHAPTER_ERROR,
  DELETE_CHAPTER_START,
  DELETE_CHAPTER_SUCCESS,
  DELETE_CHAPTER_ERROR,
  SORT_CHAPTER_START,
  SORT_CHAPTER_SUCCESS,
  SORT_CHAPTER_ERROR,
  POST_CHAPTER_START,
  POST_CHAPTER_SUCCESS,
  POST_CHAPTER_ERROR,
  PUT_LAYOUT_START,
  PUT_LAYOUT_SUCCESS,
  PUT_LAYOUT_ERROR,
  POST_IMAGE_START,
  POST_IMAGE_SUCCESS,
  POST_IMAGE_ERROR,
  PUBLISH_ALL_CHAPTERS,
  UNPUBLISH_ALL_CHAPTERS,
  GET_LAYOUTS_START,
  GET_LAYOUTS_SUCCESS,
  GET_LAYOUTS_ERROR,
  CHANGE_LAYOUT,
  SET_IMAGE_CHANGE_TYPE,
  PUT_EMPTY_BOX_START,
  PUT_EMPTY_BOX_SUCCESS,
  PUT_EMPTY_BOX_ERROR,
  UPDATE_SHEETS_SUCCESS,
  GET_CHAPTERS_COMPLETE,
  POST_MAP_START,
  POST_MAP_SUCCESS,
  POST_MAP_ERROR,
} from 'store/actions/chapter-actions/chapter-action-types';
import {
  ChapterPageType,
  ChapterContentType,
  LayoutType,
  ImageChangeType,
  LayoutChangeType,
} from 'lib/types';
import {
  calcPageNumber,
  isChapterFound,
  updateChapter,
  orderChapters,
  deleteChapter,
  createSheets,
  recreateSheets,
  fixPageBeforeNumbers,
} from 'util/chapterUtils';

interface IInitialState {
  chapters: ChapterContentType[];
  sheets: ChapterPageType[];
  layouts: LayoutType[];
  layoutChange: LayoutChangeType | undefined;
  imageChange: ImageChangeType | null;
  pageNumber: number;
  modalChapter: string | null;
  previewMode: boolean;
  isLayoutsLoading: false;
  isChaptersLoading: boolean;
  isSheetsLoading: boolean;
  isChapterUpdating: boolean;
  errorChapters: string;
  errorSheets: string;
  errorUpdate: string;
}

const initialState: IInitialState = {
  chapters: [],
  sheets: [],
  layouts: [],
  layoutChange: undefined,
  imageChange: null,
  pageNumber: -1,
  modalChapter: null,
  previewMode: false,
  isLayoutsLoading: false,
  isChaptersLoading: false,
  isSheetsLoading: false,
  isChapterUpdating: false,
  errorChapters: '',
  errorSheets: '',
  errorUpdate: '',
};

type actionTypes = ChapterActionTypes;

const ChapterReducer = (
  state: IInitialState = initialState,
  action: actionTypes
): IInitialState => {
  switch (action.type) {
    case SET_PAGE_NUMBER:
      return {
        ...state,
        pageNumber: action.payload,
        layoutChange: undefined,
      };
    case SET_MODAL_CHAPTER:
      return {
        ...state,
        modalChapter: action.payload,
      };
    case CHANGE_LAYOUT:
      return {
        ...state,
        layoutChange: action.payload,
      };
    case SET_IMAGE_CHANGE:
      return {
        ...state,
        imageChange: action.payload,
      };
    case SET_IMAGE_CHANGE_TYPE:
      return {
        ...state,
        imageChange: { ...(state.imageChange as any), type: action.payload },
      };
    case SET_PREVIEW:
      return {
        ...state,
        previewMode: !state.previewMode,
        layoutChange: undefined,
      };
    case PUBLISH_ALL_CHAPTERS:
      const allPublicChapters = state.chapters.map((c) => ({
        ...c,
        isPublic: true,
      }));
      return {
        ...state,
        chapters: allPublicChapters,
        sheets: recreateSheets(allPublicChapters),
      };
    case UNPUBLISH_ALL_CHAPTERS:
      const allPrivateChapters = state.chapters.map((c) => ({
        ...c,
        isPublic: false,
      }));
      return {
        ...state,
        chapters: allPrivateChapters,
        sheets: recreateSheets(allPrivateChapters),
      };
    case GET_CHAPTERS_START:
      return {
        ...state,
        isChaptersLoading: true,
        errorChapters: '',
      };
    case GET_CHAPTERS_SUCCESS:
      return {
        ...state,
        chapters: updateChapter([...state.chapters], action.payload as any),
      };
    case GET_CHAPTERS_COMPLETE:
      return {
        ...state,
        isChaptersLoading: false,
      };
    case GET_CHAPTERS_ERROR:
      return {
        ...state,
        isChaptersLoading: false,
        chapters: [],
        errorChapters: action.payload,
      };
    case GET_SHEETS_START:
      return {
        ...state,
        isSheetsLoading: !action.payload,
        errorSheets: '',
        sheets: action.payload ? [] : state.sheets,
      };
    case GET_SHEETS_INSERT:
      const updatedChapters = updateChapter(
        [...state.chapters],
        [action.payload]
      );
      return {
        ...state,
        chapters: updatedChapters,
        sheets: [...state.sheets, ...createSheets(action.payload)],
      };
    case GET_SHEETS_UPDATE:
      return {
        ...state,
        sheets: [...state.sheets, ...createSheets(action.payload)],
      };
    case GET_SHEETS_LOADED:
      return {
        ...state,
        isSheetsLoading: false,
      };
    case GET_SHEETS_SUCCESS:
      const pgNum = isChapterFound(state.pageNumber);
      return {
        ...state,
        isSheetsLoading: false,
        pageNumber: pgNum,
      };
    case UPDATE_SHEETS_SUCCESS:
      return {
        ...state,
        isSheetsLoading: false,
      };
    case GET_SHEETS_ERROR:
      return {
        ...state,
        isSheetsLoading: false,
        errorSheets: action.payload,
      };
    case SORT_CHAPTER_START:
      return {
        ...state,
        isChapterUpdating: true,
      };
    case SORT_CHAPTER_SUCCESS:
      const reordered = orderChapters(
        state.chapters,
        action.payload.start,
        action.payload.end
      );
      return {
        ...state,
        isChapterUpdating: false,
        chapters: reordered,
        sheets: recreateSheets(reordered),
      };
    case SORT_CHAPTER_ERROR:
      return {
        ...state,
        isChapterUpdating: false,
        errorUpdate: action.payload,
      };
    case POST_CHAPTER_START:
      return {
        ...state,
        isChapterUpdating: true,
      };
    case POST_CHAPTER_SUCCESS:
      const addToChapters = fixPageBeforeNumbers([
        ...state.chapters,
        action.payload,
      ]);
      const allSheets = recreateSheets(addToChapters);
      return {
        ...state,
        chapters: addToChapters,
        sheets: allSheets,
        isChapterUpdating: false,
        modalChapter: action.payload.id,
        pageNumber: calcPageNumber(allSheets.length - 1),
      };
    case POST_CHAPTER_ERROR:
      return {
        ...state,
        isChapterUpdating: false,
        errorUpdate: action.payload,
      };
    case PUT_CHAPTER_START:
      return {
        ...state,
        isChapterUpdating: true,
      };
    case PUT_CHAPTER_SUCCESS:
      const updateChapters = updateChapter(
        [...state.chapters],
        [action.payload]
      );
      return {
        ...state,
        isChapterUpdating: false,
        chapters: updateChapters,
        sheets: recreateSheets(updateChapters),
      };
    case PUT_CHAPTER_ERROR:
      return {
        ...state,
        isChapterUpdating: false,
        errorUpdate: action.payload,
      };
    case DELETE_CHAPTER_START:
      return {
        ...state,
        isChapterUpdating: true,
      };
    case DELETE_CHAPTER_SUCCESS:
      const newChapters = deleteChapter([...state.chapters], action.payload);
      const newSheets = recreateSheets(newChapters);
      const newPgNum =
        newSheets.length - 1 < state.pageNumber
          ? newSheets.length - 1
          : state.pageNumber;
      return {
        ...state,
        isChapterUpdating: false,
        modalChapter: null,
        chapters: newChapters,
        sheets: newSheets,
        pageNumber: calcPageNumber(newPgNum),
      };
    case DELETE_CHAPTER_ERROR:
      return {
        ...state,
        isChapterUpdating: false,
        errorUpdate: action.payload,
      };
    case GET_LAYOUTS_START:
      return {
        ...state,
        isLayoutsLoading: false,
      };
    case GET_LAYOUTS_SUCCESS:
      return {
        ...state,
        layouts: action.payload,
        isLayoutsLoading: false,
      };
    case GET_LAYOUTS_ERROR:
      return {
        ...state,
        errorChapters: action.payload,
        isLayoutsLoading: false,
      };
    case PUT_LAYOUT_START:
      return {
        ...state,
        isChapterUpdating: true,
        layoutChange: undefined,
      };
    case PUT_LAYOUT_SUCCESS:
      return {
        ...state,
        isChapterUpdating: false,
      };
    case PUT_LAYOUT_ERROR:
      return {
        ...state,
        errorUpdate: action.payload,
        isChapterUpdating: false,
      };
    case POST_IMAGE_START:
      return {
        ...state,
        isChapterUpdating: true,
        imageChange: null,
      };
    case POST_IMAGE_SUCCESS:
      return {
        ...state,
        isChapterUpdating: false,
      };
    case POST_IMAGE_ERROR:
      return {
        ...state,
        errorUpdate: action.payload,
      };
    case PUT_EMPTY_BOX_START:
      return {
        ...state,
        isChapterUpdating: true,
      };
    case PUT_EMPTY_BOX_SUCCESS:
      return {
        ...state,
        isChapterUpdating: false,
        imageChange: null,
      };
    case PUT_EMPTY_BOX_ERROR:
      return {
        ...state,
        errorUpdate: action.payload,
        imageChange: null,
      };
    case POST_MAP_START:
      return {
        ...state,
        isChapterUpdating: true,
        imageChange: null,
      };
    case POST_MAP_SUCCESS:
      return {
        ...state,
        isChapterUpdating: false,
      };
    case POST_MAP_ERROR:
      return {
        ...state,
        errorUpdate: action.payload,
        isChapterUpdating: false,
      };
    default:
      return state;
  }
};

export default ChapterReducer;
