import { put, takeLatest, all, call, select } from 'redux-saga/effects';
import {
  GET_SHEETS_UPDATE,
  UPDATE_SHEETS_SUCCESS,
  GET_SHEETS_START,
  GET_SHEETS_START_SAGA,
  GET_SHEETS_INSERT,
  GET_SHEETS_ERROR,
  GET_CHAPTERS_START,
  GET_CHAPTERS_START_SAGA,
  GET_CHAPTERS_SUCCESS,
  GET_CHAPTERS_ERROR,
  IGetChapterStartSaga,
  IGetChaptersStartSaga,
  SET_MODAL_CHAPTER,
  PUT_CHAPTER_START_SAGA,
  PUT_CHAPTER_START,
  PUT_CHAPTER_SUCCESS,
  PUT_CHAPTER_ERROR,
  IPutChapterStartSaga,
  DELETE_CHAPTER_START_SAGA,
  DELETE_CHAPTER_START,
  DELETE_CHAPTER_SUCCESS,
  DELETE_CHAPTER_ERROR,
  IDeleteChapterStartSaga,
  SORT_CHAPTER_START_SAGA,
  ISortChapterStartSaga,
  SORT_CHAPTER_START,
  SORT_CHAPTER_SUCCESS,
  SORT_CHAPTER_ERROR,
  POST_CHAPTER_START_SAGA,
  IPostChapterStartSaga,
  POST_CHAPTER_START,
  POST_CHAPTER_SUCCESS,
  POST_CHAPTER_ERROR,
  GET_SHEETS_SUCCESS,
  PUT_LAYOUT_START_SAGA,
  IPutLayoutStartSaga,
  PUT_LAYOUT_START,
  PUT_LAYOUT_ERROR,
  PUT_LAYOUT_SUCCESS,
  POST_IMAGE_START_SAGA,
  IPostImageStartSaga,
  POST_IMAGE_START,
  POST_IMAGE_SUCCESS,
  POST_IMAGE_ERROR,
  GET_LAYOUTS_START_SAGA,
  GET_LAYOUTS_START,
  GET_LAYOUTS_SUCCESS,
  GET_LAYOUTS_ERROR,
  PUT_EMPTY_BOX_START_SAGA,
  IPutEmptyBoxStartSaga,
  PUT_EMPTY_BOX_START,
  PUT_EMPTY_BOX_SUCCESS,
  GET_CHAPTERS_COMPLETE,
  IPostMapStartSaga,
  POST_MAP_START,
  POST_MAP_SUCCESS,
  POST_MAP_ERROR,
  POST_MAP_START_SAGA,
} from 'store/actions/chapter-actions/chapter-action-types';

import axios from 'axios';
import { AppState } from 'store/reducers/rootReducer';
import {
  changeImageData,
  changeLayoutData,
  changeRouteMapData,
  changeSimpleMapData,
} from './chapterSagaUtils';
import {
  revertRouteMap,
  revertSimpleMap,
} from 'components/Editor/Chapter/Parts/PageImage/ImageModal/Map/utils';
import i18n from 'i18n';

const getChapterByIndex = (state: AppState, index: number) =>
  state.chapters.chapters[index];
const getChapterById = (state: AppState, id: string) =>
  state.chapters.chapters.find((c) => c.id === id);
const getSheetsByChapter = (state: AppState, id: string) =>
  state.chapters.sheets.filter((s) => s.id === id);

// Sagas
export function* getChaptersSaga(action: IGetChaptersStartSaga) {
  const PAGE_SIZE = 20;
  let index = 1;
  let shouldFetch = true;
  let total = 0;

  try {
    yield put({ type: GET_CHAPTERS_START });
    while (shouldFetch) {
      const {
        data: { data, meta },
      } = yield call(
        axios.get,
        `v1/me/diaries/${action.payload}/chapters?page=${index}&size=${PAGE_SIZE}`
      );

      yield put({ type: GET_CHAPTERS_SUCCESS, payload: data || [] });
      total = PAGE_SIZE * index;
      if (total >= meta.totalCount) {
        shouldFetch = false;
      }
      index++;
    }

    yield put({ type: GET_CHAPTERS_COMPLETE });
  } catch (err) {
    // TODO Handle Error Handling
    console.error('ERROR CHAPTERS >>', err);
    yield put({
      type: GET_CHAPTERS_ERROR,
      payload: i18n.t('chaptersWereNotFound'),
    });
  }
}

export function* getSheetsSaga(action: IGetChapterStartSaga) {
  const { diaryId, chapterIds, isUpdate = false } = action.payload;

  try {
    yield put({ type: GET_SHEETS_START, payload: isUpdate });

    if (isUpdate) {
      for (let i = 0; i < chapterIds.length; i++) {
        const {
          data: { data },
        } = yield call(
          axios.get,
          `v1/me/diaries/${diaryId}/chapters/${chapterIds[i]}`
        );
        yield put({ type: GET_SHEETS_UPDATE, payload: data });
      }
      yield put({ type: UPDATE_SHEETS_SUCCESS });
    } else {
      for (let i = 0; i < chapterIds.length; i++) {
        const {
          data: { data },
        } = yield call(
          axios.get,
          `v1/me/diaries/${diaryId}/chapters/${chapterIds[i]}`
        );
        yield put({ type: GET_SHEETS_INSERT, payload: data });
      }
      yield put({ type: GET_SHEETS_SUCCESS });
    }
  } catch (err) {
    console.error('ERROR CHAPTER >>', err);
    yield put({
      type: GET_SHEETS_ERROR,
      payload: i18n.t('chapterWasNotFound'),
    });
  }
}

export function* sortChapterSaga(action: ISortChapterStartSaga) {
  const { start, end } = action.payload;

  try {
    yield put({ type: SORT_CHAPTER_START });
    let found = yield select(getChapterByIndex, start);
    if (!found)
      return yield put({
        type: SORT_CHAPTER_ERROR,
        payload: i18n.t('chapterWasNotFound'),
      });
    yield call(
      axios.put,
      `/v1/me/diaries/${found.diaryId}/chapters/${found.id}/move`,
      {
        desiredSortOrder: end + 1,
      }
    );
    yield put({ type: SORT_CHAPTER_SUCCESS, payload: action.payload });
  } catch (err) {
    console.error('ERROR SORTING CHAPTER >>', err);
    yield put({
      type: SORT_CHAPTER_ERROR,
      payload: i18n.t('chapterWasNotUpdated'),
    });
  }
}

export function* addChapterSaga(action: IPostChapterStartSaga) {
  const {
    withClose,
    content: { diaryId, ...rest },
  } = action.payload;

  try {
    yield put({ type: POST_CHAPTER_START });
    const {
      data: { data },
    } = yield call(axios.post, `v1/me/diaries/${diaryId}/chapters`, rest);
    yield put({ type: POST_CHAPTER_SUCCESS, payload: data });
    data.isLoaded = true;
    if (withClose) yield put({ type: SET_MODAL_CHAPTER, payload: null });
  } catch (err) {
    console.error('ERROR CREATING CHAPTER >>', err);
    yield put({
      type: POST_CHAPTER_ERROR,
      payload: i18n.t('chapterWasNotCreated'),
    });
  }
}

export function* putChapterSaga(action: IPutChapterStartSaga) {
  const { withClose = false, content } = action.payload;

  try {
    yield put({ type: PUT_CHAPTER_START });
    const {
      data: { data },
    } = yield call(
      axios.put,
      `v1/me/diaries/${content.diaryId}/chapters/${content.id}`,
      content
    );
    yield put({ type: PUT_CHAPTER_SUCCESS, payload: data });
    if (withClose) yield put({ type: SET_MODAL_CHAPTER, payload: null });
  } catch (err) {
    console.error('ERROR UPDATING CHAPTER >>', err);
    yield put({
      type: PUT_CHAPTER_ERROR,
      payload: i18n.t('chapterWasNotSaved'),
    });
  }
}

export function* deleteChapterSaga(action: IDeleteChapterStartSaga) {
  const { diaryId, chapterId } = action.payload;

  try {
    yield put({ type: DELETE_CHAPTER_START });
    yield call(axios.delete, `v1/me/diaries/${diaryId}/chapters/${chapterId}`);
    yield put({ type: DELETE_CHAPTER_SUCCESS, payload: chapterId });
  } catch (err) {
    console.error('ERROR REMOVING CHAPTER >>', err);
    yield put({
      type: DELETE_CHAPTER_ERROR,
      payload: i18n.t('chapterWasNotDeleted'),
    });
  }
}

export function* getLayoutsSaga() {
  try {
    yield put({ type: GET_LAYOUTS_START });
    const {
      data: { data },
    } = yield call(axios.get, `/v1/me/page-layouts`);
    yield put({ type: GET_LAYOUTS_SUCCESS, payload: data });
  } catch (err) {
    console.error('ERROR GETTING LAYOUTS >>', err);
    yield put({
      type: GET_LAYOUTS_ERROR,
      payload: i18n.t('layoutsWereNotFound'),
    });
  }
}

export function* putLayoutSaga(action: IPutLayoutStartSaga) {
  const { pageId, layout, chapterId, type } = action.payload;

  try {
    yield put({ type: PUT_LAYOUT_START });
    const found = yield select(getChapterById, chapterId!);
    const sheets = yield select(getSheetsByChapter, chapterId!);
    const content = yield call(
      changeLayoutData,
      found,
      sheets,
      pageId,
      layout,
      type
    );
    if (!content) throw new Error('Layout was not updated in data');
    yield putChapterSaga({
      type: PUT_CHAPTER_START_SAGA,
      payload: { content },
    });
    yield put({ type: PUT_LAYOUT_SUCCESS });
  } catch (err) {
    console.error('ERROR UPDATING LAYOUT >>', err);
    yield put({
      type: PUT_LAYOUT_ERROR,
      payload: i18n.t('layoutWasNotUpdated'),
    });
  }
}

export function* postImageSaga(action: IPostImageStartSaga) {
  const { data, image } = action.payload;
  const { diaryId, chapterId, block } = data!;

  try {
    yield put({ type: POST_IMAGE_START });
    const {
      data: { data },
    } = yield call(axios.post, `v1/me/diaries/${diaryId}/images`, image);
    const found = yield select(getChapterById, chapterId!);
    const content = yield call(changeImageData, found, block!, data);
    if (!content) throw new Error('Image was saved, but not updated');
    yield putChapterSaga({
      type: PUT_CHAPTER_START_SAGA,
      payload: { content },
    });
    yield put({ type: POST_IMAGE_SUCCESS });
  } catch (err) {
    console.error('ERROR SAVING IMAGE >>', err);
    yield put({
      type: POST_IMAGE_ERROR,
      payload: i18n.t('imageWasNotSaved'),
    });
  }
}

export function* putEmptyBoxDescSaga(action: IPutEmptyBoxStartSaga) {
  const { data, description } = action.payload;
  const { chapterId, block } = data!;

  try {
    yield put({ type: PUT_EMPTY_BOX_START });
    const found = yield select(getChapterById, chapterId!);
    const content = yield call(
      changeImageData,
      found,
      block,
      null,
      description
    );
    if (!content) throw new Error('Empty box was not updated');
    yield putChapterSaga({
      type: PUT_CHAPTER_START_SAGA,
      payload: { content },
    });
    yield put({ type: PUT_EMPTY_BOX_SUCCESS });
  } catch (err) {
    console.error('ERROR SAVING EMPTY BOX >>', err);
    yield put({
      type: POST_IMAGE_ERROR,
      payload: i18n.t('emptyBoxWasNotSaved'),
    });
  }
}

export function* saveMapSaga(action: IPostMapStartSaga) {
  const { data, map, imageData, isRoute } = action.payload;
  const { diaryId, chapterId, block } = data!;
  const mapType = isRoute ? 'routeMap' : 'simpleMap';
  const { locations, type, ...rest } = map;
  const postData = {
    ...rest,
    newLocations: { features: locations, type },
    imageData: imageData.split(',')[1],
  };
  try {
    yield put({ type: POST_MAP_START });
    const {
      data: { data },
    } = yield call(
      axios.post,
      `v1/me/diaries/${diaryId}/chapters/${chapterId}/contentblocks/${block.id}/${mapType}`,
      postData
    );
    const found = yield select(getChapterById, chapterId!);
    let content = null;

    if (isRoute)
      content = yield call(
        changeRouteMapData,
        found,
        block!,
        revertRouteMap(map, data.imageId)
      );
    else
      content = yield call(
        changeSimpleMapData,
        found,
        block!,
        revertSimpleMap(map, data.imageId)
      );

    if (!content) throw new Error('Image was saved, but not updated');
    yield putChapterSaga({
      type: PUT_CHAPTER_START_SAGA,
      payload: { content },
    });
    yield put({ type: POST_MAP_SUCCESS });
  } catch (err) {
    console.error('ERROR SAVING MAP >>', err);
    yield put({
      type: POST_MAP_ERROR,
      payload: i18n.t('mapWasNotSaved'),
    });
  }
  yield put({
    type: POST_MAP_ERROR,
    payload: i18n.t('mapWasNotSaved'),
  });
}

// Watchers
export function* watchGetChaptersSaga() {
  yield takeLatest(GET_CHAPTERS_START_SAGA, getChaptersSaga);
}
export function* watchGetChapterSheetsSaga() {
  yield takeLatest(GET_SHEETS_START_SAGA, getSheetsSaga);
}
export function* watchPostChapterSaga() {
  yield takeLatest(POST_CHAPTER_START_SAGA, addChapterSaga);
}
export function* watchSortChapterSaga() {
  yield takeLatest(SORT_CHAPTER_START_SAGA, sortChapterSaga);
}
export function* watchPutChapterSaga() {
  yield takeLatest(PUT_CHAPTER_START_SAGA, putChapterSaga);
}
export function* watchDeleteChapterSaga() {
  yield takeLatest(DELETE_CHAPTER_START_SAGA, deleteChapterSaga);
}
export function* watchPutLayoutSaga() {
  yield takeLatest(PUT_LAYOUT_START_SAGA, putLayoutSaga);
}
export function* watchPostImageSaga() {
  yield takeLatest(POST_IMAGE_START_SAGA, postImageSaga);
}
export function* watchPutEmptyBoxSaga() {
  yield takeLatest(PUT_EMPTY_BOX_START_SAGA, putEmptyBoxDescSaga);
}
export function* watchGetLayoutsSaga() {
  yield takeLatest(GET_LAYOUTS_START_SAGA, getLayoutsSaga);
}
export function* watchSaveMapSaga() {
  yield takeLatest(POST_MAP_START_SAGA, saveMapSaga);
}

export default function* allDiarySagas() {
  yield all([
    watchGetChaptersSaga(),
    watchGetChapterSheetsSaga(),
    watchPostChapterSaga(),
    watchPutChapterSaga(),
    watchDeleteChapterSaga(),
    watchSortChapterSaga(),
    watchGetLayoutsSaga(),
    watchPutLayoutSaga(),
    watchPostImageSaga(),
    watchPutEmptyBoxSaga(),
    watchSaveMapSaga(),
  ]);
}
