import { put, takeLatest, all, call, delay } from 'redux-saga/effects';
import { getChapters } from 'store/actions/chapter-actions';
import {
  GET_DIARY_START,
  GET_DIARY_START_SAGA,
  GET_DIARY_SUCCESS,
  GET_DIARY_ERROR,
  GET_DIARY_STYLES_START,
  GET_DIARY_STYLES_START_SAGA,
  GET_DIARY_STYLES_SUCCESS,
  GET_DIARY_STYLES_ERROR,
  UPDATE_DIARY_START_SAGA,
  UPDATE_DIARY_START,
  UPDATE_DIARY_SUCCESS,
  CREATE_DIARY_START_SAGA,
  CREATE_DIARY_START,
  CREATE_DIARY_SUCCESS,
  CREATE_DIARY_ERROR,
  DIARY_RESET,
  UPDATE_DIARY_COMPLETE,
  IGetDiaryStartSaga,
  ICreateDiaryStartSaga,
  IUpdateDiaryStartSaga,
} from 'store/actions/diary-actions/diary-action-types';
import {
  GET_COVER_START_SAGA,
  GET_COVER_START,
  UPDATE_COVER_START_SAGA,
  GET_COVER_SUCCESS,
  COVER_RESET,
  IUpdateCoverStartSaga,
} from 'store/actions/diary-actions/cover-action-types';

import SagaUtils from './sagaUtils';
import axios from 'axios';
import {
  GET_SHEETS_START_SAGA,
  POST_CHAPTER_START_SAGA,
} from 'store/actions/chapter-actions/chapter-action-types';
import { GTMFirstDiary } from 'util/gtm';
import i18n from 'i18n';

// Sagas
export function* getCoverSaga(action, data = null) {
  try {
    yield put({ type: GET_COVER_START });
    const response = yield call(
      axios.get,
      `v1/me/diaries/${action.payload}/cover-image/front`,
      {
        responseType: 'arraybuffer',
      }
    );
    const cover = SagaUtils.processImage(response);
    yield put({ type: GET_COVER_SUCCESS, payload: cover });

    if (data) yield put({ type: UPDATE_DIARY_SUCCESS, payload: data });

    yield delay(1500);
    yield put({ type: COVER_RESET });
    yield put({ type: DIARY_RESET });
  } catch (err) {
    // TODO ADD Error Messages
    console.error('ERROR >>', err);
  }
}

const checkDiaryId = (payload: string) =>
  !payload
    ? Promise.reject({ response: { status: 404 } })
    : Promise.resolve(payload);

export function* getDiarySaga(action: IGetDiaryStartSaga) {
  const { payload } = action;
  try {
    yield put({ type: GET_DIARY_START });
    yield call(checkDiaryId, payload);

    let [diary, cover] = yield all([
      call(axios.get, `v1/me/diaries/${payload}`),
      call(axios.get, `v1/me/diaries/${payload}/cover-image/front`, {
        responseType: 'arraybuffer',
      }),
    ]);

    [diary, cover] = yield all([
      diary.data.data,
      SagaUtils.processImage(cover),
    ]);
    yield put({ type: GET_DIARY_SUCCESS, payload: { diary, cover } });
    yield put(getChapters(payload));
  } catch (err) {
    let errorMessage = i18n.t('somethingWentWrong');
    if (err.response.status === 404) {
      errorMessage = i18n.t('diaryCantBeFound');
    }
    yield put({ type: GET_DIARY_ERROR, payload: errorMessage });
  }
}

// Add Type check on action
export function* createDiarySaga(action: ICreateDiaryStartSaga) {
  const { payload } = action;
  try {
    yield put({ type: CREATE_DIARY_START });

    let diaryData = payload.diaryData;

    if (payload.voucherId) {
      diaryData.voucherId = payload.voucherId;
    }

    const {
      data: { data },
    } = yield call(axios.post, `v1/me/diaries`, diaryData);

    const { isFirstDiary, id } = data;

    if (isFirstDiary) {
      GTMFirstDiary(payload.user);
    }

    yield updateCoverSaga({
      type: 'UPDATE_COVER_START_SAGA',
      payload: { diaryId: id, image: payload.image },
    });
    yield put({
      type: POST_CHAPTER_START_SAGA,
      payload: { content: SagaUtils.initialChapter(id), withClose: false },
    });
    yield put({
      type: CREATE_DIARY_SUCCESS,
      payload: { diary: data, createdWithVoucher: !!payload.voucherId },
    });
  } catch (err) {
    console.error('ERROR CREATE DIARY >>', err);
    yield put({
      type: CREATE_DIARY_ERROR,
      payload: i18n.t('diaryCreationFailed'),
    });
  }
}

export function* updateDiarySaga(action: IUpdateDiaryStartSaga) {
  const { diary, notCover, chapterIds } = action.payload;
  try {
    yield put({ type: UPDATE_DIARY_START });
    const {
      data: { data },
    } = yield call(axios.put, `v1/me/diaries/${diary!.id}`, diary);
    if (notCover) {
      yield put({
        type: GET_SHEETS_START_SAGA,
        payload: { diaryId: diary!.id, chapterIds, isUpdate: true },
      });
      yield delay(2000);
      yield put({ type: UPDATE_DIARY_SUCCESS, payload: data });
      yield put({ type: UPDATE_DIARY_COMPLETE });
    } else yield getCoverSaga({ payload: diary!.id }, data);
  } catch (err) {
    // TODO ADD Error Messages
    console.error('ERROR PUT DIARY >>', err);
  }
}

export function* updateCoverSaga(action: IUpdateCoverStartSaga) {
  const { diaryId, image } = action.payload;
  try {
    yield put({ type: GET_COVER_START });
    yield call(axios.put, `v1/me/diaries/${diaryId}/cover-image`, image);

    yield getCoverSaga({ payload: diaryId });
  } catch (err) {
    // TODO ADD Error Messages
    console.error('ERROR UPDATE COVER', err);
  }
}

export function* getDiaryStylesSaga() {
  try {
    yield put({ type: GET_DIARY_STYLES_START });
    const {
      data: { data },
    } = yield call(axios.get, `v1/me/styles`);
    yield put({ type: GET_DIARY_STYLES_SUCCESS, payload: data });
  } catch (err) {
    console.error('ERROR GET DIARY STYLES >>', err);
    yield put({
      type: GET_DIARY_STYLES_ERROR,
      payload: i18n.t('somethingWentWrong'),
    });
  }
}

// Watchers
export function* watchGetDiarySaga() {
  yield takeLatest(GET_DIARY_START_SAGA, getDiarySaga);
}

export function* watchGetDiaryStylesSaga() {
  yield takeLatest(GET_DIARY_STYLES_START_SAGA, getDiaryStylesSaga);
}

export function* watchCreateDiarySaga() {
  yield takeLatest(CREATE_DIARY_START_SAGA, createDiarySaga);
}

export function* watchUpdateDiarySaga() {
  yield takeLatest(UPDATE_DIARY_START_SAGA, updateDiarySaga);
}

export function* watchUpdateCoverSaga() {
  yield takeLatest(UPDATE_COVER_START_SAGA, updateCoverSaga);
}

export function* watchGetCoverSaga() {
  yield takeLatest(GET_COVER_START_SAGA, getCoverSaga);
}

export default function* allDiarySagas() {
  yield all([
    watchGetDiarySaga(),
    watchGetDiaryStylesSaga(),
    watchUpdateCoverSaga(),
    watchUpdateDiarySaga(),
    watchCreateDiarySaga(),
  ]);
}
