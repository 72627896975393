import styled from "styled-components/macro";

export const Container = styled.div`
	padding-right: 15px;
	padding-left: 15px;
	margin-right: auto;
	margin-left: auto;
	width: 98%;
`;

export const Divider = styled.hr`
	margin: ${({ spacing }) => (spacing ? `${spacing}em 0` : "2em 0")};
	background: #ededed;
	border: none;
	height: ${({ height }) => (height ? height : 1)}px;
`;
