import React, { useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import { Button, BtnWhite } from 'components/Common/Button';
import { Tooltip } from 'antd';
import GetIcon from 'components/Common/Icon';
import { setModalChapter, setPreview } from 'store/actions/chapter-actions';
import { AppState } from 'store/reducers/rootReducer';
import { NavItem, NavWrap } from './index';
import { Container } from 'components/Common/Layout';
import OrderModal from 'components/Editor/Modals/OrderModal';
import EditCoverModal from 'components/Editor/Modals/EditCoverModal';
import ShareModal from 'components/Editor/Modals/ShareModal';
import StylesModal from 'components/Editor/Modals/StylesModal/index';
import { openShareModal } from 'store/actions/share-actions';
import {
  mdiCogs,
  mdiFormatLetterCase,
  mdiEarth,
  mdiShareVariantOutline,
  mdiCart,
  mdiEyeOutline,
  mdiEyeOffOutline,
  mdiPlus,
  mdiFilePdf,
  mdiAccountGroup,
} from '@mdi/js';
import DownloadPDFModal from 'components/Editor/Modals/DownloadPDFModal';
import { track } from 'hoc/CustomerIO';

export const SubNav = styled.nav`
  position: fixed;
  background: #ffffff;
  z-index: 9;
  border: 0.1em solid #ededed;
  padding: 5px;
  top: 70px;
  left: 0;
  right: 0;
`;

const premiumPlansPath = {
  en: 'premium-plans',
  nl: 'premium-abonnement',
  es: 'premium-plans',
};

const freeTypes = ['Free', 'Basic'];

const SubHeader: React.FC = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { chapters, previewMode } = useSelector(
    ({ chapters }: AppState) => chapters
  );
  const { diary } = useSelector(({ diary }: AppState) => diary);
  const {
    userAccount: { isValid, premiumModelType },
  } = useSelector(({ user }: AppState) => user);
  const dispatch = useDispatch();
  const [pdfModal, setPDFModal] = React.useState(false);
  const [orderModal, setOrderModal] = React.useState<boolean>(false);
  const [coverModal, setCoverModal] = React.useState<boolean>(false);
  const [stylesModal, setStylesModal] = React.useState<boolean>(false);

  const canDownloadPDF =
    isValid && premiumModelType && !freeTypes.includes(premiumModelType);

  const showBecomePremiumMemberButton =
    isValid && premiumModelType && premiumModelType === 'Free';

  const orderUrl = useMemo(
    () =>
      process.env.REACT_APP_ORDER_URL?.replace('{lang}', language).replace(
        '{diaryId}',
        diary!.id
      ),
    [diary, language]
  );
  const blogUrl = useMemo(
    () => `${process.env.REACT_APP_WEBSITE_URL}/${language}/diary/${diary?.id}`,
    [diary, language]
  );

  const disableBlog = useMemo(
    () => !chapters.find((c) => c.isPublic),
    [chapters]
  );

  const redirectToPremiumPage = () => {
    window.location.href = `${process.env.REACT_APP_WEBSITE_URL}/${language}/${premiumPlansPath[language]}`;
  };

  const diaryTitleData = { diary_title: diary?.name || '' };

  const handleClickNewChapter = () => {
    dispatch(setModalChapter('-1'));
    track('New Chapter Clicked', diaryTitleData);
  };

  const handleClickEditCover = () => {
    setCoverModal(!coverModal);
    track('Edit Cover Clicked', diaryTitleData);
  };

  const handleClickStyles = () => {
    setStylesModal(!stylesModal);
    track('Styles Clicked', diaryTitleData);
  };

  const handleClickViewBlog = () => {
    window.open(blogUrl);
    track('View Blog Clicked', diaryTitleData);
  };

  const handleClickShare = () => {
    dispatch(openShareModal());
    track('Share Clicked', diaryTitleData);
  };

  const handleClickDownloadPdf = () => {
    setPDFModal(true);
    track('Download PDF Clicked', diaryTitleData);
  };

  const handleClickOrder = () => {
    setOrderModal(true);
    track('Order Clicked', diaryTitleData);
  };

  return (
    <SubNav>
      <Container>
        <NavWrap>
          <NavItem>
            <Button as={BtnWhite} onClick={handleClickNewChapter}>
              <GetIcon path={mdiPlus} />
              {t('newChapter')}
            </Button>
            <Button as={BtnWhite} onClick={handleClickEditCover}>
              <GetIcon path={mdiCogs} />
              {t('editCover')}
            </Button>
            <Button as={BtnWhite} onClick={handleClickStyles}>
              <GetIcon path={mdiFormatLetterCase} />
              {t('diaryStyle')}
            </Button>
            <Tooltip
              placement='bottom'
              title={disableBlog ? t('noPublicChapters') : null}
            >
              {/* span is here because of tooltip's known bug */}
              <span>
                <Button
                  as={BtnWhite}
                  onClick={handleClickViewBlog}
                  disabled={disableBlog}
                >
                  <GetIcon path={mdiEarth} />
                  {t('viewBlog')}
                </Button>
              </span>
            </Tooltip>
            <Button as={BtnWhite} onClick={handleClickShare}>
              <GetIcon path={mdiShareVariantOutline} />
              {t('share')}
            </Button>
            {showBecomePremiumMemberButton && (
              <Button as={BtnWhite} onClick={() => redirectToPremiumPage()}>
                <GetIcon path={mdiAccountGroup} />
                {t('becomePremiumMember')}
              </Button>
            )}
          </NavItem>
          <NavItem>
            <Tooltip
              placement='bottom'
              title={!canDownloadPDF ? t('pdfToolTipText') : ''}
            >
              {/* span is here because of tooltip's known bug */}
              <span>
                <Button
                  as={BtnWhite}
                  disabled={!canDownloadPDF}
                  data-type='preview'
                  onClick={handleClickDownloadPdf}
                >
                  <GetIcon path={mdiFilePdf} />
                  {t('downloadPDF')}
                </Button>
              </span>
            </Tooltip>

            <Button
              as={BtnWhite}
              data-type='preview'
              onClick={() => dispatch(setPreview())}
            >
              {previewMode ? (
                <>
                  <GetIcon path={mdiEyeOffOutline} />
                  {t('closePreview')}
                </>
              ) : (
                <>
                  <GetIcon path={mdiEyeOutline} />
                  {t('preview')}
                </>
              )}
            </Button>
            <Button as={BtnWhite} filled onClick={handleClickOrder}>
              <GetIcon path={mdiCart} />
              {t('order')}
            </Button>
          </NavItem>
        </NavWrap>
      </Container>
      {/* Modals */}
      {orderModal && (
        <OrderModal setVisible={setOrderModal} orderUrl={orderUrl || '#'} />
      )}
      <EditCoverModal visible={coverModal} setVisible={setCoverModal} />
      <DownloadPDFModal
        visible={pdfModal}
        setVisible={setPDFModal}
        diaryId={diary!.id}
      />
      <ShareModal />
      <StylesModal visible={stylesModal} setVisible={setStylesModal} />
    </SubNav>
  );
};

export default SubHeader;
