import {
  SET_USER,
  UserActionTypes,
} from 'store/actions/user-actions/user-action-types';
import { UserType, AccountProps, UserDeactivationReason } from 'lib/types';

interface IUser {
  user: UserType | null;
  userAccount: AccountProps;
}

export const initialState: IUser = {
  user: null,
  userAccount: {
    id: '',
    dateFormat: '',
    email: '',
    language: 'EN',
    receiveNewsletter: false,
    isUserDeactivated: false,
    deactivationReason: UserDeactivationReason.None,
  },
};

const UserReducer = (
  state: IUser = initialState,
  action: UserActionTypes
): IUser => {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        user: { ...action.payload.user },
        userAccount: { ...action.payload.account },
      };
    default:
      return state;
  }
};

export default UserReducer;
