import React, { useState, useEffect, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Anchor } from 'components/Common/Button';
import { Modal, Input, DatePicker, Switch, Popconfirm } from 'antd';
import { H4 } from 'components/Common/Text';
import styled from 'styled-components/macro';
import { useSelector, useDispatch } from 'react-redux';
import { AppState } from 'store/reducers/rootReducer';
import {
  addChapter,
  setModalChapter,
  updateChapter,
  deleteChapter,
} from 'store/actions/chapter-actions';
import { EditorState } from 'draft-js';
import { ChapterContentType } from 'lib/types';
import moment from 'moment';
import 'draft-js/dist/Draft.css';
import RichEditor, { exportContentHtml, importContent } from './RichEditor';
import { getDateFormat } from 'util/chapterUtils';
import { DateFormatEnum } from 'lib/types';
import { track } from 'hoc/CustomerIO';

const Label = styled.label`
  display: table;
  margin: 0 0.5em;
  font-size: 1.45em;
  font-weight: 400;

  small {
    color: indianred;
  }
`;

const InputWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 1em 0;
`;

const ButtonsWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  div {
    display: flex;
  }

  @media (max-width: 768px) {
    flex-direction: column-reverse;

    a {
      margin-top: 1em;
    }
  }
`;

const DeleteChapter = styled(Anchor)`
  text-align: left;
  text-decoration: underline;
  color: #cc0000;

  &:hover {
    color: #cc0000;
    font-weight: 600;
  }
`;

const SwitchWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1em 1em 0;

  @media (max-width: 768px) {
    justify-content: center;
  }
`;

const SwitchText = styled.p`
  font-size: 1.1em;
  font-weight: 500;
  margin: 0;
  margin-left: 0.5em;
`;

const FormControlWrap = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 1em 0;
`;

const LocationWrap = styled.div`
  width: 65%;
  margin-right: 0.5em;
`;

const DateWrap = styled.div`
  width: 35%;
  .ant-picker {
    width: 100%;
  }
`;

const EditorModal: React.FC = (): ReactElement => {
  const { t } = useTranslation();
  const { modalChapter, chapters, isChapterUpdating } = useSelector(
    ({ chapters }: AppState) => chapters
  );
  const { diary } = useSelector(({ diary }: AppState) => diary);
  const { userAccount } = useSelector(({ user }: AppState) => user);
  const dispatch = useDispatch();
  const [content, setContent] = useState<ChapterContentType | undefined>();
  const [editorState, setEditorState] = React.useState(() =>
    EditorState.createEmpty()
  );
  const isAddDiary = modalChapter === '-1';

  useEffect(() => {
    const prepareAddDiary = () => {
      const { sortOrder, diaryId } = chapters[chapters.length - 1];
      setContent({
        diaryId,
        date: moment(),
        isPublic: false,
        location: '',
        text: '',
        title: t('newChapter'),
        sortOrder: sortOrder + 1,
      } as any);
      setEditorState(EditorState.createWithContent(importContent('')));
    };
    if (modalChapter && chapters.length) {
      if (isAddDiary) prepareAddDiary();
      else {
        const chapterFound = chapters.find(
          (c) => c.id === modalChapter
        ) as ChapterContentType;
        let text = chapterFound.text;
        if (diary?.style.textStyle.font.hasBoldAndItalic) {
          text = text.replace(/\[\[\[/g, '<').replace(/\]\]\]/g, '>');
        } else {
          text = text
            .replace(/\[\[\[\s*\/?([b,i]+\s*\/?)\]\]\]/g, '')
            .replace(/\[\[\[/g, '<')
            .replace(/\]\]\]/g, '>');
        }
        const copyChapter = {
          ...chapterFound,
          text: text,
        };
        setContent(copyChapter);
        setEditorState(
          EditorState.createWithContent(importContent(copyChapter.text))
        );
      }
    }
  }, [modalChapter, chapters, isAddDiary, userAccount, diary, t]);

  const saveChanges = (withClose = false) => {
    const text = exportContentHtml(editorState);
    track('New Chapter Saved', {
      diary_title: diary?.name || '',
      chapter_title: content?.title || '',
      location: content?.location || '',
      date: moment(content?.date).unix(),
      text,
      is_public: content?.isPublic || false,
    });

    if (isAddDiary) {
      return dispatch(addChapter({ ...content!, text }, withClose));
    }
    dispatch(updateChapter({ ...content!, text }, withClose));
  };

  const removeChapter = () => {
    dispatch(deleteChapter(content!.diaryId, content!.id));
  };

  const handleChange = (e: any) => {
    if (moment.isMoment(e))
      setContent({ ...content!, date: e.format('YYYY-MM-DDT00:00:00') });
    else if (typeof e === 'string')
      setContent((prevState) => ({ ...prevState, text: e } as any));
    else if (typeof e === 'boolean') setContent({ ...content!, isPublic: e });
    else setContent({ ...content!, [e.target.id]: e.target.value });
  };

  const deleteLink = () => {
    if (chapters.length < 2 || isAddDiary) return null;
    return <DeleteChapter>{t('deleteChapter')}</DeleteChapter>;
  };

  return (
    <Modal
      style={{ top: '3%' }}
      title={<H4>{t('editChapter')}</H4>}
      maskClosable={false}
      visible={!!modalChapter}
      onCancel={() => dispatch(setModalChapter(null))}
      footer={[
        <ButtonsWrap key='saveBtns'>
          <Popconfirm
            title={t('deleteChapterMsg')}
            okText={t('proceedBtnTxt')}
            cancelText={t('btnNo')}
            onConfirm={removeChapter}
            okButtonProps={{ loading: isChapterUpdating }}
          >
            {deleteLink()}
          </Popconfirm>
          <div>
            <Button
              filled
              disabled={isChapterUpdating || !content?.title?.trim()}
              onClick={() => saveChanges(false)}
              style={{ marginRight: '10px' }}
            >
              {t('save')}
            </Button>
            <Button
              filled
              disabled={isChapterUpdating || !content?.title?.trim()}
              onClick={() => saveChanges(true)}
            >
              {t('saveAndClose')}
            </Button>
          </div>
        </ButtonsWrap>,
      ]}
      width={800}
    >
      <InputWrap>
        <Label htmlFor='title'>
          {t('title')}
          <small> *</small>
        </Label>
        <Input
          id='title'
          value={content?.title}
          onChange={handleChange}
          maxLength={100}
        />
      </InputWrap>
      <FormControlWrap>
        <LocationWrap>
          <Label htmlFor='location'>{t('location')}</Label>
          <Input
            id='location'
            value={content?.location}
            onChange={handleChange}
            maxLength={100}
          />
        </LocationWrap>
        <DateWrap>
          <Label htmlFor='date'>{t('date')}</Label>
          <DatePicker
            id='date'
            allowClear={false}
            format={getDateFormat(userAccount.dateFormat as DateFormatEnum)}
            value={moment(content?.date)}
            onChange={handleChange}
          />
        </DateWrap>
      </FormControlWrap>
      <InputWrap>
        <Label htmlFor='text'>{t('text')}</Label>
        <RichEditor
          editorState={editorState}
          setEditorState={setEditorState}
          hasBoldAndItalic={!!diary?.style.textStyle.font.hasBoldAndItalic}
        />
      </InputWrap>
      <SwitchWrap>
        <Switch
          checked={content?.isPublic}
          key='switch'
          onChange={(e) => handleChange(e)}
        />
        <SwitchText>{t('makePublic')}</SwitchText>
      </SwitchWrap>
    </Modal>
  );
};

export default EditorModal;
