export type UserType = {
  access_token: string;
  expires_at: number;
  id_token: string;
  profile: {
    amr: string[];
    auth_time: number;
    email: string;
    idp: string;
    name: string;
    preferred_username: string;
    role: string;
    s_hash: string;
    sid: string;
    sub: string;
  };
  refresh_token?: string | undefined;
  scope: string;
  session_state: string;
  state?: any;
  token_type: string;
};

export type AccountProps = {
  acceptedTermsAndConditionsVersion?: number;
  dateFormat: string;
  id: string;
  email?: string;
  language: string;
  receiveNewsletter: boolean;
  isValid?: boolean;
  modalFeatures?: {
    showDownloadButton: boolean;
  };
  premiumModelType?: PremiumModelType;
  validUntil?: string;
  isUserDeactivated: boolean;
  deactivationReason: UserDeactivationReason;
};

export enum UserDeactivationReason {
  None = 'None',
  FreeUsageLimit = 'FreeUsageLimit',
  PremiumExpiration = 'PremiumExpiration',
}

export type PremiumModelType = 'Free' | 'Online' | 'Print' | 'Basic';

export enum DateFormatEnum {
  Dutch = 'Dutch',
  American = 'American',
}

export type DiaryType =
  | {
      account: {
        email: string;
        id: string;
      };
      name: string;
      period: string;
      chapterCount: number;
      coverFrontImageUrl: string;
      coverImageUrl: string;
      createdOn: string;
      id: string;
      isPublic: boolean;
      isRemoved: boolean;
      isShownInTheEditorsChoice: boolean;
      isShownOnHomepage: boolean;
      style: StylesType;
      totalNumberOfPages: number;
    }
  | undefined;

export type StylesType = {
  sortOrder: number;
  displayName: string;
  titleStyle: {
    font: {
      value: number;
      displayName: string;
      webfontName: string;
      hasBoldAndItalic: boolean;
    };
    fontSize: number;
    isBold: boolean;
    textColor: {
      value: number;
      displayName: string;
      canBeDateAndLocationColor: boolean;
      printColor: {
        cyan: number;
        magenta: number;
        yellow: number;
        black: number;
      };
      screenColor: {
        redComponent: number;
        greenComponent: number;
        blueComponent: number;
        htmlHex: string;
      };
    };
    lineAlign: string;
    lineWidth: number;
    lineColor: {
      value: number;
      displayName: string;
      canBeDateAndLocationColor: boolean;
      printColor: {
        cyan: number;
        magenta: number;
        yellow: number;
        black: number;
      };
      screenColor: {
        redComponent: number;
        greenComponent: number;
        blueComponent: number;
        htmlHex: string;
      };
    };
  };
  dateAndLocationStyle: {
    showDate: boolean;
    showLocation: boolean;
    font: {
      value: number;
      displayName: string;
      webfontName: string;
      hasBoldAndItalic: boolean;
    };
    fontSize: number;
    textColor: {
      value: number;
      displayName: string;
      canBeDateAndLocationColor: boolean;
      printColor: {
        cyan: number;
        magenta: number;
        yellow: number;
        black: number;
      };
      screenColor: {
        redComponent: number;
        greenComponent: number;
        blueComponent: number;
        htmlHex: string;
      };
    };
    isItalic: boolean;
    align: string;
  };
  textStyle: {
    font: {
      value: number;
      displayName: string;
      webfontName: string;
      hasBoldAndItalic: boolean;
    };
    fontSize: number;
    align: string;
    showPageNumbers: boolean;
  };
};

export type CreateDiaryType = {
  name: string;
  period: string;
  style: StylesType;
  voucherId?: string;
};

export type DiaryOrderMetaDataType = {
  diaryPageCount: number;
  isOrderable: boolean;
  maximumOrderPageCount: number;
  minimumOrderPageCount: number;
  printPageCount: number;
};

//************** CHAPTER TYPES *****************//

type Margin = { top: number; right: number; bottom: number; left: number };
export type Size = { width: number; height: number };
export type Position = { x: number; y: number };
type TextBlock = { position: Position; text: string; width: number };
export type ContentBlock = {
  id: string;
  orginialImagePath: string;
  optimizedImagePath: string;
  margin: Margin;
  position: Position;
  size: Size;
  simpleMapData?: SimpleMapDataType | null;
  imageMetaData?: Size;
  imageId?: string;
  routeMapData?: RouteMapDataType | null;
  emptyBoxDescription?: string;
};

export type MapPoint = {
  longitude: number;
  latitude: number;
};

export type SimpleMapDataType = {
  position: MapPoint;
  imageId: string;
  zoomLevel: number;
  markers: MapPoint[];
};

export type RouteMapDataType = {
  position: MapPoint;
  zoomLevel: number;
  imageId: string;
  locations: {
    position: MapPoint;
    pixelPosition: Position;
    city: string;
    country: string;
  }[];
};

export type LinestringType = {
  type: string;
  geometry: {
    type: string;
    coordinates: any;
  };
  properties: any;
};

export type PointsDataType = {
  type: string;
  locations: FeatureType[];
  zoomLevel: number;
  position: MapPoint;
};

export type FeatureType = {
  type: string;
  properties: {
    id: string;
    isFirstOrLast?: boolean;
    city?: string;
    country?: string;
  };
  geometry: {
    type: string;
    coordinates: number[];
  };
  pixelPosition?: Position;
};

export type PageType = {
  id: string;
  number: number;
  header: {
    title: TextBlock[];
    topLine: { position: Position; size: Size };
    bottomLine: { position: Position; size: Size };
    dateAndLocation: TextBlock;
    height: number;
  };
  contentBlocks: ContentBlock[];
  lines: TextBlock[];
};

export type PageLayoutType = {
  pageId: string;
  layout: {
    blocks: {
      margin: Margin;
      position: Position;
      size: Size;
    }[];
    canBeUsedOnFirstPage: boolean;
  };
  contentBlocks: ContentBlock[];
  canBeUsedOnFirstPage: boolean;
  maxNumberOfPagesBefore: number;
};

export type ChapterContentType = {
  date: string;
  id: string;
  isPublic: boolean;
  sortOrder: number;
  title: string;
  pagesCount: number;
  diaryId: string;
  layoutPages: PageLayoutType[];
  location: string;
  pages: PageType[];
  text: string;
  imagePathPrefix: string;
  pagesBefore: number;
  isLoaded: boolean;
};

export type ChapterPageType = {
  id: string;
  diaryId: string;
  title: string;
  layoutPage: PageLayoutType;
  page: PageType;
  pagesBefore: number;
  sortOrder: number;
};

//************** LAYOUT TYPES *****************//
export type LayoutType = {
  sortOrder: number;
  name: string;
  icon: string;
  canBeFirstPage: boolean;
  layout: {
    blocks: { margin: Margin; position: Position; size: Size }[];
    canBeUsedOnFirstPage: boolean;
  };
};

export enum LayoutChangeEnum {
  CHANGE = 'change',
  LEFT = 'left',
  RIGHT = 'right',
}

export type LayoutChangeType = {
  sheet: ChapterPageType;
  type: LayoutChangeEnum;
};

//************** IMAGE TYPES *****************//
export enum ImageBoxType {
  IMAGE = 'image',
  EDIT_IMAGE = 'edit_image',
  MAP_TYPE = 'map_type',
  SIMPLE_MAP = 'simple_map',
  ROUTE_MAP = 'route_map',
  EMPTYBOX = 'box',
}

export type ImageChangeType = {
  diaryId: string;
  chapterId: string;
  block: ContentBlock;
  size: Size;
  type: ImageBoxType | null;
  image?: string;
  mode?: 'edit' | 'create';
};

export enum OrderModalStatus {
  Closed,
  LessThanLimit,
  MoreThanLimit,
}

export type IconType = {
  color?: string;
  width: number;
  height: number;
};
