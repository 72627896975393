import React, { useState, useEffect, useCallback } from 'react';
import { requestAccountData } from '../Callback';
import { useDispatch } from 'react-redux';
import { RouteProps, useLocation } from 'react-router-dom';
import { setUserAction } from 'store/actions/user-actions';
import userManager from 'util/userManager';
import { User } from 'oidc-client';
import { UserType } from 'lib/types';
import { requestInterceptor } from 'util/AxiosHeaders';

import ErrorBoundary from 'hoc/ErrorBoundary';
import LoadingScreen from 'components/Common/LoadingScreen';
import { GTMPageView } from 'util/gtm';
interface IPrivateRouteProps extends RouteProps {
  component: any;
  routeProps: any;
}

const PrivateRoute: React.FC<IPrivateRouteProps> = ({
  component,
  routeProps,
}) => {
  const dispatch = useDispatch();
  const Component = component;
  const [user, setUser] = useState<User | null>(null);
  const [isLoadingUser, setIsLoadingUser] = useState(true);
  const location = useLocation();

  const getUserData = useCallback(async () => {
    try {
      const user = await userManager.getUser();
      requestInterceptor(user);
      const accountData = await requestAccountData();
      setUser(user || null);
      dispatch(setUserAction(user as unknown as UserType, accountData));
      setIsLoadingUser(false);
      GTMPageView('Editor', user, location.pathname);
    } catch (error) {
      console.error(`FETCHING USER DATA FAILED`, error);
    }
  }, [dispatch, location]);

  useEffect(() => {
    getUserData();
  }, [getUserData]);

  if (isLoadingUser) return null;

  if (user) {
    return (
      <ErrorBoundary>
        <Component user={user} {...routeProps} />
      </ErrorBoundary>
    );
  }

  localStorage.setItem('oidc_redirect', window.location.pathname);
  userManager.signinRedirect();
  return <LoadingScreen />;
};

export default PrivateRoute;
