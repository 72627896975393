import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { requestInterceptor, responseInterceptor } from 'util/AxiosHeaders';
import { User } from 'oidc-client';
import { AppState } from 'store/reducers/rootReducer';
import { getDiaryStyles } from 'store/actions/diary-actions';
import { Container } from 'components/Common/Layout';
import { H1, H2 } from 'components/Common/Text';
import StepView from './StepView';
import Stepper from 'components/Stepper';
import Layout from 'hoc/Layout';
import ErrorMessage from 'components/Common/ErrorMessage';
import { isEmpty as _isEmpty } from 'lodash';
import LoadingScreen from 'components/Common/LoadingScreen';
import styled from 'styled-components/macro';
import { message } from 'antd';
import MobileScreenModal from 'components/MobileScreenModal';
import useCheckMobileScreen from 'util/useCheckMobileScreen';

// Styled Components

const MainViewWrap = styled.div`
  width: 100%;
  max-width: 900px;
  margin: 0 auto;

  h1 {
    text-align: center;
    margin-bottom: 1.2em;
  }
`;

// Types

type CreateDiaryViewTypes = { match: any; user: User };

const CreateDiaryView: React.FC<CreateDiaryViewTypes> = ({ match, user }) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const [currentView, setCurrentView] = useState(1);
  const {
    diaryStyles,
    isDiaryStylesLoading,
    errorStylesMessage,
    isCreatingDiary,
    createdDiaryId,
    createdWithVoucher,
  } = useSelector(({ diary }: AppState) => diary);

  const isMobile = useCheckMobileScreen();

  useEffect(() => {
    if (user) {
      requestInterceptor(user);
      responseInterceptor();
      dispatch(getDiaryStyles());
    }
  }, [user, dispatch, match]);

  //* Redirect if diary was created
  useEffect(() => {
    if (!!createdDiaryId) {
      const content = createdWithVoucher
        ? 'createdWithVoucherDiarySuccess'
        : 'createdDiarySuccess';
      message.success({
        content: t(content),
        style: {
          marginTop: '45px',
        },
      });
      setTimeout(() => {
        window.location.href =
          process.env.REACT_APP_DIARY_EDIT_URL?.replace(
            '{lang}',
            i18n.language
          ).replace('{diaryId}', createdDiaryId) ?? '#';
      }, 3000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createdDiaryId, i18n.language, t]);

  const renderError = (
    <Layout>
      <ErrorMessage>
        <H2>{errorStylesMessage}</H2>
      </ErrorMessage>
    </Layout>
  );

  const renderContent = (
    <Layout>
      <Container>
        {!isMobile ? (
          <MainViewWrap>
            <H1>{t('createDiary')}</H1>
            <Stepper currentStep={currentView} />
            <StepView
              currentView={currentView}
              setCurrentStep={setCurrentView}
              voucherId={match?.params?.voucherId}
            />
          </MainViewWrap>
        ) : (
          <MobileScreenModal />
        )}
      </Container>
    </Layout>
  );

  return isDiaryStylesLoading || isCreatingDiary || _isEmpty(user) ? (
    <LoadingScreen />
  ) : !_isEmpty(errorStylesMessage) ? (
    renderError
  ) : !_isEmpty(diaryStyles) && !isDiaryStylesLoading ? (
    renderContent
  ) : null;
};

export default CreateDiaryView;
