import React, { ReactElement, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import { Menu, Button, Dropdown } from "antd";
import GetIcon from "components/Common/Icon"
import {
    mdiArrowExpandLeft,
    mdiArrowExpandRight,
    mdiClose,
    mdiDelete,
    mdiFileEditOutline
} from "@mdi/js";
import { changeLayout, deleteLayout } from "store/actions/chapter-actions";
import { ChapterPageType } from "lib/types";

const Wrapper = styled.div`
    margin: 0 auto;
    display:flex;
    align-items:center;
    justify-content:space-between;
    position:absolute;
    top:-10px;
    left -15px;
    right:-10px;
    z-index:8;
    width: ${({ width }) => width};

    button {
		display:flex;
        justify-content:center;
        align-items:center;
  		height: 40px;
		width: 40px;
        color:${({ theme }) => theme.whiteColor};
		background-color: ${({ theme }) => theme.primaryColor};
		border-color: ${({ theme }) => theme.primaryColor};

		&:hover {
			color:${({ theme }) => theme.whiteColor};
			background-color: ${({ theme }) => theme.primaryHover};
            border-color: ${({ theme }) => theme.primaryHover};
		}

		&:active,&:focus {
			color:${({ theme }) => theme.whiteColor};
		    background-color: ${({ theme }) => theme.primaryColor};
            border-color: ${({ theme }) => theme.primaryColor};
		}
	}
`

type LayoutButtonsProp = {
    sides: { left: ChapterPageType; right: ChapterPageType };
    width: number;
}

const LayoutButtons: React.FC<LayoutButtonsProp> = ({ sides, width }): ReactElement => {
    const { t } = useTranslation()
    const dispatch = useDispatch();
    const [changeSide, setChangeSide] = React.useState<"left" | "right" | null>(null)

    useEffect(() => {
        setChangeSide(null)
    }, [sides])

    const onClick = ({ key }) => {
        const [type, side] = key.split('_')
        if (type === 'delete') dispatch(deleteLayout(sides[side]))
        else {
            dispatch(changeLayout(sides[side], type))
            setChangeSide(side)
        }
    };

    const StopButton = () =>
        <Button shape="circle" icon={<GetIcon path={mdiClose} className="extra" />} onClick={() => {
            dispatch(changeLayout(undefined))
            setChangeSide(null)
        }} />

    const headerOnPage = (side: string) => !!sides[side].page.header

    const renderMenu = (side: string) => (
        <Menu className="bookLayoutDropdown" onClick={onClick} style={{ [side]: '30px' }}>
            <Menu.Item key={`change_${side}`} icon={<GetIcon path={mdiFileEditOutline} />}>
                {t("changeLayout")}
            </Menu.Item>
            <Menu.Item
                key={`left_${side}`}
                icon={<GetIcon path={mdiArrowExpandLeft} />}
                disabled={headerOnPage(side)}>
                {t("leftLayout")}
            </Menu.Item>
            <Menu.Item key={`right_${side}`} icon={<GetIcon path={mdiArrowExpandRight} />}>
                {t("rightLayout")}
            </Menu.Item>
            <Menu.Item key={`delete_${side}`} icon={<GetIcon path={mdiDelete} />}>
                {t("deleteLayout")}
            </Menu.Item>
        </Menu>
    );

    const DropDown = ({ which }) => {
        if (!sides[which]) return null;
        const placement: any = `bottom${which.charAt(0).toUpperCase() + which.slice(1)}`
        if (changeSide !== which)
            return  <Dropdown overlay={renderMenu(which)} placement={placement}>
                        <Button shape="circle" icon={<GetIcon path={mdiFileEditOutline} className="extra" />} />
                    </Dropdown>
        return <StopButton />
    }

    return <Wrapper width={`${width}px`} >
        <DropDown which="left" />
        <DropDown which="right" />
    </Wrapper>
}

export default LayoutButtons;