import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { List } from "antd";
import GetIcon from "components/Common/Icon";
import { mdiClose } from "@mdi/js";
import * as S from "./styles";
import { mdiDotsVertical, mdiArrowExpandUp, mdiArrowExpandDown } from "@mdi/js";
import { Dropdown, Menu } from "antd";

type RouteListItemProps = {
    item: any;
    index: number;
    isFirst: boolean;
    isLast: boolean;
    onRemove: (index: number) => void;
    onMenuClick: (e: any) => void;
};

const RouteListItem: FC<RouteListItemProps> = ({
    item,
    index,
    isFirst,
    isLast,
    onMenuClick,
    onRemove,
}) => {
    const { t } = useTranslation();

    const renderMenu = () => (
        <Menu onClick={onMenuClick}>
            <Menu.Item
                key={`moveUp-${index}`}
                icon={<GetIcon path={mdiArrowExpandUp} />}
                disabled={isFirst}
            >
                {t("moveUp")}
            </Menu.Item>
            <Menu.Item
                key={`moveDown-${index}`}
                icon={<GetIcon path={mdiArrowExpandDown} />}
                disabled={isLast}
            >
                {t("moveDown")}
            </Menu.Item>
        </Menu>
    );

    return (
        <List.Item>
            <S.ListItem>
                <S.ActionsWrap>
                    <Dropdown overlay={renderMenu()} placement="bottomLeft">
                        {<GetIcon path={mdiDotsVertical} />}
                    </Dropdown>
                    <S.ListItemDot isLast={isLast} />
                </S.ActionsWrap>
                <S.ListItemText>
                    {item.properties.city}{item.properties.city ? "," : ''} {item.properties.country}
                </S.ListItemText>
                <S.ListItemCTA onClick={() => onRemove(index)}>
                    <GetIcon path={mdiClose} className="extra" />
                </S.ListItemCTA>
            </S.ListItem>
        </List.Item>
    );
};

export default RouteListItem