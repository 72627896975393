import { ChapterContentType, ChapterPageType, PageLayoutType } from 'lib/types';
import { DateFormatEnum } from 'lib/types';

export function chapterPageNumber(
  chapterId: string,
  data: ChapterContentType[] | ChapterPageType[]
) {
  if (data.length && data[0].hasOwnProperty('date')) {
    const chapters = data as ChapterContentType[];
    const findChapter = chapters.find((c) => c.id === chapterId);
    if (findChapter) {
      return calcPageNumber(findChapter.pagesBefore);
    }
  }
  if (data.length) {
    const sheets = data as ChapterPageType[];
    //when redirected from dashboard to editor we send 1
    if (chapterId === '1') return calcPageNumber(0);
    const findPageIndex = sheets.findIndex((s) => s.id === chapterId);
    if (findPageIndex !== -1) {
      return calcPageNumber(findPageIndex);
    }
  }
  return null;
}

export function isChapterFound(pgNum: number) {
  if (pgNum === -1) return 0;
  return pgNum;
}

export function calcPageNumber(newPgNum: number) {
  //it must be an even number
  const oddPages = newPgNum % 2 ? 1 : 0;
  return newPgNum - oddPages;
}

export function updateChapter(
  chapters: ChapterContentType[],
  update: ChapterContentType[]
) {
  if (!chapters.length) return update;
  for (let j = 0; j < update.length; j++) {
    let found = false;
    for (let i = 0; i < chapters.length; i++) {
      if (chapters[i].id === update[j].id) {
        chapters[i] = { ...chapters[i], ...update[j] };
        chapters[i].isLoaded = true;
        found = true;
        break;
      }
    }
    if (!found) chapters.push(update[j]);
  }
  return chapters;
}

export function fixPageBeforeNumbers(chapters: ChapterContentType[]) {
  const allChapters = [...chapters];
  let prevPages = 0;
  //fix the page numbers
  for (let i = 0; i < allChapters.length; i++) {
    allChapters[i].pagesBefore = prevPages;
    prevPages += allChapters[i].pages.length;
  }

  return allChapters;
}

export function orderChapters(
  chapters: ChapterContentType[],
  startIndex: number,
  endIndex: number
) {
  const [removed] = chapters.splice(startIndex, 1);
  chapters.splice(endIndex, 0, removed);

  return fixPageBeforeNumbers(chapters);
}

export function deleteChapter(
  chapters: ChapterContentType[],
  chapterId: string
) {
  return fixPageBeforeNumbers(chapters.filter((c) => c.id !== chapterId));
}

export function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    // eslint-disable-next-line
    var r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export function defaultLayout() {
  return {
    pageId: uuidv4(),
    layout: {
      blocks: [],
      canBeUsedOnFirstPage: true,
    },
    contentBlocks: [],
    canBeFirstPage: false,
    maxNumberOfPagesBefore: 0,
  };
}

export function createSheets(payload: ChapterContentType): ChapterPageType[] {
  return payload.pages.map((p) => {
    const findLayout = (p.id &&
      payload.layoutPages.find((l) => l.pageId === p.id)) as PageLayoutType;

    return {
      id: payload.id,
      diaryId: payload.diaryId,
      title: payload.title,
      layoutPage: findLayout || defaultLayout(),
      page: p,
      pagesBefore: payload.pagesBefore,
      sortOrder: payload.sortOrder,
    };
  });
}

export function recreateSheets(chapters: ChapterContentType[]) {
  let newChapters: ChapterPageType[] = [];
  for (let i = 0; i < chapters.length; i++) {
    newChapters = [...newChapters, ...createSheets(chapters[i])];
  }
  return newChapters;
}

export const getDateFormat = (
  format: DateFormatEnum = DateFormatEnum.American
): string => {
  if (format === DateFormatEnum.American) return 'MM/DD/YYYY';
  else if (format === DateFormatEnum.Dutch) return 'DD-MM-YYYY';
  else return 'DD-MM-YYYY';
};
