import React, { useState, useEffect, Dispatch, SetStateAction } from 'react';
import { H2, HelpParagraph } from 'components/Common/Text';
import { Upload } from 'antd';
import { mdiPlusCircle } from '@mdi/js';
import GetIcon from 'components/Common/Icon';
import ImgCrop from 'antd-img-crop';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
const newDiary = require(`assets/images/${process.env.REACT_APP_FILE_PREFIX}/new-diary.jpg`);

// Styled Components

const StepTwoWrap = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0 -2em 5em;

  @media (max-width: 768px) {
    flex-direction: column;
    flex-wrap: wrap;
    margin: 0;
  }

  ${({ isLoaded }) =>
    isLoaded ? { animation: 'fadeIn 0.5s linear' } : { opacity: 0 }}

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
`;

const StepTwoWrapItem = styled.div`
  width: 50%;
  padding: 2em;

  @media (max-width: 768px) {
    padding: 0;
    width: 100%;
  }

  &:first-of-type {
    padding: 1.5em;
  }

  &:last-of-type {
    padding-top: 3.5em;

    p {
      margin-bottom: 2em;
      font-weight: 300;
    }
  }
`;

const Image = styled.div`
  background: url('${({ src }) => src}');
  -ms-background-position-x: right;
  background-position: right;
  background-size: cover;
  width: 420px;
  height: 595px; // 420 / 411 * 583
  -webkit-box-shadow: rgb(227, 227, 227) 1em 1em 2em 0px;
  -moz-box-shadow: rgb(227, 227, 227) 1em 1em 2em 0px;
  box-shadow: rgb(227, 227, 227) 1em 1em 2em 0px;
  -webkit-border-radius: 1.5em;
  -moz-border-radius: 1.5em;
  border-radius: 1.5em;

  @media (max-width: 992px) {
    width: 330px;
    height: 468px; // 330 / 411 * 583
  }
`;

const ImageWrap = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  margin: 1em;
`;

const CardHeaderWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  .mdi-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    opacity: 0.9;
    --webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    cursor: pointer;
    color: ${({ theme }) => theme.primaryColor};
    font-size: 10em;
    -webkit-transition: 0.35s all ease-in-out;
    -moz-transition: 0.35s all ease-in-out;
    transition: 0.35s all ease-in-out;

    &:hover {
      -webkit-transform: translate(-50%, -50%) scale(0.9);
      -moz-transform: translate(-50%, -50%) scale(0.9);
      transform: translate(-50%, -50%) scale(0.9);
    }
  }
`;

// Types

type StepTwoType = {
  newCover: File | undefined;
  setNewCover: Dispatch<SetStateAction<File | undefined>>;
};

const { REACT_APP_PAGE_WIDTH, REACT_APP_PAGE_HEIGHT } = process.env as any;
const cropRatio = (2 * REACT_APP_PAGE_WIDTH) / REACT_APP_PAGE_HEIGHT;

const StepTwo: React.FC<StepTwoType> = ({ newCover, setNewCover }) => {
  const { t } = useTranslation();

  const [loaded, setLoaded] = useState<boolean>(false);
  const [displayImage, setDisplayImage] = useState<string>();

  useEffect(() => {
    setLoaded(true);
    if (newCover) setDisplayImage(URL.createObjectURL(newCover));
  }, [newCover]);

  const handleUpload = (file: any): boolean => {
    setNewCover(file);
    setDisplayImage(URL.createObjectURL(file));
    return true;
  };

  return (
    <>
      <StepTwoWrap isLoaded={loaded}>
        <StepTwoWrapItem>
          <ImageWrap>
            <CardHeaderWrap>
              <ImgCrop
                aspect={cropRatio}
                quality={1}
                modalTitle={t('cropCover')}
                modalWidth='40%'
                modalOk={t('save')}
                modalCancel={t('cancel')}
              >
                <Upload
                  customRequest={() => {}}
                  showUploadList={false}
                  beforeUpload={handleUpload}
                  accept='image/*'
                >
                  <GetIcon path={mdiPlusCircle} />
                  <Image src={displayImage || newDiary} alt='Add cover image' />
                </Upload>
              </ImgCrop>
            </CardHeaderWrap>
            {/* {isCoverLoading && <CoverLoader />} */}
          </ImageWrap>
        </StepTwoWrapItem>
        <StepTwoWrapItem>
          <H2>{t('coverOfYourDiary')}</H2>
          <HelpParagraph>{t('coverText')}</HelpParagraph>
        </StepTwoWrapItem>
      </StepTwoWrap>
    </>
  );
};

export default StepTwo;
