import {
    PUBLISH_DIARY_START_SAGA,
    UNPUBLISH_DIARY_START_SAGA,
    OPEN_SHARE_MODAL,
    CLOSE_SHARE_MODAL,
    OPEN_EMAIL_SECTION,
    CLOSE_EMAIL_SECTION,
    SHARE_BY_EMAIL_START_SAGA,
    GET_SHAREABLE_EMAILS_START_SAGA,
    DELETE_SHARED_EMAIL_START_SAGA,
    RESET_SHARE_MESSAGE,
} from "store/actions/share-actions/share-action-types";
import { DiaryType } from "lib/types";

//send string if you want certain chapter to be selected by default
export const openShareModal = (chapterId:string|boolean = true) => {
    return { type: OPEN_SHARE_MODAL, payload: chapterId };
};
export const closeShareModal = () => {
    return { type: CLOSE_SHARE_MODAL };
};
export const resetShareMessage = () => {
    return { type: RESET_SHARE_MESSAGE };
};
export const openEmailSection = (sharedId: string) => {
    return { type: OPEN_EMAIL_SECTION, payload: sharedId};
};
export const closeEmailSection = () => {
    return { type: CLOSE_EMAIL_SECTION };
};
export const shareByEmail = (
    diaryId: string,
    data: {
        language: string,
        message: string,
        emailAddresses: string[]
    },
    chapterId?: string
) => {
    return { type: SHARE_BY_EMAIL_START_SAGA, payload: { diaryId, chapterId, data } };
};

export const publishDiary = (diary: DiaryType) => {
    return { type: PUBLISH_DIARY_START_SAGA, payload: diary };
};
export const unpublishDiary = (diary: DiaryType) => {
    return { type: UNPUBLISH_DIARY_START_SAGA, payload: diary };
};

export const getSharableEmails = (diaryId: string) => {
    return { type: GET_SHAREABLE_EMAILS_START_SAGA, payload: diaryId };
};

export const deleteSharableEmail = (email: string | number [] | boolean [], diaryId: string) => {
    return { type: DELETE_SHARED_EMAIL_START_SAGA, payload: { diaryId, email } };
};