import { put, takeLatest, all, call, delay } from 'redux-saga/effects';
import {
  PUBLISH_DIARY_START_SAGA,
  UNPUBLISH_DIARY_START_SAGA,
  PUBLISH_DIARY_SUCCESS,
  PUBLISH_DIARY_ERROR,
  UNPUBLISH_DIARY_SUCCESS,
  UNPUBLISH_DIARY_ERROR,
  SHARE_BY_EMAIL_START_SAGA,
  SHARE_BY_EMAIL_START,
  SHARE_BY_EMAIL_ERROR,
  SHARE_BY_EMAIL_SUCCESS,
  CLOSE_EMAIL_SECTION,
  CLOSE_SHARE_MODAL,
  GET_SHAREABLE_EMAILS_START_SAGA,
  GET_SHAREABLE_EMAILS_SUCCESS,
  GET_SHAREABLE_EMAILS_ERROR,
  IUnpublishDiaryStartSagaAction,
  IEmailShareStartSagaAction,
  IPublishDiaryStartSagaAction,
  IGetSharableEmailsStartSagaAction,
  DELETE_SHARED_EMAIL_START_SAGA,
  DELETE_SHARED_EMAIL_SUCCESS,
  DELETE_SHARED_EMAIL_ERROR,
  IDeleteSharedEmailStartSagaAction,
  PUBLISH_DIARY_START,
  UNPUBLISH_DIARY_START,
} from 'store/actions/share-actions/share-action-types';
import {
  PUBLISH_GLOBAL_DIARY,
  UNPUBLISH_GLOBAL_DIARY,
} from 'store/actions/diary-actions/diary-action-types';
import {
  PUBLISH_ALL_CHAPTERS,
  UNPUBLISH_ALL_CHAPTERS,
} from 'store/actions/chapter-actions/chapter-action-types';
import axios from 'axios';

// Sagas

export function* publishDiary(action: IPublishDiaryStartSagaAction) {
  try {
    yield put({ type: PUBLISH_DIARY_START });
    yield call(axios.put, `v1/me/diaries/${action.payload!.id}/publish`, {
      ...action.payload,
      isPublic: true,
    });
    yield put({ type: PUBLISH_DIARY_SUCCESS });
    yield put({ type: PUBLISH_GLOBAL_DIARY });
    yield put({ type: PUBLISH_ALL_CHAPTERS });
  } catch (ex) {
    yield put({ type: PUBLISH_DIARY_ERROR });
  }
}

export function* unpublishDiary(action: IUnpublishDiaryStartSagaAction) {
  try {
    yield put({ type: UNPUBLISH_DIARY_START });
    yield call(axios.put, `v1/me/diaries/${action.payload!.id}/unpublish`, {
      ...action.payload,
      isPublic: false,
    });
    yield put({ type: UNPUBLISH_DIARY_SUCCESS });
    yield put({
      type: UNPUBLISH_GLOBAL_DIARY,
      payload: { diaryId: action.payload!.id },
    });
    yield put({ type: UNPUBLISH_ALL_CHAPTERS });
  } catch (ex) {
    yield put({ type: UNPUBLISH_DIARY_ERROR });
  }
}

export function* shareByEmail(action: IEmailShareStartSagaAction) {
  try {
    yield put({ type: SHARE_BY_EMAIL_START });

    if (action.payload.chapterId)
      yield call(
        axios.put,
        `v1/me/diaries/${action.payload.diaryId}/chapters/${action.payload.chapterId}/share-by-mail`,
        {
          ...action.payload.data,
        }
      );
    else
      yield call(
        axios.put,
        `v1/me/diaries-sharing/${action.payload.diaryId}/share-by-mail`,
        {
          ...action.payload.data,
        }
      );

    yield put({ type: CLOSE_SHARE_MODAL });
    yield delay(500);
    yield put({ type: SHARE_BY_EMAIL_SUCCESS });
    yield put({ type: CLOSE_EMAIL_SECTION });
  } catch (ex) {
    yield put({ type: CLOSE_SHARE_MODAL });
    yield delay(500);
    yield put({ type: SHARE_BY_EMAIL_ERROR });
    yield put({ type: CLOSE_EMAIL_SECTION });
  }
}

export function* getSharableEmails(action: IGetSharableEmailsStartSagaAction) {
  const PAGE_SIZE = 50;
  let index = 1;
  let shouldFetch = true;
  let total = 0;
  let mails: string[] = [];

  try {
    while (shouldFetch) {
      const {
        data: { data, meta },
      } = yield call(
        axios.get,
        `v1/me/diaries-sharing/${action.payload}/shared-with?page=${index}&&size=${PAGE_SIZE}`
      );

      if (data && Array.isArray(data)) {
        mails = [...mails, ...data];
      }
      total = PAGE_SIZE * index;
      if (total >= meta.totalCount) {
        shouldFetch = false;
      }
      index++;
    }

    yield put({
      type: GET_SHAREABLE_EMAILS_SUCCESS,
      payload: mails || [],
    });
  } catch (ex) {
    yield put({ type: GET_SHAREABLE_EMAILS_ERROR });
  }
}

export function* deleteSharedEmail(action: IDeleteSharedEmailStartSagaAction) {
  try {
    yield call(
      axios.delete,
      `v1/me/diaries-sharing/${action.payload.diaryId}/removesharedemail/${action.payload.email}`
    );
    yield put({
      type: DELETE_SHARED_EMAIL_SUCCESS,
      payload: action.payload.email,
    });
  } catch (ex) {
    yield put({ type: DELETE_SHARED_EMAIL_ERROR });
  }
}

// Watchers
export function* watchPublishDiary() {
  yield takeLatest(PUBLISH_DIARY_START_SAGA, publishDiary);
}
export function* watchUnpublishDiary() {
  yield takeLatest(UNPUBLISH_DIARY_START_SAGA, unpublishDiary);
}
export function* watchShareDiaryEmails() {
  yield takeLatest(SHARE_BY_EMAIL_START_SAGA, shareByEmail);
}
export function* watchGetSharableEmails() {
  yield takeLatest(GET_SHAREABLE_EMAILS_START_SAGA, getSharableEmails);
}
export function* watchDeleteSharedEmail() {
  yield takeLatest(DELETE_SHARED_EMAIL_START_SAGA, deleteSharedEmail);
}

export default function* allMyDiariesSagas() {
  yield all([
    watchPublishDiary(),
    watchUnpublishDiary(),
    watchShareDiaryEmails(),
    watchGetSharableEmails(),
    watchDeleteSharedEmail(),
  ]);
}
