import React from "react";
import { Steps } from "antd";
import { useTranslation } from 'react-i18next';

const { Step } = Steps;

type StepType = {
	currentStep: number;
};

const Stepper: React.FC<StepType> = ({ currentStep }) => {
	const { t } = useTranslation();
	return (
		<Steps current={currentStep - 1}>
			<Step title={t("addTitle")} />
			<Step title={t("addCover")} />
			<Step title={t("chooseStyle")} />
		</Steps>
	);
};

export default Stepper;
