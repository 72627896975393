import {
  PointsDataType,
  RouteMapDataType,
  LinestringType,
  SimpleMapDataType,
} from "lib/types";
import { getNumberOfPixelsPerPointPrint } from "util/imageUtils";

function makeid(length) {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export const convertToRouteMap = (data: RouteMapDataType): PointsDataType => {
  const locations = data.locations.map((location, index) => {
    return {
      type: "Feature",
      properties: {
        id: makeid(16),
        isFirstOrLast: index === 0 || index === data.locations.length - 1,
        city: location.city,
        country: location.country,
      },
      geometry: {
        type: "Point",
        coordinates: [location.position.longitude, location.position.latitude],
      },
      pixelPosition: location.pixelPosition,
    };
  });

  return {
    type: "FeatureCollection",
    locations,
    zoomLevel: data.zoomLevel,
    position: data.position,
  };
};

export const revertRouteMap = (
  data: PointsDataType,
  imageId: string
): RouteMapDataType => {
  const locations: RouteMapDataType["locations"] = data.locations.map(
    (location) => {
      return {
        position: {
          longitude: location.geometry.coordinates[0] || 0,
          latitude: location.geometry.coordinates[1] || 0,
        },
        pixelPosition: location.pixelPosition || { x: 0, y: 0 },
        city: location.properties.city || "",
        country: location.properties.country || "",
      };
    }
  );

  return {
    zoomLevel: data.zoomLevel,
    position: data.position,
    locations,
    imageId,
  };
};

export const revertSimpleMap = (
  data: PointsDataType,
  imageId: string
): SimpleMapDataType => {
  const locations: SimpleMapDataType["markers"] = data.locations.map(
    (location) => {
      return {
        longitude: location.geometry.coordinates[0] || 0,
        latitude: location.geometry.coordinates[1] || 0,
      };
    }
  );

  return {
    zoomLevel: data.zoomLevel,
    position: data.position,
    markers: locations,
    imageId,
  };
};

export const convertToSimpleMap = (data: SimpleMapDataType): PointsDataType => {
  const { position, zoomLevel } = data;
  const locations = data.markers.map((marker) => {
    return {
      type: "Feature",
      properties: {
        id: makeid(16),
        isFirstOrLast: false,
        city: "",
        country: "",
      },
      geometry: {
        type: "Point",
        coordinates: [marker.longitude, marker.latitude],
      },
      pixelPosition: { x: data.position[0], y: data.position[1] },
    };
  });
  // todo: probably we need to add linestring

  return {
    type: "FeatureCollection",
    locations,
    zoomLevel,
    position,
  };
};

export const updateFirstAndLastPoint = (
  points: PointsDataType
): PointsDataType => {
  points.locations.forEach((feature, index) => {
    if (index === 0 || index === points.locations.length - 1) {
      feature.properties.isFirstOrLast = true;
    } else {
      feature.properties.isFirstOrLast = false;
    }
  });
  return points;
};

export const handleLineStrings = (isRoute: boolean, data: PointsDataType) => {
  if (isRoute && data.locations.length > 1) {
    let lineString: LinestringType = {
      type: "Feature",
      geometry: {
        type: "LineString",
        coordinates: [],
      },
      properties: {},
    };
    lineString.geometry.coordinates = data.locations.map(
      (point) => point.geometry.coordinates
    );
    data.locations = data.locations.filter(
      (feature) => feature.geometry.type !== "LineString"
    );
    data.locations.push(lineString);
  }
  return data;
};

export const mapAdapter = (
  data: PointsDataType,
  isRoute: boolean
): GeoJSON.FeatureCollection<GeoJSON.Geometry> => {
  if (isRoute) {
    data = handleLineStrings(isRoute, data);
  }

  return {
    type: "FeatureCollection",
    features: data.locations.map((f) => {
      return {
        type: "Feature",
        geometry: {
          type: f.geometry.type as any,
          coordinates: f.geometry.coordinates,
        },
        coordinates: [f.geometry.coordinates[0], f.geometry.coordinates[1]],
        id: f.properties.id,
        properties: f.properties,
      };
    }),
  };
};

export const getMapboxClickData = (features) => {
  const clickedPointData = {
    region: "",
    place: "",
    country: "",
  };

  features.forEach((feature) => {
    clickedPointData[feature.place_type[0]] = feature.place_name;
  });

  if (clickedPointData.place) {
    const [town = "", city, country] = clickedPointData.place.split(",");
    const place = town.trim() !== city.trim() ? `${town} - ${city}` : town;
    return { city: place, country };
  } else if (clickedPointData.region) {
    const [city = "", country] = clickedPointData.region.split(",");
    return { city, country };
  } else if (clickedPointData.country) {
    const [city = "", country] = clickedPointData.country.split(",");
    return { city, country };
  } else return { city: "", country: "" };
};

export const getMapSizeInPixels = (size) => {
  const ppp = getNumberOfPixelsPerPointPrint();
  return {
    width: Math.round(size.width * ppp),
    height: Math.round(size.height * ppp),
  };
};

export const getMapBase64 = (map) => {
  Object.defineProperty(window, "devicePixelRatio", {
    get: function () {
      return 3;
    },
  });

  return map.getCanvas().toDataURL();
};
