import { repeater } from '../../util/helpers';

type CioEvents = {
  'Diary Started': null;
  'Title Added': { diary_title: string; period: string };
  'Cover Photo Added': null;
  'Style Chosen': { style: string };
  'New Chapter Clicked': { diary_title: string };
  'Edit Cover Clicked': { diary_title: string };
  'Styles Clicked': { diary_title: string };
  'View Blog Clicked': { diary_title: string };
  'Share Clicked': { diary_title: string };
  'Download PDF Clicked': { diary_title: string };
  'Preview Clicked': { diary_title: string };
  'Order Clicked': { diary_title: string };
  'New Chapter Saved': {
    diary_title: string;
    chapter_title: string;
    location: string;
    date: number;
    text: string;
    is_public: boolean;
  };
  'Order Started': null;
};

type CioEventName = keyof CioEvents;

type CioEventData = {
  [eventName in CioEventName]: CioEvents[eventName];
};

const track = <T extends CioEventName>(eventName: T, data: CioEventData[T]) => {
  if (!_track(eventName, data)) {
    repeater(() => _track(eventName, data), 3, 3);
  }
};

const _track = <T extends CioEventName>(
  eventName: T,
  data: CioEventData[T]
) => {
  const cio = window._cio;

  if (!!cio) {
    cio.track(eventName, data);
    return true;
  } else {
    return false;
  }
};

const identify = (id: string) => {
  if (!_identify(id)) {
    repeater(() => _identify(id), 3, 3);
  }
};

const _identify = (id: string) => {
  const cio = window._cio;

  if (!!cio) {
    cio.identify({ id });
    return true;
  } else {
    return false;
  }
};

export { identify, track };
