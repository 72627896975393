import enUS from 'antd/es/locale/en_US';
import nlNL from 'antd/es/locale/nl_NL';
import esEs from 'antd/es/locale/es_ES';
import 'moment/locale/es';
import 'moment/locale/nl';

const languages = ['en', 'nl', 'es'];

const routeLangs = 'en|nl|es';

const languageSelect = {
  EN: 'English',
  NL: 'Nederlands',
  ES: 'Español',
};

const languageRegions = {
  en: 'en-US',
  nl: 'nl-NL',
  es: 'es-ES',
};

const getAndtLocale = (lang: string) => {
  switch (lang) {
    case 'nl':
      return nlNL;
    case 'es':
      return esEs;
    default:
      return enUS;
  }
};

export {
  getAndtLocale,
  languages,
  routeLangs,
  languageSelect,
  languageRegions,
};
