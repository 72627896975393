import React, { ReactElement, useEffect, useRef, useState } from 'react';
import {
  Editor,
  RichUtils,
  convertFromHTML,
  ContentState,
  EditorState,
  getDefaultKeyBinding,
} from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import { mdiFormatBold, mdiFormatItalic } from '@mdi/js';
import GetIcon from 'components/Common/Icon';
import { Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import * as S from './styles';

type Styles = 'BOLD' | 'ITALIC';
type StyleButtonProps = {
  editorState: EditorState;
  changeStyle: any;
  disabled;
};
type Props = {
  editorState: EditorState;
  setEditorState: (editorState: EditorState) => void;
  hasBoldAndItalic: boolean;
};

const RichEditor: React.FC<Props> = ({
  editorState,
  setEditorState,
  hasBoldAndItalic,
}): ReactElement => {
  const { t } = useTranslation();
  const rootRef = useRef<any>(null);
  const editorRef = useRef<any>(null);
  const [isScrollable, setIsScrollable] = useState<boolean>(false);

  useEffect(() => {
    if (rootRef && rootRef.current && isScrollable) {
      //increase scrollTop value by lineHeight value of the container
      rootRef.current.scrollTop =
        rootRef.current.scrollTop +
        parseFloat(getComputedStyle(rootRef.current).lineHeight);
      setIsScrollable(false);
    }
  }, [isScrollable]);

  const keyBindingFn = (e: any) => {
    if (e.key === 'Enter') {
      return 'enter';
    }
    return getDefaultKeyBinding(e);
  };

  const scrollToNewLine = () => {
    if (rootRef.current && editorRef.current) {
      const rootHeight = rootRef.current.offsetHeight - 32;
      const editorHeight = editorRef.current.editor.firstChild.offsetHeight;
      if (editorHeight > rootHeight) {
        setIsScrollable(true);
      }
    }
  };

  const handleKeyCommand = (command) => {
    if (command === 'enter') {
      const next = RichUtils.insertSoftNewline(editorState);
      setEditorState(next);
      scrollToNewLine();
      return 'handled';
    }
    return 'not-handled';
  };

  return (
    <>
      <S.RichEditorRoot ref={rootRef}>
        <Editor
          keyBindingFn={keyBindingFn}
          handleKeyCommand={handleKeyCommand}
          placeholder={t('tellYourStory')}
          stripPastedStyles={true}
          editorState={editorState}
          onChange={setEditorState}
          ref={editorRef}
          spellCheck={true}
        />
      </S.RichEditorRoot>
      <S.RichEditorControls>
        <Tooltip
          placement='bottom'
          title={!hasBoldAndItalic ? t('doesntSupportBoldAndItalic') : null}
        >
          <span>
            <StyleButtons
              editorState={editorState}
              changeStyle={setEditorState}
              disabled={!hasBoldAndItalic}
            />
          </span>
        </Tooltip>
      </S.RichEditorControls>
    </>
  );
};

export default RichEditor;

//* FUNCTIONS ///

const listStyles: { style: Styles; icon: any }[] = [
  { style: 'BOLD', icon: <GetIcon path={mdiFormatBold} className='large' /> },
  {
    style: 'ITALIC',
    icon: <GetIcon path={mdiFormatItalic} className='large' />,
  },
];

function styleText(
  e: any,
  text: Styles,
  editorState: EditorState,
  changeStyle: any
) {
  // onMouseDown and e.preventDefault because editor losses focus if you use onClick
  e.preventDefault();
  let nextState = RichUtils.toggleInlineStyle(editorState, text);
  changeStyle(nextState);
}

export const StyleButtons = ({
  editorState,
  changeStyle,
  disabled,
}: StyleButtonProps): any => {
  const renderButton = (s: { style: Styles; icon: any }) => {
    const currentStyle = editorState.getCurrentInlineStyle();
    const active = currentStyle.has(s.style);
    let className = 'styleButton';
    if (disabled) {
      className += ' disabledButton';
    } else if (active) {
      className += ' activeButton';
    }
    return (
      <span
        className={className}
        key={s.style}
        onMouseDown={(e) =>
          !disabled ? styleText(e, s.style, editorState, changeStyle) : null
        }
      >
        {s.icon}
      </span>
    );
  };

  return listStyles.map((s) => renderButton(s));
};

export const importContent = (text: string) => {
  const blocksFromHTML = convertFromHTML(text);
  return ContentState.createFromBlockArray(
    blocksFromHTML.contentBlocks,
    blocksFromHTML.entityMap
  );
};

export const exportContentHtml = (editorState: EditorState) => {
  // decodes html entities
  const html = stateToHTML(editorState.getCurrentContent());
  const textArea = document.createElement('textarea');
  textArea.innerHTML = html;

  return (
    textArea.value
      // First <p> is wrapper, Removes the first <p> tag.
      .replace(/<p>/, '')
      // Replaces all <p><br/></p> or just <p> or <br/> tags with the string "[[[br/]]]".
      .replace(/(<p><br\/?><\/p>)|<p>|<br\/?>/g, '[[[br/]]]')
      // Replaces all <strong> or <b> tags with "[[[b]]]".
      .replace(/<(\/?)(strong|b)>/g, '[[[$1b]]]')
      // Replaces all <em> or <i> tags with "[[[i]]]".
      .replace(/<(\/?)(em|i)>/g, '[[[$1i]]]')
      // Removes all line breaks and </p> tags.
      .replace(/(?:\r\n|\r|\n|<\/p>)/g, '')
  );
};
