import React, { ReactElement, useState } from "react";
import { useSelector } from "react-redux";
import SidebarGroupWrap from "./SidebarGroups/SidebarGroupWrap";
import styled from "styled-components/macro";
import { AppState } from "store/reducers/rootReducer";

// Styled Components
const SideBarActionsWrap = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	flex: 2;
	padding-top: 10px;
	flex: 0 0 30em;
	height: 80vh;
	position: relative;
	z-index: 8;

	@media (max-width: 992px) {
		height: 600px;
		margin: 1em auto;
		width: 70%;
	}
`;

const SidebarActionInnerWrap = styled.div`
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 25px;
	background: white;
	z-index: 10;
	-webkit-transition: 0.2s all ease-in-out;
	-moz-transition: 0.2s all ease-in-out;
	transition: 0.2s all ease-in-out;
	transition-delay: 0.2s;
	-webkit-transform: translateX(-100vw);
	-moz-transform: translateX(-100vw);
	transform: translateX(-100vw);
	-webkit-box-shadow: rgba(33, 33, 33, 0.1) 0px 0px 11px;
	-moz-box-shadow: rgba(33, 33, 33, 0.1) 0px 0px 11px;
	box-shadow: rgba(33, 33, 33, 0.1) 0px 0px 11px;
	-webkit-border-top-right-radius: 1em;
	-moz-border-top-right-radius: 1em;
	border-top-right-radius: 1em;
	-webkit-border-bottom-right-radius: 1em;
	-moz-border-bottom-right-radius: 1em;
	border-bottom-right-radius: 1em;
	border: 1px solid #ededed;
	overflow: hidden;

	@media (max-width: 992px) {
		height: 400px;
	}

	transform: translate(0)
`;

const SidebarActions: React.FC = (): ReactElement => {
	const { layoutChange } = useSelector(({ chapters }: AppState) => chapters);
	const [itemActive, setItemActive] = useState<number>(0);
	React.useEffect(() => {
		if (layoutChange) setItemActive(2)
		else setItemActive(1)
	}, [layoutChange])

	return (
		<SideBarActionsWrap>
			<SidebarActionInnerWrap>
				<SidebarGroupWrap
					currentItem={itemActive}
				/>
			</SidebarActionInnerWrap>
		</SideBarActionsWrap>
	);
};

export default SidebarActions;
