import React, { useState, Dispatch, SetStateAction, useEffect } from 'react';
import Pagination from 'components/Pagination';
import { useSelector, useDispatch } from 'react-redux';
import { AppState } from 'store/reducers/rootReducer';
import StepOne from 'views/create/steps/StepOne';
import StepTwo from 'views/create/steps/StepTwo';
import StepThree from 'views/create/steps/StepThree';
import styled from 'styled-components/macro';
import { createDiary } from 'store/actions/diary-actions';
import { track } from 'hoc/CustomerIO';

const dummyCover = new File([''], 'dummy.jpg', { type: 'image/jpg' });

const StepViewWrap = styled.div`
  padding: 6em 0 6.5em;

  @media (max-width: 768px) {
    padding: 2.5em 0 3em;
  }
`;

interface StepViewProps {
  currentView: number;
  setCurrentStep: Dispatch<SetStateAction<number>>;
  voucherId: string;
}

const StepView: React.FC<StepViewProps> = ({
  currentView,
  setCurrentStep,
  voucherId,
}) => {
  const [newDiary, setNewDiary] = useState({ name: '', period: '' });
  const [newCover, setNewCover] = useState<File | undefined>();
  const [newStyle, setNewStyle] = useState<string | undefined>();
  const dispatch = useDispatch();
  const { diaryStyles, createdDiaryId } = useSelector(
    ({ diary }: AppState) => diary
  );
  const { user } = useSelector(({ user }: AppState) => user);

  useEffect(() => {
    switch (currentView) {
      case 1:
        track('Diary Started', null);
        break;
      case 2:
        track('Title Added', {
          diary_title: newDiary.name,
          period: newDiary.period,
        });
        break;
      case 3:
        track('Cover Photo Added', null);
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentView]);

  const renderSteps = () => {
    switch (currentView) {
      case 1:
      default:
        return <StepOne newDiary={newDiary} setNewDiary={setNewDiary} />;
      case 2:
        return <StepTwo newCover={newCover} setNewCover={setNewCover} />;
      case 3:
        return (
          <StepThree
            newStyle={newStyle}
            setNewStyle={setNewStyle}
            diaryStyles={diaryStyles || []}
          />
        );
    }
  };

  const handleCreateDiary = () => {
    const findStyle = diaryStyles.find((ds) => ds?.displayName === newStyle);
    if (!findStyle) {
      console.error('ERROR >> NO STYLE FOUND >> WHAT TO DO');
      return;
    }
    track('Style Chosen', { style: findStyle.displayName });

    dispatch(
      createDiary(
        { ...newDiary, style: findStyle },
        newCover ?? dummyCover,
        user,
        voucherId ?? undefined
      )
    );
  };

  const handleNextDisabled =
    newDiary.name.length < 1 ||
    // WARNING! Below line commented to allow creating a diary without cover image
    // (currentView > 1 && (!newCover || !newCover.type.includes("image"))) ||
    (currentView > 2 && !newStyle) ||
    !!createdDiaryId;

  return (
    <StepViewWrap>
      {renderSteps()}
      <Pagination
        currentView={currentView}
        clicked={setCurrentStep}
        saveDiary={handleCreateDiary}
        nextDisabled={handleNextDisabled}
      />
    </StepViewWrap>
  );
};

export default StepView;
