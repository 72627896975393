import { AccountProps, UserType } from "lib/types";

export const SET_USER = "SET_USER";

export interface ISetUserAction {
	type: typeof SET_USER;
	payload: {user : UserType , account: AccountProps }
}

export type UserActionTypes = ISetUserAction
