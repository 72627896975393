import {
  OPEN_SHARE_MODAL,
  CLOSE_SHARE_MODAL,
  OPEN_EMAIL_SECTION,
  CLOSE_EMAIL_SECTION,
  SHARE_BY_EMAIL_START,
  SHARE_BY_EMAIL_SUCCESS,
  SHARE_BY_EMAIL_ERROR,
  PUBLISH_DIARY_ERROR,
  PUBLISH_DIARY_SUCCESS,
  PUBLISH_DIARY_START,
  UNPUBLISH_DIARY_START,
  UNPUBLISH_DIARY_ERROR,
  UNPUBLISH_DIARY_SUCCESS,
  RESET_SHARE_MESSAGE,
  ShareActionTypes,
  GET_SHAREABLE_EMAILS_START_SAGA,
  GET_SHAREABLE_EMAILS_SUCCESS,
  GET_SHAREABLE_EMAILS_ERROR,
  DELETE_SHARED_EMAIL_START_SAGA,
  DELETE_SHARED_EMAIL_SUCCESS,
  DELETE_SHARED_EMAIL_ERROR,
} from 'store/actions/share-actions/share-action-types';
import { DiaryType } from 'lib/types';
import i18n from 'i18n';

interface IInitialState {
  data: DiaryType | null;
  showModal: string | boolean;
  showEmailSection: boolean;
  isEmailSending: boolean;
  availableEmailsToShare: string[];
  isSharableEmailsLoading: boolean;
  sharedId: string;
  isShareing: boolean;
  shareMessage: {
    content: string;
    type: string;
  };
}

const initialState: IInitialState = {
  data: null,
  showModal: false,
  showEmailSection: false,
  isEmailSending: false,
  isSharableEmailsLoading: false,
  availableEmailsToShare: [],
  sharedId: '',
  isShareing: false,
  shareMessage: {
    content: '',
    type: '',
  },
};

const changePublished = (data: DiaryType | null, status: boolean) => {
  if (!data) return null;
  let item = { ...data };
  item.isPublic = status;
  return item;
};

const ShareReducer = (
  state = initialState,
  action: ShareActionTypes
): IInitialState => {
  switch (action.type) {
    case PUBLISH_DIARY_SUCCESS:
      return {
        ...state,
        data: changePublished(state.data, true),
        isShareing: false,
      };
    case PUBLISH_DIARY_START:
      return {
        ...state,
        data: changePublished(state.data, true),
        isShareing: true,
      };
    case PUBLISH_DIARY_ERROR:
      return {
        ...state,
        shareMessage: {
          content: i18n.t('failedToPublishDiary'),
          type: 'error',
        },
        isShareing: false,
      };
    case UNPUBLISH_DIARY_START:
      return {
        ...state,
        data: changePublished(state.data, true),
        isShareing: true,
      };
    case UNPUBLISH_DIARY_SUCCESS:
      return {
        ...state,
        data: changePublished(state.data, false),
        isShareing: false,
      };
    case UNPUBLISH_DIARY_ERROR:
      return {
        ...state,
        shareMessage: {
          content: i18n.t('failedToMakeTheDiaryPrivate'),
          type: 'error',
        },
        isShareing: false,
      };
    case RESET_SHARE_MESSAGE:
      return {
        ...state,
        shareMessage: {
          content: '',
          type: '',
        },
      };
    case OPEN_SHARE_MODAL:
      return {
        ...state,
        showModal: action.payload,
        showEmailSection: false,
      };
    case CLOSE_SHARE_MODAL:
      return {
        ...state,
        data: null,
        showModal: false,
        showEmailSection: false,
        sharedId: '',
      };
    case OPEN_EMAIL_SECTION:
      return {
        ...state,
        showEmailSection: true,
        sharedId: action.payload,
      };
    case CLOSE_EMAIL_SECTION:
      return {
        ...state,
        showEmailSection: false,
        sharedId: '',
      };
    case SHARE_BY_EMAIL_START:
      return {
        ...state,
        isEmailSending: true,
      };
    case SHARE_BY_EMAIL_SUCCESS:
      return {
        ...state,
        isEmailSending: false,
        shareMessage: {
          content: i18n.t('emailMessageWasSentSuccessfully'),
          type: 'success',
        },
        sharedId: '',
      };
    case SHARE_BY_EMAIL_ERROR:
      return {
        ...state,
        isEmailSending: false,
        shareMessage: {
          content: i18n.t('emailSharingFailed'),
          type: 'error',
        },
      };
    case GET_SHAREABLE_EMAILS_START_SAGA:
      return {
        ...state,
        isSharableEmailsLoading: true,
      };
    case GET_SHAREABLE_EMAILS_SUCCESS:
      return {
        ...state,
        availableEmailsToShare: action.payload,
        isSharableEmailsLoading: false,
      };
    case GET_SHAREABLE_EMAILS_ERROR:
      return {
        ...state,
        isSharableEmailsLoading: false,
        shareMessage: {
          content: i18n.t('gettingPreviouslySharedEmailAddressesFailed'),
          type: 'error',
        },
      };
    case DELETE_SHARED_EMAIL_START_SAGA:
      return {
        ...state,
        isSharableEmailsLoading: true,
      };
    case DELETE_SHARED_EMAIL_SUCCESS:
      return {
        ...state,
        isSharableEmailsLoading: false,
        availableEmailsToShare: [
          ...state.availableEmailsToShare.filter(
            (mail) => mail !== action.payload
          ),
        ],
      };
    case DELETE_SHARED_EMAIL_ERROR:
      return {
        ...state,
        isSharableEmailsLoading: false,
        shareMessage: {
          content: i18n.t('deleteEmailAdressesFailed'),
          type: 'error',
        },
      };
    default:
      return state;
  }
};

export default ShareReducer;
