import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { Button } from 'antd';
import { mdiChevronLeft, mdiChevronRight } from '@mdi/js';
import GetIcon from 'components/Common/Icon';
import { setPageNumber } from 'store/actions/chapter-actions';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'store/reducers/rootReducer';

const ButtonWrap = styled.div`
	display:flex;
	align-items:center;
	justify-content:space-between;
	position:absolute;
	bottom:50%;
	left -60px;
	right:-60px;
	color:${({ theme }) => theme.textColor};

	button {
		display:inline-block;
		height: 50px;
		width: 50px;
		color:${({ theme }) => theme.primaryHover};
		border: none;
		box-shadow: rgba(33, 33, 33, 0.15) 0px 0px 11px;

		.mdi-icon{
			vertical-align: middle;
			width: 30px;
		}

		&:hover {
			color:${({ theme }) => theme.whiteColor};
			background-color: ${({ theme }) => theme.primaryHover};
		}

		&:active,&:focus {
			color: ${({ theme }) => theme.whiteColor};
			background-color: ${({ theme }) => theme.primaryHover};
			border-color: lightgray;
		}
	}
`;

const NavigationArrows: React.FC = (): ReactElement | null => {
  const dispatch = useDispatch();
  const { sheets, pageNumber } = useSelector(
    ({ chapters }: AppState) => chapters
  );

  return (
    <ButtonWrap>
      <Button
        shape="circle"
        icon={<GetIcon path={mdiChevronLeft} />}
        onClick={() => dispatch(setPageNumber(pageNumber - 2))}
        disabled={pageNumber < 1}
      />
      <Button
        shape="circle"
        icon={<GetIcon path={mdiChevronRight} />}
        onClick={() => dispatch(setPageNumber(pageNumber + 2))}
        disabled={!sheets.length || sheets.length <= pageNumber + 2}
      />
    </ButtonWrap>
  );
};

export default NavigationArrows;
