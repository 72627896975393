import Konva from 'konva';
import { Position } from 'lib/types'

enum Style {
    normal = 'normal',
    bold = 'bold',
    italic = 'italic',
    boldItalic = 'italic bold',
}
type TextObj = {
    name: string | undefined;
    text: string;
    x: number;
    y: number;
    fontStyle: string;
    fontFamily: string;
    fontSize: number;
}

export default (text: string, position: Position, font: { fontFamily: string, fontSize: number }) => {
    let { x, y } = position
    const { fontFamily, fontSize } = font
    //! One fontSize is a vertical margin recevied from the legacy code
    y = y - fontSize
    const textSplits = text.split(/(<b>|<i>)/)
    const textParts: TextObj[] = []

    if (textSplits.length < 2) {
        textParts.push({ name: "1", text, x, y: y, fontFamily, fontSize, fontStyle: Style.normal })
        return textParts;
    }

    let shifter = 0;
    let fontStyle = Style.normal

    const createText = (txt: string) => {
        const newX = x + shifter
        const content = new Konva.Text({
            x: newX,
            y: y,
            text: txt,
            fontSize,
            fontFamily,
            fontStyle
        })
        textParts.push({ name: "" + textParts.length, text: txt, x: newX, y, fontFamily, fontSize, fontStyle })
        shifter += content.getTextWidth()
    }

    const switchStyle = (tag: string) => {
        switch (fontStyle) {
            case Style.normal: {
                if (tag === '<b>') fontStyle = Style.bold
                else fontStyle = Style.italic
                break
            }
            case Style.bold: {
                if (tag === '<b>') fontStyle = Style.normal
                else fontStyle = Style.boldItalic
                break
            }
            case Style.italic: {
                if (tag === '<i>') fontStyle = Style.normal
                else fontStyle = Style.boldItalic
                break
            }
            case Style.boldItalic: {
                if (tag === '<i>') fontStyle = Style.bold
                else fontStyle = Style.italic
                break
            }
            default: console.warn("Unknown style ", fontStyle)
        }
    }

    for (let i = 0; i < textSplits.length; i++) {
        if (i % 2 !== 0) switchStyle(textSplits[i])
        else if (textSplits[i]) createText(textSplits[i])
    }

    return textParts;
}