import { useEffect, useState } from "react";
import axios from 'axios'
import SagaUtils from "store/sagas/diary-sagas/sagaUtils";
import { getNumberOfPixelsPerPointPrint } from "util/imageUtils";

export default (diaryId: string, imageId: string | undefined, width: number, height: number) => {
  const ppp = getNumberOfPixelsPerPointPrint();
  const [url] = useState(`v1/me/diaries/${diaryId}/images/${imageId}?w=${width * ppp}&h=${height * ppp}`);
  const [image, setImage] = useState<HTMLImageElement | undefined>(undefined);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    const ourRequest = axios.CancelToken.source()
    const getAsyncImage = async () => {
      try {
        setLoading(true)
        return await axios
          .get(url, { responseType: "arraybuffer", cancelToken: ourRequest.token })
          .then((res) => {
            const photo = new window.Image() as any;
            photo.src = SagaUtils.processImage(res)
            photo.addEventListener("load", () => {
              setImage(photo)
              setLoading(false)
              setError(false)
            })
          });
      } catch (e) {
        if (axios.isCancel(e)) return;
        setLoading(false)
        setError(true)
        return null
      }
    }
    if (!image && imageId) getAsyncImage()

    return () => { ourRequest.cancel() }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // here, we freeze the array to a tuple
  return [image, loading, error] as const
}