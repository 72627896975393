import { ChapterContentType, ChapterPageType, ContentBlock, LayoutChangeEnum, LayoutType, PageLayoutType, RouteMapDataType, SimpleMapDataType } from "lib/types";
import { uuidv4 } from "util/chapterUtils";

export function changeImageData(
    chapter: ChapterContentType | null,
    block: ContentBlock,
    image: { id: string, imageMetaData: any, format? : string } | null,
    description?: string | null) {
    if (chapter) {
        const data = {
            imageId: image?.id || undefined,
            imageMetaData: image?.imageMetaData || { height: 0, width: 0 },
            emptyBoxDescription: description || undefined,
            imageFormat: image?.format || undefined,
            routeMapData: undefined,
            simpleMapData: undefined
        }

        for (let i = 0; i < chapter.layoutPages.length; i++) {
            for (let j = 0; j < chapter.layoutPages[i].contentBlocks.length; j++) {   
                if (chapter.layoutPages[i].contentBlocks[j].id === block.id) {
                    chapter.layoutPages[i].contentBlocks[j] = {
                        ...chapter.layoutPages[i].contentBlocks[j],
                        ...data
                    }
                    return chapter
                }
            }
        }
    }
        
    return null;
}

export function changeRouteMapData(
    chapter: ChapterContentType | null,
    block: ContentBlock,
    routeMapData: RouteMapDataType
) {
    if (chapter)
        for (let i = 0; i < chapter.layoutPages.length; i++) {
            for (let j = 0; j < chapter.layoutPages[i].contentBlocks.length; j++) {
                if (chapter.layoutPages[i].contentBlocks[j].id === block.id) {
                    chapter.layoutPages[i].contentBlocks[j] = {
                        ...chapter.layoutPages[i].contentBlocks[j],
                        imageId: '',
                        routeMapData,
                        simpleMapData: undefined
                    }
                    return chapter
                }
            }
        }
    return null;
}

export function changeSimpleMapData(
    chapter: ChapterContentType | null,
    block: ContentBlock,
    simpleMapData: SimpleMapDataType
) {
    if (chapter)
        for (let i = 0; i < chapter.layoutPages.length; i++) {
            for (let j = 0; j < chapter.layoutPages[i].contentBlocks.length; j++) {
                if (chapter.layoutPages[i].contentBlocks[j].id === block.id) {
                    chapter.layoutPages[i].contentBlocks[j] = {
                        ...chapter.layoutPages[i].contentBlocks[j],
                        imageId: '',
                        simpleMapData,
                        routeMapData: undefined
                    }
                    return chapter
                }
            }
        }
    return null;
}

function updateContentBlock(existing: any[], future: any[]) {
    const length = [existing.length, future.length]
    if (!length[1]) return []
    const arr = [...existing];
    for (let i = 0; i < length[1] - length[0]; i++) arr.push({ id: uuidv4() })
    for (let k = 0; k < length[1]; k++) {
        arr[k] = { ...arr[k], ...future[k] }
    }
    if (length[0] > length[1]) return arr.slice(0, future.length)
    return arr;
}

export function changeLayoutData(
    chapter: ChapterContentType | null,
    sheets: ChapterPageType[],
    pageId: string,
    layout: LayoutType['layout'],
    type: LayoutChangeEnum) {
    if (chapter && sheets.length) {
        //here we re-evaluate the existence and changes of all the layouts for the pages
        let typeTargetIndex = -1;
        let newLayoutPages = chapter.pages.map((p, index) => {
            //by default we get the data from defaultLayout() function
            let currentLayout = sheets[index].layoutPage
            //if there is page with id, there might be a layout too
            if (p.id) {
                const findLayout = chapter.layoutPages.find((c => c.pageId === p.id));
                if (findLayout) currentLayout = findLayout
            }
            //if that's the layout we are changing
            if (currentLayout.pageId === pageId) {
                //we don't change it if it's not a CHANGE
                if (type !== LayoutChangeEnum.CHANGE) {
                    typeTargetIndex = index
                    return currentLayout;
                }
                const contentBlocks = updateContentBlock(currentLayout!.contentBlocks, layout.blocks)
                currentLayout.contentBlocks = contentBlocks
                currentLayout.layout = layout
                typeTargetIndex = index
            }
            return currentLayout;
        })
        newLayoutPages = applyLayoutChangeType(newLayoutPages, layout, type, typeTargetIndex);
        chapter.layoutPages = newLayoutPages;
        return chapter
    }
    return null;
}

function applyLayoutChangeType(
    layoutPages: PageLayoutType[],
    layout: LayoutType['layout'],
    type: LayoutChangeEnum,
    index: number): PageLayoutType[] {
    if (type === LayoutChangeEnum.CHANGE || index === -1) return layoutPages;
    const contentBlocks = updateContentBlock([], layout.blocks)
    const newLayout: PageLayoutType = {
        layout,
        maxNumberOfPagesBefore: 0,
        canBeUsedOnFirstPage: false,
        contentBlocks,
        pageId: uuidv4()
    }
    if (type === LayoutChangeEnum.LEFT) {
        layoutPages.splice(index, 0, newLayout)
    } else if (type === LayoutChangeEnum.RIGHT) {
        layoutPages.splice(index + 1, 0, newLayout)
        if (index !==0 && layoutPages.length > index + 2)
            layoutPages[index + 2].maxNumberOfPagesBefore = 1
    }
    return layoutPages;
}