import React, { FC } from "react";
import * as S from "./styles";
import { PointsDataType } from "lib/types";
import RouteMapListItem from "./RouteMapListItem";


interface RouteMapListProps {
  dataSource: PointsDataType;
  updateData: (data: PointsDataType) => void;
}

const RouteMapList: FC<RouteMapListProps> = ({ dataSource, updateData }: RouteMapListProps) => {
  const { locations } = dataSource;

  const handleClickRemove = (index: number) => {
    if (dataSource) {
      let markers = [...locations];
      markers.splice(index, 1);
      updateData({ ...dataSource, locations: markers });
    }
  };

  const handleClickMenu = ({ key }) => {
    let [command, index] = key.split('-');
    index = parseInt(index);
    switch (command) {
      case "moveUp":
        if (dataSource) {
          let markers = [...locations];
          [markers[index - 1], markers[index]] = [
            markers[index],
            markers[index - 1],
          ];
          updateData({ ...dataSource, locations: markers });
        }
        break;

      case "moveDown":
        if (dataSource) {
          let markers = [...locations];
          [markers[index + 1], markers[index]] = [
            markers[index],
            markers[index + 1],
          ];
          updateData({ ...dataSource, locations: markers });
        }
        break;
    }
  };

  return (
    <S.CustomList
      bordered
      split={false}
      dataSource={locations.filter(data => data.geometry.type !== 'LineString')}
      renderItem={(item, index) => (
        <RouteMapListItem
          item={item}
          index={index}
          isFirst={index === 0}
          isLast={locations.filter(data => data.geometry.type !== 'LineString').length - 1 === index}
          onRemove={handleClickRemove}
          onMenuClick={handleClickMenu}
        />
      )}
    />
  );
};

export default RouteMapList;
