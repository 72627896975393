import { Component } from 'react';

type MyState = {
  hasError: boolean;
  error: any;
};

class ErrorBoundary extends Component<{}, MyState> {
  state: MyState = {
    hasError: false,
    error: null,
  };

  static getDerivedStateFromError(error: any) {
    return {
      hasError: true,
      error,
    };
  }

  render() {
    if (this.state.hasError) {
      console.warn(this.state.hasError);
      return null;
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
