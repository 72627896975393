import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { H4, HelpParagraph, Paragraph } from 'components/Common/Text';
import { Select } from 'antd';
import { mdiChevronDown } from '@mdi/js';
import GetIcon from 'components/Common/Icon';
import styled from 'styled-components/macro';
import { AppState } from 'store/reducers/rootReducer';
import { StylesType } from 'lib/types';
import fonts from 'lib/fonts.json';
import colors from 'lib/colors.json';
import { Button } from 'components/Common/Button';
import TitleStyle from './StyleParts/TitleStyle';
import TextStyle from './StyleParts/TextStyle';
import DateLocationStyle from './StyleParts/DateLocationStyle';
import { useTranslation } from 'react-i18next';
import { compareObjs } from './StyleParts/reusable';

const { Option } = Select;

// Styled Components

const StyleWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;

  p {
    font-size: 1em;
  }

  &::-webkit-scrollbar {
    display: none;
  }
`;

const PredefinedStyle = styled.div`
  padding-bottom: 1em;

  .ant-select {
    width: 100%;
  }
  p {
    margin-top: 0.3em;
    padding: 0 0.3em;
  }
`;

const ComposeStyle = styled.div`
  display: flex;
  flex-direction: column;
`;

const ComposeItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0.6em;
  background: ${({ theme }) => theme.primaryColor};
  border-bottom: 2px solid lightgray;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  color: #fff;
  cursor: pointer;

  .mdi-icon {
    font-size: 1.5em;
    display: block;
  }

  p {
    color: #fff;
    font-weight: 600;
    font-size: 1.4em;
    margin-bottom: 0;
  }

  &.active {
    background: ${({ theme }) => theme.primaryHover};

    .mdi-icon {
      transform: rotate(180deg);
      -webkit-transition: 0.2s all ease-in-out;
      -moz-transition: 0.2s all ease-in-out;
      transition: 0.2s all ease-in-out;
    }
  }
`;

const ComposeItemContent = styled.div`
  width: 100%;
  height: auto;
  overflow: auto;
  padding: 1em;
  opacity: 1;
  -webkit-transition: 0.25s all ease-in-out;
  -moz-transition: 0.25s all ease-in-out;
  transition: 0.25s all ease-in-out;
  border: 1px solid #ededed;
  margin-bottom: 1em;

  p {
    margin-bottom: 2em;
  }

  ${({ isActive, content }) =>
    isActive !== content && {
      overflow: 'hidden',
      paddingTop: 0,
      paddingBottom: 0,
      maxHeight: 0,
      opacity: 0,
      border: '1px solid #ffffff',
    }}
`;

const SaveButtonWrap = styled.div`
  display: flex;
  justify-content: flex-end;

  @media (max-width: 768px) {
    justify-content: center;
  }
`;

// Types
type StyleTypes = {
  style: StylesType;
  changeStyle: (style: StylesType) => void;
  saveStyle: () => void;
};

const Style: React.FC<StyleTypes> = ({ style, changeStyle, saveStyle }) => {
  const { titleStyle, dateAndLocationStyle: dateStyle, textStyle } = style;
  const [predefined, setPredefined] = useState<string>('Custom');
  const [lineColorSame, setLineColorSame] = useState(false);
  const [activeContent, setActiveContent] = useState<number>(3);
  const { diaryStyles, updateDiaryLoading: disable } = useSelector(
    ({ diary }: AppState) => diary
  );
  const { t } = useTranslation();

  useEffect(() => {
    if (titleStyle) {
      setLineColorSame(
        titleStyle.textColor.value === titleStyle.lineColor.value
      );
    }
  }, [titleStyle]);

  useEffect(() => {
    if (diaryStyles && diaryStyles.length) {
      let found = false;
      for (let styleD of diaryStyles) {
        if (compareObjs(style, styleD)) {
          setPredefined(styleD.displayName);
          found = true;
          break;
        }
      }
      if (!found) setPredefined('Custom');
    }
  }, [style, diaryStyles]);

  const changeTitle = (update: any) => {
    const change = { ...titleStyle, ...update };
    changeStyle({ ...style, titleStyle: change });
  };
  const changeDateLoc = (update: any) => {
    const change = { ...dateStyle, ...update };
    changeStyle({ ...style, dateAndLocationStyle: change });
  };
  const changeText = (update: any) => {
    const change = { ...textStyle, ...update };
    changeStyle({ ...style, textStyle: change });
  };

  const handleLinesColorChange = (e: any) => {
    let changes = { lineColor: titleStyle!.textColor };
    if (!e.target.checked) changes.lineColor = colors[0];
    changeTitle(changes);
  };

  const handleColor = (value: number, option: any): void => {
    switch (option) {
      case 'title': {
        let changes = { textColor: colors[value] } as any;
        if (lineColorSame) changes.lineColor = colors[value];
        return changeTitle(changes);
      }
      case 'dateLoc': {
        return changeDateLoc({ textColor: colors[value] });
      }
    }
  };

  const handleFont = (value: number, type: any): void => {
    const foundFont = fonts.find((f) => f.value === value);
    if (!foundFont) return;
    switch (type) {
      case 'title':
        return changeTitle({ font: foundFont });
      case 'dateLoc':
        return changeDateLoc({ font: foundFont });
      case 'text':
        return changeText({ font: foundFont });
    }
  };

  const toggleActiveContent = (num: number): void =>
    num === activeContent ? setActiveContent(0) : setActiveContent(num);

  const handlePredefined = (styleName: string) => {
    const found = diaryStyles.find((s) => s.displayName === styleName);
    if (found) {
      setPredefined(styleName);
      changeStyle(found);
    }
  };

  const activeTab = (id: number) => (activeContent === id ? 'active' : '');

  return (
    <StyleWrap>
      <div>
        <PredefinedStyle>
          <H4>{t('predefinedStyle')}</H4>
          <Select
            defaultValue='Custom'
            value={predefined}
            onChange={handlePredefined}
            disabled={disable}
          >
            {diaryStyles &&
              diaryStyles.map((s) => (
                <Option key={s.displayName} value={s.displayName}>
                  {s.displayName}
                </Option>
              ))}
            <Option value='Custom'>Custom</Option>
          </Select>
          <HelpParagraph>{t('predefinedHelpText')}</HelpParagraph>
        </PredefinedStyle>
        <ComposeStyle>
          <H4>{t('composeStyle')}</H4>
          <ComposeItem
            className={activeTab(1)}
            onClick={() => toggleActiveContent(1)}
          >
            <Paragraph>{t('title')}</Paragraph>
            <GetIcon path={mdiChevronDown} className='extra' />
          </ComposeItem>
          <ComposeItemContent isActive={activeContent} content={1}>
            <TitleStyle
              t={t}
              lineColorSame={lineColorSame}
              titleStyle={titleStyle}
              changeTitle={changeTitle}
              disable={disable}
              handleColor={handleColor}
              handleFont={handleFont}
              handleLinesColorChange={handleLinesColorChange}
            />
          </ComposeItemContent>

          <ComposeItem
            className={activeTab(2)}
            onClick={() => toggleActiveContent(2)}
          >
            <Paragraph>{t('dateAndLocation')}</Paragraph>
            <GetIcon path={mdiChevronDown} className='extra' />
          </ComposeItem>
          <ComposeItemContent isActive={activeContent} content={2}>
            <DateLocationStyle
              t={t}
              dateStyle={dateStyle}
              changeDateLoc={changeDateLoc}
              disable={disable}
              handleColor={handleColor}
              handleFont={handleFont}
            />
          </ComposeItemContent>

          <ComposeItem
            className={activeTab(3)}
            onClick={() => toggleActiveContent(3)}
          >
            <Paragraph>{t('text')}</Paragraph>
            <GetIcon path={mdiChevronDown} className='extra' />
          </ComposeItem>
          <ComposeItemContent isActive={activeContent} content={3}>
            <TextStyle
              t={t}
              textStyle={textStyle}
              changeText={changeText}
              handleFont={handleFont}
              disable={disable}
            />
          </ComposeItemContent>
        </ComposeStyle>
      </div>
      <SaveButtonWrap>
        <Button disabled={disable} size='large' filled onClick={saveStyle}>
          {t('save')}
        </Button>
      </SaveButtonWrap>
    </StyleWrap>
  );
};

export default Style;
