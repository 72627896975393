import React, {
  useState,
  useCallback,
  useRef,
  useEffect,
  ReactElement,
  useMemo,
} from "react";
import { ReactReduxContext, Provider, useSelector } from "react-redux";
import { Stage, Layer, Image } from "react-konva";
import useImage from "use-image";
import styled from "styled-components/macro";
import ImageDropdownMenu from "components/Editor/Chapter/Parts/PageImage/ImageDropdownMenu";
import PageContent from "components/Editor/Chapter/PageContent";
import LayoutOverlay from "./Parts/LayoutOverlay";
import LayoutButtons from "./Parts/LayoutButtons";
import { AppState } from "store/reducers/rootReducer";
import { ChapterPageType } from "lib/types";
import CoverLoader from "components/Common/CoverLoader";
import NavigationArrows from "../NavigationArrows";
import { createPortal } from "react-dom";

// Styled components
const ContentWrap = styled.div`
  width: 100%;
  flex: 8;
  font: ${({ font }) => font};
  padding: 0 70px;

  .stage {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    height: 100%;
  }
`;

const Burrito = styled.div`
  width: ${({ Page, scale }) => Page.w * scale}px;
  position: absolute;
  top: ${({ spaceTop }) => spaceTop}px;
  right: ${({ spaceRight }) => spaceRight}px;
  left: ${({ spaceLeft }) => spaceLeft}px;
`;

const { REACT_APP_PAGE_WIDTH, REACT_APP_PAGE_HEIGHT } = process.env as any;
const PAGE = { w: 2 * REACT_APP_PAGE_WIDTH, h: +REACT_APP_PAGE_HEIGHT };

const ChapterContent: React.FC = (): ReactElement => {
  const { diary, updateDiaryLoading } = useSelector(
    ({ diary }: AppState) => diary
  );
  const [image] = useImage("/background-diary-white.png");
  const [scale, setScale] = useState(1);
  const stageEl = useRef(null) as any;
  const { layoutChange, sheets, pageNumber, previewMode } = useSelector(
    ({ chapters }: AppState) => chapters
  );
  const [spaceTop, setSpaceTop] = useState<number>(100);
  const [spaceLeft, setSpaceLeft] = useState<number>(0);
  const [spaceRight, setSpaceRight] = useState<number>(0);
  const diaryStyle = useMemo(() => diary?.style, [diary]);

  //! The 3 following functions are intentional to prevent rerender
  const sides = useMemo(
    () => ({ left: sheets[pageNumber], right: sheets[pageNumber + 1] }),
    [pageNumber, sheets]
  );
  const leftPage = useCallback(
    () => <ShowPage isRight={false} side={sides.left} />,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [diaryStyle, sides.left]
  );
  const rightPage = useCallback(
    () => <ShowPage isRight={true} side={sides.right} />,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [diaryStyle, sides.right]
  );

  const positionNavigationArrows = useCallback(() => {
    if (stageEl && stageEl.current) {
      const stageContent = document.querySelector(".konvajs-content");
      if (!stageContent?.classList.contains("on-top")) {
        stageContent!.classList.add("on-top");
      }
      const stageContentBounds = stageContent!.getBoundingClientRect();
      const right = window.innerWidth - stageContentBounds.right;
      const left = stageContentBounds.left;
      setSpaceTop(stageContentBounds.top + stageContent!.clientHeight / 2);
      setSpaceLeft(left);
      setSpaceRight(right);
    }
  }, [stageEl]);

  useEffect(() => {
    setTimeout(() => {
      positionNavigationArrows();
    }, 200);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (stageEl && stageEl.current !== null) {
        const stageObj = stageEl.current.container();
        setTimeout(() => {
          positionNavigationArrows();
        }, 250);

        if (stageObj) {
          setScale(
            Math.min(
              stageObj.clientWidth / PAGE.w,
              stageObj.clientHeight / PAGE.h
            )
          );
        }
      }
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, [positionNavigationArrows, stageEl, previewMode]);

  const bookBackground = useCallback(() => {
    return (
      <Layer>
        <Image width={PAGE.w} height={PAGE.h} image={image} />
      </Layer>
    );
  }, [image]);

  const ShowPage = ({
    isRight,
    side,
  }: {
    isRight: boolean;
    side: ChapterPageType;
  }) => {
    if (typeof side === "undefined") return <></>;
    return (
      <PageContent
        key={isRight ? "right" : "left"}
        side={side}
        style={diaryStyle}
        isRight={isRight}
      />
    );
  };

  const renderLayoutOverlay = useCallback(() => {
    if (!layoutChange) return null;
    return <LayoutOverlay layoutChange={layoutChange} />;
  }, [layoutChange]);

  if (!sheets.length) return <></>;

  const renderLayoutButtons = () => {
    if (stageEl && stageEl.current) {
      return createPortal(
        <>
          {!previewMode ? (
            <LayoutButtons
              width={stageEl.current.content.clientWidth}
              sides={sides}
            />
          ) : null}
        </>,
        stageEl.current.container()
      );
    }
    return null;
  };

  return (
    <ContentWrap>
      <ReactReduxContext.Consumer>
        {({ store }) => (
          <>
            {updateDiaryLoading ? (
              <CoverLoader />
            ) : (
              <>
                <>
                  <Stage
                    className="stage"
                    width={PAGE.w * scale}
                    height={PAGE.h * scale}
                    id="xx"
                    data-id="asdasd"
                    ref={stageEl}
                    scaleX={scale}
                    scaleY={scale}
                  >
                    {bookBackground()}
                    <Provider store={store}>
                      {leftPage()}
                      {rightPage()}
                    </Provider>
                    {renderLayoutOverlay()}
                  </Stage>
                  {renderLayoutButtons()}
                </>
                <Burrito
                  Page={PAGE}
                  scale={scale}
                  spaceTop={spaceTop}
                  spaceLeft={spaceLeft}
                  spaceRight={spaceRight}
                >
                  <NavigationArrows />
                </Burrito>
              </>
            )}
          </>
        )}
      </ReactReduxContext.Consumer>
      <ImageDropdownMenu />
    </ContentWrap>
  );
};

export default ChapterContent;
