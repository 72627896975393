import React, { ReactElement, Fragment } from "react";
import { useSelector } from "react-redux";
import { AppState } from "store/reducers/rootReducer";
import Chapters from "./Chapters/index";
import Layouts from "./Layouts";

// Types
type SidebarGroupWrapTypes = {
	currentItem: number;
};

const SidebarGroupWrap: React.FC<SidebarGroupWrapTypes> = ({
	currentItem,
}): ReactElement => {
	const { chapters } = useSelector((state: AppState) => state.chapters);

	const renderContent = () => {
		switch (currentItem) {
			case 1:
				return <Chapters chapters={chapters} />;
			case 2:
				return <Layouts />;
			default:
				return null;
		}
	};

	return <Fragment>{renderContent()}</Fragment>;
};

export default SidebarGroupWrap;
