import styled from 'styled-components/macro';

export const Paragraph = styled.p`
  font-size: ${({ size }) => (size ? size : 1.8)}em;
  line-height: 1.4em;
  font-weight: 300;
  color: ${({ theme }) => theme.textColor};
  ${(props) =>
    props.noMargin && {
      marginBottom: '0',
    }}
`;

export const HelpParagraph = styled(Paragraph)`
  color: ${({ theme }) => theme.greyColor};
`;

export const WarningParagraph = styled(Paragraph)`
  color: ${({ theme }) => theme.redColor};
  font-weight: 600;
`;

export const Span = styled.span`
  font-size: 1.8em;
  line-height: 1.4em;
  font-weight: 300;
  display: inline-block;
  color: ${({ theme }) => theme.textColor};
`;

export const H1 = styled.h1`
  font-size: 4em;
  margin-bottom: 1em;
  line-height: 1.2em;
  font-weight: 700;
  color: ${({ theme }) => theme.textColor};

  @media (max-width: 768px) {
    font-size: 3.2em !important;
    font-weight: 600 !important;
  }
`;

export const H2 = styled.h2`
  font-size: 3em;
  margin-bottom: 1em;
  line-height: 1em;
  font-weight: 700;
  color: ${({ theme }) => theme.textColor};

  @media (max-width: 768px) {
    font-size: 2.3em !important;
    font-weight: 600 !important;
  }
`;

export const H3 = styled.h3`
  font-size: 2.2em;
  margin-bottom: 1em;
  line-height: 1.5em;
  font-weight: 600;
  color: ${({ theme }) => theme.textColor};

  @media (max-width: 768px) {
    font-size: 1.8em !important;
  }
`;

export const H4 = styled.h4`
  font-size: 1.8em;
  line-height: 1.2em;
  margin-bottom: 1em;
  font-weight: 600;
  color: ${({ theme }) => theme.textColor};

  @media (max-width: 768px) {
    font-size: 1.6em;
  }
`;

export const H5 = styled.h5`
  font-size: 1.4em;
  line-height: 1em;
  margin-bottom: 1em;
  font-weight: 600;
  color: ${({ theme }) => theme.textColor};

  @media (max-width: 768px) {
    font-size: 1.2em;
  }
`;
