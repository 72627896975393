import { DiaryType } from 'lib/types';

export const OPEN_SHARE_MODAL = 'OPEN_SHARE_MODAL';
export const CLOSE_SHARE_MODAL = 'CLOSE_SHARE_MODAL';
export const RESET_SHARE_DATA = 'RESET_SHARE_DATA';
export const OPEN_EMAIL_SECTION = 'OPEN_EMAIL_SECTION';
export const CLOSE_EMAIL_SECTION = 'CLOSE_EMAIL_SECTION';
export const RESET_SHARE_MESSAGE = 'RESET_SHARE_MESSAGE';

//SHARE MODAL ACTIONS
export const PUBLISH_DIARY_START_SAGA = 'PUBLISH_DIARY_START_SAGA';
export const PUBLISH_DIARY_START = 'PUBLISH_DIARY_START';
export const PUBLISH_DIARY_SUCCESS = 'PUBLISH_DIARY_SUCCESS';
export const PUBLISH_DIARY_ERROR = 'PUBLISH_DIARY_ERROR';
export const UNPUBLISH_DIARY_START_SAGA = 'UNPUBLISH_DIARY_START_SAGA';
export const UNPUBLISH_DIARY_START = 'UNPUBLISH_DIARY_START';
export const UNPUBLISH_DIARY_SUCCESS = 'UNPUBLISH_DIARY_SUCCESS';
export const UNPUBLISH_DIARY_ERROR = 'UNPUBLISH_DIARY_ERROR';
export const SHARE_BY_EMAIL_START_SAGA = 'SHARE_BY_EMAIL_START_SAGA';
export const SHARE_BY_EMAIL_START = 'SHARE_BY_EMAIL_START';
export const SHARE_BY_EMAIL_SUCCESS = 'SHARE_BY_EMAIL_SUCCESS';
export const SHARE_BY_EMAIL_ERROR = 'SHARE_BY_EMAIL_ERROR';
export const GET_SHAREABLE_EMAILS_START_SAGA =
  'GET_SHAREABLE_EMAILS_START_SAGA';
export const GET_SHAREABLE_EMAILS_SUCCESS = 'GET_SHAREABLE_EMAILS_SUCCESS';
export const GET_SHAREABLE_EMAILS_ERROR = 'GET_SHAREABLE_EMAILS__ERROR';
export const DELETE_SHARED_EMAIL_START_SAGA = 'DELETE_SHARED_EMAIL_START_SAGA';
export const DELETE_SHARED_EMAIL_SUCCESS = 'DELETE_SHARED_EMAIL_SUCCESS';
export const DELETE_SHARED_EMAIL_ERROR = 'DELETE_SHARED_EMAIL_ERROR';

export interface IPublishDiaryStartSagaAction {
  type: typeof PUBLISH_DIARY_START_SAGA;
  payload: DiaryType;
}
export interface IPublishDiaryStartAction {
  type: typeof PUBLISH_DIARY_START;
}
export interface IPublishDiarySuccessAction {
  type: typeof PUBLISH_DIARY_SUCCESS;
}
export interface IPublishDiaryErrorAction {
  type: typeof PUBLISH_DIARY_ERROR;
}
export interface IUnpublishDiaryStartSagaAction {
  type: typeof UNPUBLISH_DIARY_START_SAGA;
  payload: DiaryType;
}
export interface IUnpublishDiaryStartAction {
  type: typeof UNPUBLISH_DIARY_START;
}
export interface IUnpublishDiarySuccessAction {
  type: typeof UNPUBLISH_DIARY_SUCCESS;
}
export interface IUnpublishDiaryErrorAction {
  type: typeof UNPUBLISH_DIARY_ERROR;
}
export interface IOpenModalAction {
  type: typeof OPEN_SHARE_MODAL;
  payload: string | boolean;
}
export interface ICloseModalAction {
  type: typeof CLOSE_SHARE_MODAL;
}
export interface IEmailSectionOpenAction {
  type: typeof OPEN_EMAIL_SECTION;
  payload: string;
}
export interface IEmailSectionCloseAction {
  type: typeof CLOSE_EMAIL_SECTION;
}
export interface IEmailShareStartAction {
  type: typeof SHARE_BY_EMAIL_START;
}
export interface IEmailShareStartSagaAction {
  type: typeof SHARE_BY_EMAIL_START_SAGA;
  payload: { diaryId: string; data: object; chapterId?: string };
}
export interface IEmailShareSuccessAction {
  type: typeof SHARE_BY_EMAIL_SUCCESS;
}
export interface IEmailShareErrorAction {
  type: typeof SHARE_BY_EMAIL_ERROR;
}
export interface IResetShareMessageAction {
  type: typeof RESET_SHARE_MESSAGE;
}
export interface IGetSharableEmailsStartSagaAction {
  type: typeof GET_SHAREABLE_EMAILS_START_SAGA;
  payload: string | null;
}
export interface IGetSharableEmailsSuccessAction {
  type: typeof GET_SHAREABLE_EMAILS_SUCCESS;
  payload: string[];
}
export interface IGetSharableEmailsErrorAction {
  type: typeof GET_SHAREABLE_EMAILS_ERROR;
}
export interface IDeleteSharedEmailStartSagaAction {
  type: typeof DELETE_SHARED_EMAIL_START_SAGA;
  payload: { diaryId: string; email: string };
}
export interface IDeleteSharedEmailSuccess {
  type: typeof DELETE_SHARED_EMAIL_SUCCESS;
  payload: string;
}
export interface IDeleteSharedEmailError {
  type: typeof DELETE_SHARED_EMAIL_ERROR;
}

export type ShareActionTypes =
  | IPublishDiaryStartAction
  | IPublishDiaryStartSagaAction
  | IUnpublishDiaryStartSagaAction
  | IPublishDiarySuccessAction
  | IUnpublishDiarySuccessAction
  | IPublishDiaryErrorAction
  | IUnpublishDiaryErrorAction
  | IOpenModalAction
  | ICloseModalAction
  | IEmailSectionOpenAction
  | IEmailSectionCloseAction
  | IEmailShareStartAction
  | IEmailShareStartSagaAction
  | IEmailShareSuccessAction
  | IEmailShareErrorAction
  | IResetShareMessageAction
  | IGetSharableEmailsStartSagaAction
  | IGetSharableEmailsErrorAction
  | IGetSharableEmailsSuccessAction
  | IDeleteSharedEmailStartSagaAction
  | IDeleteSharedEmailSuccess
  | IDeleteSharedEmailError
  | IUnpublishDiaryStartAction;
