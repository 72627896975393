import { IconType } from 'lib/types';
import * as React from 'react';
import IconContainer from 'components/Common/IconContainer';

const UploadIcon: React.FC<IconType> = ({ color, height, width }) => {
  return (
    <IconContainer
      width={width}
      height={height}
      viewBox="0 0 512 512"
      xmlns="http://www.w3.org/2000/svg"
      fillColor={color}
    >
      <path
        d="M380.032 133.472l-112-128C264.992 2.016 260.608 0 256 0s-8.992 2.016-12.032 5.472l-112 128c-4.128 4.736-5.152 11.424-2.528 17.152A16.013 16.013 0 00144 160h64v208c0 8.832 7.168 16 16 16h64c8.832 0 16-7.168 16-16V160h64a15.96 15.96 0 0014.56-9.376c2.592-5.728 1.632-12.448-2.528-17.152z"
        fill={color}
      />
      <path
        d="M432 352v96H80v-96H16v128c0 17.696 14.336 32 32 32h416c17.696 0 32-14.304 32-32V352h-64z"
        fill={color}
      />
    </IconContainer>
  );
};

export default UploadIcon;
