export const GET_COVER_START_SAGA = "GET_COVER_START_SAGA";
export const GET_COVER_START = "GET_COVER_START";
export const GET_COVER_SUCCESS = "GET_COVER_SUCCESS";
export const GET_COVER_ERROR = "GET_COVER_ERROR";

export const UPDATE_COVER_START_SAGA = "UPDATE_COVER_START_SAGA";
export const UPDATE_COVER_START = "UPDATE_COVER_START";
export const UPDATE_COVER_SUCCESS = "UPDATE_COVER_SUCCESS";
export const UPDATE_COVER_ERROR = "UPDATE_COVER_ERROR";

export const COVER_RESET = "COVER_RESET";

export interface IGetCoverStartSaga {
	type: typeof GET_COVER_START_SAGA;
}

export interface IGetCoverSuccessAction {
	type: typeof GET_COVER_SUCCESS;
	payload: string;
}

export interface IGetCoverStartAction {
	type: typeof GET_COVER_START;
}

export interface IUpdateCoverStartSaga {
	type: typeof UPDATE_COVER_START_SAGA;
	payload: { diaryId: string, image: FormData }
}

export interface IUpdateCoverStartAction {
	type: typeof UPDATE_COVER_START;
}

export interface ICoverResetAction {
	type: typeof COVER_RESET;
}

export type CoverActionTypes =
	| IGetCoverStartSaga
	| IGetCoverStartAction
	| IUpdateCoverStartSaga
	| IUpdateCoverStartAction
	| IGetCoverSuccessAction
	| ICoverResetAction;
