import React, { ReactElement } from 'react';
import { Layer, Rect } from 'react-konva';
import { LayoutChangeType } from 'lib/types';

const appName = process.env.REACT_APP_NAME;
const { REACT_APP_PAGE_WIDTH: pageX, REACT_APP_PAGE_HEIGHT: pageY } =
  process.env as any;
type Props = { layoutChange: LayoutChangeType };
const LayoutOverlay: React.FC<Props> = ({ layoutChange }): ReactElement => {
  const { sheet, type } = layoutChange;
  const isLayoutChangeOnLeft =
    (sheet.pagesBefore + sheet.page.number) % 2 !== 0;

  const colors = {
    'Travel Diaries': 'yellow',
    'Baby Diaries': '#D5BBA6',
  };

  function renderOverlay(which: string) {
    let color = appName ? colors[appName.toString()] : colors['Travel Diaries'];
    const isLeftPage = which === 'left';

    if (type === 'change') {
      if (isLayoutChangeOnLeft && !isLeftPage) {
        color = 'transparent';
      } else if (!isLayoutChangeOnLeft && isLeftPage) {
        color = 'transparent';
      }
    } else if (type !== which) color = 'transparent';

    return (
      <Rect
        x={isLeftPage ? 5 : +pageX}
        y={9}
        width={+pageX - 5}
        height={+pageY - 18}
        fill={color}
        opacity={0.2}
      />
    );
  }

  return (
    <Layer>
      {renderOverlay('left')}
      {renderOverlay('right')}
    </Layer>
  );
};

export default LayoutOverlay;
