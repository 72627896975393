import i18next from 'i18next';

class SagaUtils {
  static processImage = (response) =>
    `data:${response.headers['content-type']};base64,${Buffer.from(
      response.data,
      'binary'
    ).toString('base64')}`;

  static initialChapter = (id: string) => ({
    diaryId: id,
    date: new Date().toISOString(),
    isPublic: false,
    location: '',
    text: '',
    title: i18next.t('newChapter'),
    sortOrder: 1,
  });
}

export default SagaUtils;
