import React, { ReactElement } from "react";
import { useDispatch } from "react-redux";
import { setImageChangeType } from "store/actions/chapter-actions";
import { Row, Col } from "antd";
import "mapbox-gl/dist/mapbox-gl.css";
import { useTranslation } from "react-i18next";
import { ImageBoxType } from "lib/types";
import { H5 } from "components/Common/Text";
import * as S from "./styles";

const SelectMapType: React.FC = (): ReactElement => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const onRouteMapClick = () => dispatch(setImageChangeType(ImageBoxType.ROUTE_MAP))
  const onSimpleMapClick = () => dispatch(setImageChangeType(ImageBoxType.SIMPLE_MAP))

  return (
    <>
      <H5>{t("clickToSelectMapType")}</H5>
      <Row gutter={16}>
        <Col span={12}>
          <S.Image
            src={"/map-type-simple.jpeg"}
            alt={t("simpleMap")}
            onClick={onSimpleMapClick}
          />
          <S.Title>{t("simpleMap")}</S.Title>
          <p>{t("simpleMapDescription")}</p>
        </Col>
        <Col span={12}>
          <S.Image
            src={"/map-type-route.jpeg"}
            alt={t("routeMap")}
            onClick={onRouteMapClick}
          />
          <S.Title>{t("routeMap")}</S.Title>
          <p>{t("routeMapDescription")}</p>
        </Col>
      </Row>
    </>
  );
};

export default SelectMapType;
