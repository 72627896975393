import React, { ReactElement } from "react";
import { Container } from "components/Common/Layout";
import { DiaryType, ChapterContentType } from "lib/types";
import DiaryPreview from "./DiaryPreview";
import ChaptersPreview from "./ChaptersPreview";
import styled from "styled-components/macro";

// Styled components
const PreviewDiaryWrap = styled.div`
	display: flex;
	flex-direction: row;
	width: 85%;
	margin: 0 auto;
`;

type PreviewDiaryType = {
	diary: DiaryType;
	cover: string;
	chapters: ChapterContentType[];
};

const PreviewDiary: React.FC<PreviewDiaryType> = ({diary, cover, chapters }): ReactElement => {
	return (
		<Container>
			<PreviewDiaryWrap>
				<DiaryPreview cover={cover} diary={diary} />
				<ChaptersPreview diaryId={diary?.id} chapters={chapters} />
			</PreviewDiaryWrap>
		</Container>
	);
};

export default PreviewDiary;
