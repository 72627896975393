import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Modal } from 'antd';
import styled from 'styled-components/macro';
import { H4 } from 'components/Common/Text';
import { AppState } from 'store/reducers/rootReducer';
import StylePreview from 'components/StylePreview';
import { StylesType } from 'lib/types';
import Style from './Style';
import { updateDiary } from 'store/actions/diary-actions';

const ModalContent = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0;

  @media (max-width: 768px) {
    flex-direction: column;
    flex-wrap: wrap;
    margin: 0 1em 1em;
  }

  ${({ isLoaded }) =>
    isLoaded ? { animation: 'fadeIn 0.5s linear' } : { opacity: 0 }}

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
`;

const StyleWrap = styled.div`
  position: relative;
  display: inline-flex;
`;

const WrapItem = styled.div`
  flex: 1;
  padding: 1em;

  h4 {
    font-size: 1.3em;
    font-weight: 700;
  }

  &.styles {
    margin-left: 3em;
  }

  &.preview {
    h4 {
      padding-left: 0.1em;
    }
  }

  @media (max-width: 768px) {
    &.styles {
      margin-left: 0;
    }
    width: 100%;
  }
`;

type CoverProps = { visible: boolean; setVisible: (newState: boolean) => void };

const StylesModal: React.FC<CoverProps> = ({ visible, setVisible }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { diary } = useSelector((state: AppState) => state.diary);
  const { chapters } = useSelector((state: AppState) => state.chapters);
  const [state, setState] = useState<StylesType>();

  useEffect(() => {
    if (diary && diary.style) setState(diary.style);
  }, [diary]);

  const updateDiaryStyle = () => {
    if (state) {
      const chapterIds: string[] = chapters.map((c) => c.id);
      dispatch(updateDiary({ ...diary!, style: state }, true, chapterIds));
      setVisible(false);
    }
  };

  return (
    <Modal
      title={<H4>{t('changeDiaryStyle')}</H4>}
      style={{ top: '3%' }}
      visible={visible}
      onCancel={() => setVisible(false)}
      footer={null}
      width={1000}
    >
      <ModalContent isLoaded={state}>
        <WrapItem className='preview'>
          <H4>{t('preview')}</H4>
          <StyleWrap>{state && <StylePreview style={state} />}</StyleWrap>
        </WrapItem>
        <WrapItem className='styles'>
          {state && (
            <Style
              style={state}
              changeStyle={setState}
              saveStyle={updateDiaryStyle}
            />
          )}
        </WrapItem>
      </ModalContent>
    </Modal>
  );
};

export default StylesModal;
