import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Callback from 'hoc/Callback';
import GuardRoute from 'hoc/GuardRoute';

import CreateDiaryView from 'views/create/CreateDiaryView';
import EditorView from 'views/edit/EditorView';
import NotFoundPage from 'views/NotFoundView';

const Routes = () => {
  return (
    <Switch>
      <Route exact path='/signin-oidc' component={Callback} />
      <Route
        exact
        path='/:lang?/add-diary/:voucherId?'
        render={(routeProps) => (
          <GuardRoute routeProps={routeProps} component={CreateDiaryView} />
        )}
      />
      <Route
        exact
        path='/:lang?/diary/:diaryId/chapter/:chapterId'
        render={(routeProps) => (
          <GuardRoute routeProps={routeProps} component={EditorView} />
        )}
      />
      <Route
        exact
        path='/:lang?/diary/:diaryId'
        render={(routeProps) => (
          <GuardRoute routeProps={routeProps} component={EditorView} />
        )}
      />

      <Route path='*' component={NotFoundPage} />
    </Switch>
  );
};

export default Routes;
