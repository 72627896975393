import {
  ChapterContentType,
  ImageChangeType,
  LayoutType,
  ChapterPageType,
  LayoutChangeEnum,
  ImageBoxType,
  PointsDataType,
} from 'lib/types';
import { defaultLayout } from 'util/chapterUtils';
import {
  SET_PAGE_NUMBER,
  GET_SHEETS_LOADED,
  GET_CHAPTERS_START_SAGA,
  GET_SHEETS_START_SAGA,
  IGetChaptersStartSaga,
  IGetMapStartSaga,
  IGetChapterStartSaga,
  ISetPageNumber,
  ISetEnoughSheetsLoaded,
  ISetModalChapter,
  SET_MODAL_CHAPTER,
  POST_CHAPTER_START_SAGA,
  IPostChapterStartSaga,
  PUT_CHAPTER_START_SAGA,
  IPutChapterStartSaga,
  IDeleteChapterStartSaga,
  DELETE_CHAPTER_START_SAGA,
  ISortChapterStartSaga,
  SORT_CHAPTER_START_SAGA,
  CHANGE_LAYOUT,
  IChangeLayout,
  PUT_LAYOUT_START_SAGA,
  IPutLayoutStartSaga,
  SET_IMAGE_CHANGE,
  SET_IMAGE_CHANGE_TYPE,
  ISetImageChangeType,
  ISetImageChange,
  POST_IMAGE_START_SAGA,
  IPostImageStartSaga,
  SET_PREVIEW,
  ISetPreview,
  GET_LAYOUTS_START_SAGA,
  PUT_EMPTY_BOX_START_SAGA,
  GET_MAP_START_SAGA,
  IPostMapStartSaga,
  POST_MAP_START_SAGA,
} from 'store/actions/chapter-actions/chapter-action-types';

//**** NON-SAGA ACTIONS  *****/

export const setPreview = (): ISetPreview => {
  return { type: SET_PREVIEW };
};

export const setPageNumber = (pageNumber: number): ISetPageNumber => {
  return { type: SET_PAGE_NUMBER, payload: pageNumber };
};

export const setImageChange = (
  data: ImageChangeType | null
): ISetImageChange => {
  return { type: SET_IMAGE_CHANGE, payload: data };
};

export const setImageChangeType = (type: ImageBoxType): ISetImageChangeType => {
  return { type: SET_IMAGE_CHANGE_TYPE, payload: type };
};

export const setModalChapter = (chapterId: string | null): ISetModalChapter => {
  return { type: SET_MODAL_CHAPTER, payload: chapterId };
};

export const changeLayout = (
  sheet: ChapterPageType | undefined,
  type: LayoutChangeEnum = LayoutChangeEnum.CHANGE
): IChangeLayout => {
  if (!sheet) return { type: CHANGE_LAYOUT, payload: undefined };
  return { type: CHANGE_LAYOUT, payload: { sheet, type } };
};

export const setStopLoading = (): ISetEnoughSheetsLoaded => {
  return { type: GET_SHEETS_LOADED };
};

//**** GET MAP SAGAS  *****/

export const getMap = (
  diaryId: string,
  chapterIds: string[],
  isUpdate: boolean
): IGetMapStartSaga => {
  return {
    type: GET_MAP_START_SAGA,
    payload: { diaryId, chapterIds, isUpdate },
  };
};

//**** GET CHAPTERS SAGAS  *****/

export const getChapters = (diaryId: string): IGetChaptersStartSaga => {
  return { type: GET_CHAPTERS_START_SAGA, payload: diaryId };
};

export const getAllChapterSheets = (
  diaryId: string,
  chapterIds: string[],
  isUpdate: boolean
): IGetChapterStartSaga => {
  return {
    type: GET_SHEETS_START_SAGA,
    payload: { diaryId, chapterIds, isUpdate },
  };
};

//**** CHAPTER SPECIFIC SAGAS  *****/

export const addChapter = (
  content: ChapterContentType,
  withClose: boolean
): IPostChapterStartSaga => {
  return { type: POST_CHAPTER_START_SAGA, payload: { content, withClose } };
};

export const updateChapter = (
  content: ChapterContentType,
  withClose?: boolean
): IPutChapterStartSaga => {
  return { type: PUT_CHAPTER_START_SAGA, payload: { content, withClose } };
};

export const deleteChapter = (
  diaryId: string,
  chapterId: string
): IDeleteChapterStartSaga => {
  return { type: DELETE_CHAPTER_START_SAGA, payload: { diaryId, chapterId } };
};

export const sortChapterOrder = (
  start: number,
  end: number
): ISortChapterStartSaga => {
  return { type: SORT_CHAPTER_START_SAGA, payload: { start, end } };
};

export const saveImage = (
  data: ImageChangeType,
  file: any
): IPostImageStartSaga => {
  const image = new FormData();
  image.set('image', file);
  return { type: POST_IMAGE_START_SAGA, payload: { data, image } };
};

export const saveEmptyBox = (
  data: ImageChangeType,
  description: string | null
) => {
  return { type: PUT_EMPTY_BOX_START_SAGA, payload: { data, description } };
};

export const saveMap = (
  data: ImageChangeType,
  map: PointsDataType,
  imageData: string,
  isRoute: boolean
): IPostMapStartSaga => {
  return {
    type: POST_MAP_START_SAGA,
    payload: { data, map, imageData, isRoute },
  };
};

//**** LAYOUTS SPECIFIC SAGAS  *****/

export const getLayouts = () => {
  return { type: GET_LAYOUTS_START_SAGA };
};

export const updateLayout = (data: {
  chapterId: string;
  layout: LayoutType['layout'];
  pageId: string;
  type: LayoutChangeEnum;
}): IPutLayoutStartSaga => {
  return { type: PUT_LAYOUT_START_SAGA, payload: data };
};

export const deleteLayout = (sheet: ChapterPageType): IPutLayoutStartSaga => {
  const data = {
    chapterId: sheet.id,
    layout: defaultLayout().layout,
    pageId: sheet.page.id,
    type: LayoutChangeEnum.CHANGE,
  };
  return updateLayout(data);
};
