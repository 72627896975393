import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import { setPageNumber } from "store/actions/chapter-actions";
import { H4, HelpParagraph } from "components/Common/Text";
import ChapterMenu from "./ChapterMenu";
import { chapterPageNumber, getDateFormat } from "util/chapterUtils";
import { ChapterContentType } from "lib/types";
import { DateFormatEnum } from "lib/types";
import { AppState } from "store/reducers/rootReducer";
import moment from "moment";
import { Spin } from "antd";

// Styled components

const ChaptersWrap = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
	${({ isLoaded }) => (isLoaded ? { animation: "fadeIn 0.5s linear" } : { opacity: 0 })}
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1.75em 2.5em;
  background: ${({ theme }) => theme.primaryColor};
  overflow: hidden;
  -webkit-transition: 0.15s all ease-in-out;
  -moz-transition: 0.15s all ease-in-out;
  transition: 0.15s all ease-in-out;

  h4 {
    margin-bottom: 0;
    color: ${({ theme }) => theme.whiteColor};
    white-space: nowrap;
  }

  &:hover {
    background: ${({ theme }) => theme.primaryHover};
  }
`;

const ChaptersList = styled.ul`
  margin: 0;
  padding: 0;
  overflow: auto;
  height: 100%;
`;

const ChaptersListItem = styled.li`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-around;
  padding: 1.5em 1em;
  border-top: 1px solid #f6f6f6;
  border-bottom: 1px solid #f6f6f6;
`;

const ChapterTitle = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;

  h4 {
    font-size: 1.5em;
    margin-bottom: 0;
    padding-bottom: 0.1em;
    &:hover {
      color: ${({ theme }) => theme.primaryHover};
      cursor: pointer;
    }
  }

  p {
    margin: 0;
  }
`;

const ActionsWrap = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  flex: 1 1 30px;
  margin-left: 10px;

  .mdi-icon {
    width: 30px;
    height: 30px;
    color: gray;
    cursor: pointer;

    &:hover {
      color: ${({ theme }) => theme.primaryHover};
    }
  }

  @media (max-width: 992px) {
    .mdi-icon {
      width: 30px;
      height: 30px;
    }
  }
`;
// Types
type ChapterTypes = {
  chapters: ChapterContentType[];
};

const Chapters: React.FC<ChapterTypes> = ({ chapters }: ChapterTypes) => {
  const { t } = useTranslation()
  const [state, setState] = useState<ChapterContentType[]>([]);
  const [loaded, setLoaded] = useState(false);
  const dispatch = useDispatch();
  const { userAccount } = useSelector(({ user }: AppState) => user);


  useEffect(() => {
    if (chapters) {
      setState(chapters);
      setLoaded(true)
    }
  }, [chapters]);

  const redirectChapter = (chapterId: string) => {
    const currentUrl = window.location.pathname.split("/")
    if (currentUrl[currentUrl.length - 1] === chapterId) return;
    const newPageNum = chapterPageNumber(chapterId, chapters)
    if (newPageNum !== null) dispatch(setPageNumber(newPageNum))
  }

  const renderChapters = () => {
    if (!state.length) return null;
    return state.map((chapter, index) => (
      <Spin spinning={!chapter.isLoaded} key={chapter.id}>
        <ChaptersListItem key={chapter.id}>
          <ChapterTitle>
            <H4 onClick={() => redirectChapter(chapter.id)}>{chapter.title}</H4>
            <HelpParagraph size={1.2}>
              {moment(chapter.date).format(getDateFormat(userAccount.dateFormat as DateFormatEnum))}
            </HelpParagraph>
          </ChapterTitle>
          <ActionsWrap>
            <ChapterMenu index={index} chapter={chapter} count={state.length} />
          </ActionsWrap>
        </ChaptersListItem>
      </Spin>
    ))
  }

  return (
    <ChaptersWrap isLoaded={loaded}>
      <Header>
        <H4>{t('chapters')}</H4>
      </Header>
      <ChaptersList>
        {renderChapters()}
      </ChaptersList>
    </ChaptersWrap>
  );
};

export default Chapters;
