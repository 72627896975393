import styled from "styled-components/macro";

export const RichEditorRoot = styled.div`
  cursor: text;
  background: #fff;
  border: 1px solid #ddd;
  font-size: 1em;
  padding: 15px;
  border-radius: 30px 30px 0 0;
  overflow: hidden;
  overflow-y: auto;

  //To respect the rounded corner of editor
  &::-webkit-scrollbar-track {
    margin-top: 20px;
  }

  .DraftEditor-editorContainer {
    height: 12em;
  }

  .public-DraftEditorPlaceholder-root,
  .public-DraftEditor-content {
    margin: 0 -15px -15px;
    padding: 5px 15px;
  }

  .public-DraftEditor-content {
    min-height: 100px;
  }
`;

export const RichEditorControls = styled.div`
  font-size: 1.5em;
  user-select: none;
  border: 1px solid #ddd;
  border-radius: 0 0 30px 30px;
  padding: 0 25px;

  .styleButton {
    color: #999;
    cursor: pointer;
    margin-right: 5px;
    padding-top: 4px;
    display: inline-block;
  }

  .activeButton {
    color: var(--primaryColor);
  }

  .disabledButton {
    color: var(--disabledColor);
    cursor: not-allowed;
  }
`;

export const StyledButtons = styled.button`
  appearence: none;
  border: none;
  background: transparent;
`;
