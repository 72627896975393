import React, { ReactElement } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import GetIcon from "components/Common/Icon";
import {
    mdiDotsVertical,
    mdiArrowExpandUp,
    mdiArrowExpandDown,
    mdiShareVariant,
    mdiDelete,
    mdiEyeOffOutline,
    mdiEyeOutline
} from '@mdi/js';
import { Dropdown, Menu, Modal } from "antd";
import { ChapterContentType } from "lib/types";
import { deleteChapter, sortChapterOrder, updateChapter } from "store/actions/chapter-actions";
import { openShareModal } from "store/actions/share-actions";

// Types
type ChapterMenuProps = {
    chapter: ChapterContentType;
    count: number;
    index: number;
};

const ChapterMenu: React.FC<ChapterMenuProps> = ({ index, chapter, count }): ReactElement => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const last = count - 1 === index

    const onClick = ({ key }) => {
        switch (key) {
            case 'moveUp':
                return dispatch(sortChapterOrder(index, index - 1))
            case 'moveDown':
                return dispatch(sortChapterOrder(index, index + 1))
            case 'share':
                return dispatch(openShareModal(chapter.id))
            case 'switchPublic':
                return dispatch(updateChapter({ ...chapter, isPublic: !chapter.isPublic }, true))
        }
    };

    //don't return, modal will not close
    const onDelete = () => { dispatch(deleteChapter(chapter.diaryId, chapter.id)) }


    const confirm = () => {
        Modal.confirm({
            maskClosable: true,
            title: t('deleteChapter'),
            content: t("deleteChapterMsg"),
            okText: t('proceedBtnTxt'),
            cancelText: t('btnNo'),
            onOk: onDelete
        })
    }

    const renderMenu = () => (
        <Menu onClick={onClick}>
            <Menu.Item key="moveUp" icon={<GetIcon path={mdiArrowExpandUp} />} disabled={index === 0}>
                {t('moveUp')}
            </Menu.Item>
            <Menu.Item key="moveDown" icon={<GetIcon path={mdiArrowExpandDown} />} disabled={last}>
                {t('moveDown')}
            </Menu.Item>
            <Menu.Item
                key={'switchPublic'}
                icon={<GetIcon path={chapter.isPublic ? mdiEyeOffOutline : mdiEyeOutline} />} >
                {chapter.isPublic ? t('makePrivate') : t('makePublic')}
            </Menu.Item>
            <Menu.Item key="share" icon={<GetIcon path={mdiShareVariant} />} disabled={!chapter.isPublic}>
                {t('share')}
            </Menu.Item>
            <Menu.Item icon={<GetIcon path={mdiDelete} />} onClick={confirm} disabled={count < 2}>
                {t('delete')}
            </Menu.Item>
        </Menu>
    );

    return <Dropdown overlay={renderMenu()} placement="bottomLeft">
        {<GetIcon path={mdiDotsVertical} />}
    </Dropdown>

}

export default ChapterMenu;
