import React, { useEffect, ReactElement } from "react";
import { AppState } from "store/reducers/rootReducer";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import ChapterContent from "components/Editor/Chapter/ChapterContent";
import SidebarActions from "components/Editor/Chapter/Sidebar/SidebarActions";
import LoadingScreen from "components/Common/LoadingScreen";
import {
	getAllChapterSheets,
	setStopLoading,
	setPageNumber
} from "store/actions/chapter-actions";
import EditorModal from "components/Editor/Chapter/Parts/EditorModal/index";
import ImageModal from "components/Editor/Chapter/Parts/PageImage/ImageModal";
import { chapterPageNumber } from "util/chapterUtils";

// Styled components
const ContentEditorWrap = styled.div`
	display: flex;
	width: 100%;
	margin-top: 20px;

	@media (max-width: 992px) {
		flex-direction:column-reverse;
		padding: 0 1em;
		margin-bottom: 200px;
	}
`;

type ChapterEditorViewProps = {
	params: { diaryId: string; chapterId: string };
};

const ChapterEditorView: React.FC<ChapterEditorViewProps> = ({ params }): ReactElement => {
	const { i18n } = useTranslation();
	const lang = i18n.language;
	const dispatch = useDispatch();
	const { chapters, sheets, isSheetsLoading, pageNumber, previewMode } = useSelector(({ chapters }: AppState) => chapters);
	const { updateDiaryLoading } = useSelector(({ diary }: AppState) => diary);
	useEffect(() => {
		if (chapters.length && !sheets.length && !updateDiaryLoading) {
			dispatch(getAllChapterSheets(params.diaryId, chapters.map(c => c.id), false));
		}
	}, [dispatch, params.diaryId, sheets, chapters, updateDiaryLoading]);

	useEffect(() => {
		try {
			if (sheets.length && !updateDiaryLoading) {
				if (pageNumber === -1 && isSheetsLoading) {
					const pageNum = chapterPageNumber(params.chapterId, sheets)
					if (pageNum !== null) {
						dispatch(setPageNumber(pageNum))
						dispatch(setStopLoading())
					}
				} else {
					//first we check for right page chapterId
					let sheetId = pageNumber + 1
					//else left page chapterId
					if (sheets.length - 1 < sheetId) sheetId -= 1
					const { id, diaryId, title } = sheets[sheetId]
					window.history.replaceState({ diaryId, chapterId: id }, title, `/${lang}/diary/${diaryId}/chapter/${id}`)
				}
			}
		} catch (e) {
			window.location.reload();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sheets, pageNumber, isSheetsLoading, updateDiaryLoading]);

	const editorScreen = (): JSX.Element => (
		<ContentEditorWrap>
			<ImageModal />
			<EditorModal />
			{!previewMode && <SidebarActions />}
			<ChapterContent />
		</ContentEditorWrap>
	)

	if (updateDiaryLoading)
		return editorScreen()
	else if (sheets.length && !isSheetsLoading)
		return editorScreen()
	else
		return <LoadingScreen />
};

export default ChapterEditorView;
