import { DiaryOrderMetaDataType } from "lib/types";
import axios from 'axios';

export const getDiaryOrderMetaData = async (diaryId: string): Promise<DiaryOrderMetaDataType | null> => {
    try {
       return await axios.get(`${process.env.REACT_APP_API_URL}v1/me/diaries/${diaryId}/canOrder`).then(res => res.data)
    } catch (e) {
        console.error("<< DIARY META DATA REQUEST FAILED >>");
        return null;
    }
}